import React from "react";
import styled from "styled-components";

const content04 = () => {
  return (
    <Container>
      <Desc3>다운로드</Desc3>
      <Desc2>42,000+</Desc2>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 148px;
  height: 122px;
  padding: 16px;
  box-sizing: border-box;
  background-image: url("/img/main/contentBg02.svg");
  background-size: cover;
`;

const Desc1 = styled.div`
  color: #000;  
  font-size: 18px;
  font-style: normal;
  line-height: 120%;
  font-family: Pretendard700, sans-serif;
  font-weight: 700;
`;
const Desc2 = styled.div`
  color: #000;  
  font-family: Pretendard300, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; 
`;
const Desc3 = styled(Desc1)``;
export default content04;
