import Spacer from '@components/base/Spacer'
import { Colors } from '@styles/colors'
import styled from 'styled-components'
import useRouterHistoryHandler from '../hooks/useRouterHistoryHandler'

const WelcomeBridgePage = () => {
  const { handleGoWelcomeCallSchemePage } = useRouterHistoryHandler()

  const handleClickInstall = () => {
    var isMobile = /Mobi/i.test(window.navigator.userAgent)
    if (isMobile) {
      if (/iPhone/i.test(window.navigator.userAgent)) {
        window.open(
          'https://apps.apple.com/ar/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
        )
      } else {
        window.open(
          'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
        )
      }
    } else {
      window.open('https://160ipo.com/')
    }
  }

  return (
    <Container>
      <LogoWrapper>
        <Logo>
          <img src='/icons/160.png' width='130' />
          <Spacer height={16} />
          <div id='text'>일육공으로 이동합니다.</div>
        </Logo>
      </LogoWrapper>

      <Bottom>
        <Text2>
          해당 화면으로 이동되지 않으면 <br /> 다시 시도해주세요.
        </Text2>
        <BtnWrapper>
          <Buttons>
            <InstallBtn onClick={handleClickInstall}>설치하기</InstallBtn>
            <NavigationBtn onClick={() => handleGoWelcomeCallSchemePage()}>
              이동하기
            </NavigationBtn>
          </Buttons>
        </BtnWrapper>
      </Bottom>
    </Container>
  )
}

export default WelcomeBridgePage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20vh;
`

const Text2 = styled.div`
  margin-bottom: 16px;
  color: #999;
`

const Logo = styled.div`
  text-align: center;
`

const InstallBtn = styled.div`
  width: calc(100% - 32px);
  padding: 18px 16px;
  border-radius: 4px;
  background-color: #e2e2e2;
`

const NavigationBtn = styled(InstallBtn)`
  background-color: ${Colors.primary_ios};
`

const Bottom = styled.div`
  position: absolute;
  bottom: 70px;
  width: 100%;
  text-align: center;
`

const BtnWrapper = styled.div`
  position: relative;
  text-align: center;
`

const Buttons = styled.div`
  width: calc(100% - 32px);
  display: flex;
  gap: 16px;
  flex-direction: row;
  width: 100%;
  padding: 0 16px;
`
