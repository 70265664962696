import styled from 'styled-components'
import Modal from 'react-modal'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const disabledModalStyle = {
  content: {
    border: 0,
    // width: '80%',
    height: 'auto',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    bottom: 'auto',
    borderRadius: '4px',
    padding: '0',
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1000,
  },
}

const Styled = {
  ModalContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 40px 58px;
  `,
  Title: styled.div`
    font-family: Pretendard700, sans-serif;
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    margin-bottom: 32px;
    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 21.6px;
    }
  `,
  Description: styled.div`
    font-family: Pretendard400, sans-serif;
    font-size: 22px;
    line-height: 26.4px;
    text-align: center;
    color: #555564;
    margin-bottom: 32px;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 16.8px;
    }
  `,
  BlueButton: styled.span`
    border: none;
    border-radius: 4px;
    background-color: #0e6dfb;
    color: #fff;
    font-family: Pretendard700, sans-serif;
    font-size: 26px;
    line-height: 26.4px;
    text-align: center;
    width: 100%;
    padding: 16.5px 0;
    cursor: pointer;

    @media (max-width: 800px) {
      font-family: Pretendard500, sans-serif;
      font-size: 16px;
      line-height: 19.2px;
    }
  `,
  NextButton: styled.span`
    text-decoration: underline;
    font-family: Pretendard400, sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #999999;
    margin-top: 12px;
    cursor: pointer;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 14.4px;
    }
  `,
}

const { ModalContainer, Title, Description, BlueButton, NextButton } = Styled

const DisalbedModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  setIsBottomSheetOpen,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          ...disabledModalStyle,
          content: {
            ...disabledModalStyle.content,
            width: '80%',
            maxWidth: '500px',
          },
        }}
        ariaHideApp={false}
      >
        <ModalContainer>
          <Title>이벤트 신청 정보가 없어요</Title>
          <Description>
            이벤트 참여를 원하실 경우,
            <br />
            참여 신청 절차를 진행해주세요
          </Description>
          <BlueButton
            onClick={() => {
              setIsOpen(false)
              setIsBottomSheetOpen(true)
            }}
          >
            참여 신청하기
          </BlueButton>
          <NextButton onClick={() => setIsOpen(false)}>
            다음에 할게요
          </NextButton>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default DisalbedModal
