import styled from "styled-components";
import { Colors } from "../../styles/colors";
import Spacer from "../base/Spacer";
import { SpoqaBold, SpoqaLight, SpoqaMedium } from "../base/TextStyle/Spoqa";

export const Style = {
  Container: styled.div`
    padding-top: 152px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 562px;
  `,

  PrimaryText: styled.span``,

  Title: styled.h1``,

  Content: styled.span``,

  ContentBold: styled.span``,
};

const { Container, Title, Content, ContentBold } = Style;

const PageOne = () => {
  const linespace = 4;
  return (
    <Container>
      <SpoqaBold size={30} color={Colors.primary_ios}>
        <SpoqaBold size={30} color={Colors.primary_ios}>
          공모주 투자
        </SpoqaBold>
      </SpoqaBold>
      <Spacer height={20} />
      <Title>
        <SpoqaBold size={50}>일육공과 함께, 따상하자!</SpoqaBold>
      </Title>
      <Spacer height={56} />
      <Content>
        <SpoqaLight size={24}>
          일육공은 모바일 공모주 투자 길잡이 서비스로,
        </SpoqaLight>
        <Spacer height={10} />
      </Content>
      <Spacer height={linespace} />
      <Spacer />
      <Content>
        <SpoqaLight size={24}>
          자체 개발한 퀀트 분석 프로그램을 활용하여 보다 쉽고 편리한 공모주 투자
          경험을 제공합니다.
        </SpoqaLight>
        <Spacer height={10} />
      </Content>
      <Spacer height={linespace} />
      <ContentBold>
        <SpoqaMedium size={24}>
          일육공(160)과 함께 공모주 투자 160% 상승, 따상해봐요!
        </SpoqaMedium>
      </ContentBold>
    </Container>
  );
};

export default PageOne;
