import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 1174px;
  flex-shrink: 0;
  background-color: ${Colors.maingrey_bg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1430px) {
    height: auto;
  }
`

export const ContentWrapper = styled.div`
  height: 586px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 1430px) {
    position: relative;
    height: auto;
    width: 100%;
    flex-direction: column;
  }
`

export const RightContentWrapper = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 1850px) {
    width: 90%;
  }
`
export const TextWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1850px) {
    width: 40%;
  }
`
export const ImageWrapper = styled.div`
  height: 100%;
  @media (max-width: 1850px) {
    display: flex;
    align-items: center;
  }
  @media (max-width: 1430px) {
    width: 100%;
    margin-top: 66px;
  }
`
export const ImageMobile = styled.img`
  object-fit: contain;
  width: 100%;

  @media (max-width: 1430px) {
    width: 100%;
  }
`
export const ImageBox = styled.img`
  height: 100%;
 
  @media (max-width: 1850px) {
    height: 88%;
  }
  @media (max-width: 1430px) {
    height: 100%;
  
`
//mobile
export const PaddingWrapper = styled.div`
  padding: 0 20px;
`
export const BoldText = styled.div<{
  size: number
  color: Colors
  indent?: number
  lineHeight?: number
  weight?: number
}>`
  font-family: 'SpoqaHanSansNeoBold', sans-serif;
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  text-indent: ${(props) => (props.indent ? props.indent : 0)}px;
  line-height: ${(props) => props.lineHeight}px;
  font-weight: ${(props) => props.weight};
`
