import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import styled from "styled-components";
import MobileDownload from "@components/NewMain/Mobile/MobileDownload";

const Footer = (props:{ isSignCheck:()=>void, paddingBottom:number,isFromApp:boolean}) => {
  const {isSignCheck, paddingBottom, isFromApp} = props;


  return (
    <Container>
      <ButtonContainer onClick={isSignCheck} paddingBottom={paddingBottom}>
        <Participation isFromApp={isFromApp}>
          동의
        </Participation>
    </ButtonContainer>
    </Container>
  );
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px; 
  width: 100%;
  margin-bottom: 123px;
  margin-top:-29px;
`;

const ButtonContainer = styled.div<{paddingBottom:number}>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-top: 1px solid #DCDEEB; */
  background-color: #fff;
  width: 800px;
  margin: auto;
  position: fixed;
  bottom: 0;
  z-index: 100;
  @media (max-width:800px){
    padding: 16px 24px;
    width:100%;
    padding-bottom:  ${({ paddingBottom }) => (paddingBottom ? paddingBottom : 24)}px;

    }
`
const Participation = styled.div<{isFromApp?:boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #0e6dfb;
  color: #fff;
  text-align: center;
  font-family: Pretendard700, sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  border-radius: 16px;
  padding: 16px 24px;
  box-sizing: border-box;
  /* min-width: 312px; */
  width: 100%;
  margin-bottom: 24px;
  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 18px;
    font-style: normal;
    line-height: 150%;
    margin-bottom: 0px;
    border-radius: ${(props) => (props.isFromApp ? '4px' : '18px')};
  }
  `
export default Footer;
