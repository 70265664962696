import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'
import { Colors } from '@styles/colors'
import { useHistory } from 'react-router-dom'
import TradingNowButton from '@components/simulated-trading/TradingNowButton'
import SimulatedLayout from '@layouts/SimulatedLayout'
import { useMediaQuery } from 'react-responsive'
import { useScrollBlock } from '@hooks/useScrollBlock'
import Spinner from '@components/spinner/Spinner'

const Background = styled.div`
  position: fixed;
  z-index: 2000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`

const Wrapper = styled.article`
  background-color: #fafafa;
  width: 100%;
  padding-bottom: 98px;
`
const Container = styled.section`
  width: 100%;
  position: relative;
  margin: 0 auto;
  @media screen and (max-width: ${Desktop}px) {
    max-width: 800px;
  }
`
const CloseIcon = styled.button`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  @media screen and (max-width: ${Desktop}px) {
    top: 16px;
    right: 16px;
  }
  > img {
    width: 44px;
    height: 44px;
    @media screen and (max-width: ${Desktop}px) {
      width: 24px;
      height: 24px;
    }
  }
`

const BlurImage = styled.img`
  width: 100%;
  min-height: 100vh;
  filter: blur(8px);
  @media screen and (max-width: ${Desktop}px) {
    max-height: 100%;
    filter: blur(0);
  }
`
const ModalStyle = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 2001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  background-color: ${Colors.gf};
  width: 500px;
  height: 486px;
  border: 1px solid ${Colors.ge};
  padding: 80px 56px;

  @media screen and (max-width: ${Desktop}px) {
    padding: 48px 40px 40px;
    border-radius: 4px;
    width: 326px;
    height: 324px;
  }
`
const Title = styled.h3`
  font-family: Pretendard700, sans-serif;
  text-align: center;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.84px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 18px;
    line-height: 22px;
  }
`
const Contents = styled.h4`
  font-family: Pretendard400, sans-serif;
  color: ${Colors.g7};
  text-align: center;
  margin: 40px 0;
  font-size: 22px;
  line-height: 26px;
  @media screen and (max-width: ${Desktop}px) {
    margin: 32px 0;
    font-size: 14px;
    line-height: 17px;
  }
`
const ApplyButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background-color: #0e6dfb;
  height: 74px;
  border-radius: 16px;
  > span {
    font-family: SpoqaHanSansNeoMedium, sans-serif;
    color: ${Colors.gf};
    font-size: 22px;
    line-height: 26px;
    @media screen and (max-width: ${Desktop}px) {
      font-size: 16px;
      letter-spacing: -0.33px;
    }
  }
  @media screen and (max-width: ${Desktop}px) {
    height: 58px;
    border-radius: 4px;
  }
`
const AlreadyButton = styled.button`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  padding-right: 28px;
  justify-content: center;
  margin-top: 27px;
  @media screen and (max-width: ${Desktop}px) {
    padding-right: 18px;
  }

  > img {
    width: 28px;
    height: 28px;
    @media screen and (max-width: ${Desktop}px) {
      width: 18px;
      height: 18px;
    }
  }
  @media screen and (max-width: ${Desktop}px) {
    margin-top: 16px;
    gap: 4px;
  }
`
const AlreadyText = styled.span`
  color: ${Colors.g7};
  font-size: 18px;
  font-family: SpoqaHanSansNeo, sans-serif;
  text-decoration: underline ${Colors.g7};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 12px;
  }
`

const SimulatedBlurPage = () => {
  const history = useHistory()
  const [isOpen, setIsOpen] = React.useState(false)
  const [isReady, setIsReady] = useState(false)
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const [blockScroll, allowScroll] = useScrollBlock()

  const handleNavigate = (already: boolean = false) => {
    const SIGH_PAGE_URL = '/simulated-sign'
    if (already) {
      history.push(SIGH_PAGE_URL + '?param=alreadyapply')
    } else {
      history.push(SIGH_PAGE_URL)
    }
  }

  const handleOnLoad = () => {
    setIsReady(true)
  }
  const handleCloseModal = () => {
    history.goBack()
  }

  useEffect(() => {
    if (isOpen) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => {
      allowScroll()
    }
  }, [isOpen])

  useEffect(() => {
    if (isReady) setIsOpen(true)
    else setIsOpen(false)
  }, [isReady])

  return (
    <Wrapper>
      {isOpen && (
        <>
          <Background onClick={handleCloseModal} />
        </>
      )}
      <SimulatedLayout hasClose={false} hasFooter={false}>
        <Container>
          {isOpen ? (
            <ModalStyle>
              <CloseIcon onClick={handleCloseModal}>
                <img src={'/icons/only_x_close.png'} alt='close' />
              </CloseIcon>
              <Title>
                최대 53만원 지급!
                <br />
                현재 상금이 얼마나 쌓였을까요?
              </Title>
              <Contents>
                공모주 게임 이벤트 참여 신청 후
                <br />
                누적 상금을 확인할 수 있어요.
              </Contents>
              <ApplyButton onClick={() => handleNavigate()}>
                <span>참여 신청하기</span>
              </ApplyButton>
              <AlreadyButton onClick={() => handleNavigate(true)}>
                <img src={'/images/event/simulated_info.svg'} alt={'info'} />
                <AlreadyText>이미 참여신청을 하셨나요?</AlreadyText>
              </AlreadyButton>
            </ModalStyle>
          ) : null}
          {(!isReady || !isOpen) && <Spinner />}

          <BlurImage
            onClick={() => setIsOpen(true)}
            onLoad={handleOnLoad}
            src={
              !isMobile
                ? '/images/event/blur_web_image.png'
                : '/images/event/simulated_blur_page.png'
            }
            alt={'blur_event_page'}
          />
        </Container>
      </SimulatedLayout>

      <TradingNowButton />
    </Wrapper>
  )
}

export default SimulatedBlurPage
