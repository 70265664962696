import React from 'react'
import styled, { keyframes } from 'styled-components'

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`

const images = [
  '/img/main/Beach-with-umbrella.svg',
  '/img/main/Bubble-tea.svg',
  '/img/main/Cartwheeling.svg',
  '/img/main/Cup-with-straw.svg',
  '/img/main/Gesturing-ok.svg',
  '/img/main/Headphone.svg',
  '/img/main/High-heeled-shoe.svg',
  '/img/main/Luggage.svg',
  '/img/main/Meat-on-bone.svg',
  '/img/main/Money-with-wings.svg',
  '/img/main/Pizza.svg',
  '/img/main/Popcorn.svg',
  '/img/main/Poultry-leg.svg',
  '/img/main/Ribbon.svg',
  '/img/main/Seedling.svg',
  '/img/main/Shaved-ice.svg',
  '/img/main/Sparkling-heart.svg',
  '/img/main/Wrapped-gift.svg',
]

const Rolling = () => {
  return (
    <ImageSlider>
      <ImageList>
        {images.map((src, index) => (
          <Image key={index} src={src} alt={`Image ${index + 1}`} />
        ))}
        {images.map((src, index) => (
          <Image key={index} src={src} alt={`Image ${index + 1}`} />
        ))}
        {images.map((src, index) => (
          <Image key={index} src={src} alt={`Image ${index + 1}`} />
        ))}
      </ImageList>
    </ImageSlider>
  )
}

const ImageSlider = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 96px;
  position: relative;
`

const ImageList = styled.div`
  display: flex;
  animation: ${scroll} 80s linear infinite; /* 애니메이션 적용 */
  width: calc(96px * ${images.length * 3});
  gap: 157px;
`

const Image = styled.img`
  width: 96px;
  height: 96px;
  object-fit: cover;
`

export default Rolling
