import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const CircleMotion = () => {
  useEffect(() => {
    AOS
      .init
      // {
      //     duration: 1000,
      //     easing: 'ease-out'
      // }
      ();
  }, []);

  return (
    <Container>
      <Circle_han
        src={"/img/main/circle/icon01.svg"}
        alt="한국투자증권"
        data-aos="zoom-out"
        data-aos-duration="2000"
      />
      <Circle_sam
        src={"/img/main/circle/icon02.svg"}
        alt="삼성증권"
        data-aos="zoom-out"
        data-aos-duration="1000"
      />
      <Circle_kb
        src={"/img/main/circle/icon03.svg"}
        alt="국민증권"
        data-aos="zoom-out"
        data-aos-duration="2000"
      />
      <CircleBlur_san
        src={"/img/main/circle/icon08.svg"}
        alt="산영투자"
        data-aos="zoom-out"
        data-aos-duration="4000"
      />
      <CircleBlur_hi
        src={"/img/main/circle/icon07.svg"}
        alt="하이증권"
        data-aos="zoom-out"
        data-aos-duration="3000"
      />
    </Container>
  );
};

const Container = styled.div``;

const Circle_han = styled.img`
  width: 87.574px;
  height: 87.574px;
  top: 57.3px;
  right: 17.19px;
  position: absolute;
`;

const Circle_sam = styled.img`
  width: 91.134px;
  height: 91.134px;
  left: 34.882px;
  top: 10px;
  position: absolute;
`;

const Circle_kb = styled.img`
  width: 76.538px;
  height: 76.538px;
  bottom: 10px;
  left: -50px;
  position: absolute;
`;

const CircleBlur_san = styled.img`
  width: 45.567px;
  height: 45.567px;
  bottom: 57.35px;
  right: 139px;
  position: absolute;
`;

const CircleBlur_hi = styled.img`
  width: 68.35px;
  height: 68.35px;
  bottom: 23.17px;
  right: -45px;
  position: absolute;
`;

export default CircleMotion;
