import { ReactElement } from 'react'
import styled from 'styled-components'
import { Colors } from '../../styles/colors'
import Spacer from '../base/Spacer'
import { SpoqaMedium } from '../base/TextStyle/Spoqa'

export const Style = {
  Container: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,

  Block: styled.div`
    background-color: ${Colors.yellow_sub};
    width: 100%;
    height: 825px;
    position: absolute;
    top: 0px;
    z-index: 0;
  `,

  BGitem: styled.img`
    position: absolute;
    top: 0;
    right: 0;
  `,

  HalfMoon: styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
  `,

  ContentContainer: styled.div`
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 189px;
  `,

  ImgText: styled.img``,

  ContentItem: styled.img``,
}

const {
  Container,
  Block,
  BGitem,
  HalfMoon,
  ContentContainer,
  ImgText,
  ContentItem,
} = Style

const PageTwo = (): ReactElement => {
  return (
    <Container>
      <Block>
        <HalfMoon src='/images/infopage/item2.png' />
        <BGitem src='/images/infopage/item1.png' />
      </Block>
      <ContentContainer>
        <SpoqaMedium size={30}>단 하나의 공모주 평가지표,</SpoqaMedium>
        <Spacer height={8} />
        <ImgText src='/images/infopage/item3.png' />
        <ContentItem src='/images/infopage/item4.png' />
        <ContentItem src='/images/infopage/item5.png' />
        <ContentItem src='/images/infopage/item6.png' />
      </ContentContainer>
    </Container>
  )
}

export default PageTwo
