import React from "react";
import styled from "styled-components";
import ContentBox from "./contentBox/index";

const Index = () => {
  return (
    <Container>
      <ContentBox />
      <LogoContainer>
        <Logo src={"/img/main/ML_logo_mobile.svg"} alt="ml_logo" />
      </LogoContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  box-sizing: border-box;
  gap: 72px;
  width: 100%;
  min-width: 360px;
  justify-content: center;
  align-items: center;

`;

const LogoContainer = styled.div``;
const Logo = styled.img`
  width: 280px;
`;
export default Index;
