/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs'
import _ from 'lodash'
import { Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { RefHandles } from 'react-spring-bottom-sheet/dist/types'
import styled, { css } from 'styled-components'
import {
  ScheduleType,
  filterScheduleInitialState,
  hideCompletedFilterInitialState,
  setFilter,
  setSelectedDate,
  setSelectedMonth,
  setSelectedYear,
  typeFilterInitialState,
} from '../store/modules/base'
import { baseSelector } from '../store/selectors/base'
import { Colors } from '@styles/colors'
import { getNow } from '../utils/dateHelper'
import Icon, { IconType } from './base/Icon'
import Spacer from './base/Spacer'
import { IpoStockGubun } from '@enums/StockEnum'

const SHOW_MONTH_CHUNKED_COUNT = 4

const Styled = {
  Wrapper: styled.div`
    padding: 20px 20px 0 20px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  `,
  Left: styled.div`
    display: flex;
    justify-content: flex-start;
  `,
  Date: styled.div`
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
  `,
  Right: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  YearLabelWrapper: styled.div`
    overflow-x: scroll;
    margin-top: 32px;
    margin-left: calc((100% / 4 / 2) - 36px + 12px);
  `,
  Label: styled.div<{ active?: boolean; fontSize?: number }>`
    display: inline-flex;
    font-size: 16px;
    color: ${Colors.g9};
    padding: 8px 14px;
    background-color: ${Colors.background_sub};
    border-radius: 50px;
    margin-right: 8px;
    align-items: center;
    ${({ fontSize }) =>
      fontSize &&
      css`
        font-size: ${fontSize}px;
      `}
    ${({ active }) =>
      active &&
      css`
        color: white;
        background-color: ${Colors.secondary};
      `};
  `,
  MonthWrapper: styled.div`
    margin-top: 36px;
    margin-bottom: 36px;
  `,
  MonthRow: styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  `,
  Month: styled.div`
    display: inline-flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    flex-basis: calc(100% / ${SHOW_MONTH_CHUNKED_COUNT});
    text-align: center;
    margin: 8px;
  `,
  Item: styled.div<{
    active?: boolean
    disabled?: boolean
  }>`
    color: ${Colors.g12};
    padding: 8px;
    border-radius: 50px;

    ${({ active }) =>
      active &&
      css`
        color: white;
        background-color: ${Colors.secondary};
      `}
  `,
  Filter: styled.div`
    margin-top: 24px;
  `,
  SmallTitle: styled.div`
    font-size: 12px;
    color: ${Colors.ga};
    margin-bottom: 6px;
  `,
  BottomArea: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 32px;
  `,
  Button: styled.div`
    background-color: ${Colors.primary_ios};
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: ${Colors.g12};
    border-radius: 4px;
  `,
  ResetButton: styled.div`
    margin-top: 12px;
    color: ${Colors.g9};
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  FilterWrapper: styled.div`
    padding: 0 24px;
  `,
}

const {
  Wrapper,
  Left,
  Right,
  Date,
  YearLabelWrapper,
  Label,
  MonthWrapper,
  MonthRow,
  Month,
  Item,
  Filter,
  SmallTitle,
  BottomArea,
  Button,
  ResetButton,
  FilterWrapper,
} = Styled

const SHOW_PREVIOUS_YEAR_COUNT = 3
const SHOW_MONTH_COUNT = 12

interface MainHeaderProps {
  setDate: (date: string) => void
  setIsShowCalendarView: (isShowCalendarView: boolean) => void
  isShowCalendarView: boolean
}
function ScreenHeader({
  setDate,
  setIsShowCalendarView,
  isShowCalendarView,
}: MainHeaderProps) {
  const dispatch = useDispatch()
  const [openDateBottomSheet, setOpenDateBottomSheet] = useState(false)
  const [openFilterBottomSheet, setOpenFilterBottomSheet] = useState(false)
  const dateBottomSheetRef = useRef<BottomSheetRef>()
  const filterBottomSheetRef = useRef<BottomSheetRef>()
  const { selectedYear, selectedMonth, selectedFilter } =
    useSelector(baseSelector)

  const chunkedYears = useMemo(
    () =>
      _.chunk(
        Array.from({ length: SHOW_MONTH_COUNT }),
        SHOW_MONTH_CHUNKED_COUNT
      ),
    []
  )

  const [scheduleFilter, setScheduleFilter] = useState(selectedFilter.schedule)
  const [typeFilter, setTypeFilter] = useState(selectedFilter.type)
  const [hideCompletedFilter, setHideCompletedFilter] = useState(
    selectedFilter.hideCompleted
  )

  const getYearByIndex = useCallback((index: number) => {
    if (getNow().month() > 10) {
      return getNow().add(1, 'year').subtract(index, 'year').year()
    } else {
      return getNow().subtract(index, 'year').year()
    }
  }, [])

  const [tempYear, setTempYear] = useState(selectedYear ?? getNow().year())

  const handleClickMonth = useCallback(
    (yearsIndex: number, yearIndex: number) => {
      if (!selectedYear) {
        return
      }
      const month = SHOW_MONTH_CHUNKED_COUNT * yearsIndex + yearIndex
      dispatch(setSelectedMonth(month))
      const date = dayjs()
        .set('year', tempYear)
        .set('month', month)
        .set('date', 1)
        .format('YYYY-MM-DD')
      setDate(date)
      setOpenDateBottomSheet(false)
      dispatch(setSelectedYear(tempYear))
      dispatch(setSelectedDate(date))
    },
    [setDate, tempYear]
  )
  const handleYear = useCallback(
    (index: any) => {
      if (selectedMonth === null) {
        return
      }
      const year = getYearByIndex(index)
      setTempYear(year)
    },
    [setDate, selectedMonth]
  )
  const handleScheduleFilter = useCallback(
    (type: ScheduleType) => {
      if (scheduleFilter.includes(type)) {
        setScheduleFilter(scheduleFilter.filter((item) => item !== type))
      } else {
        setScheduleFilter([...scheduleFilter, type])
      }
    },
    [scheduleFilter]
  )
  const handleTypeFilter = useCallback(
    (type: keyof typeof IpoStockGubun) => {
      if (typeFilter.includes(type)) {
        setTypeFilter(typeFilter.filter((item) => item !== type))
      } else {
        setTypeFilter([...typeFilter, type])
      }
    },
    [typeFilter]
  )
  const handleApplyFilter = useCallback(() => {
    if (selectedYear === null || selectedMonth === null) {
      return
    }
    dispatch(
      setFilter({
        schedule: scheduleFilter,
        type: typeFilter,
        hideCompleted: hideCompletedFilter,
      })
    )
    const date = dayjs()
      .set('year', selectedYear)
      .set('month', selectedMonth)
      .set('date', 1)
      .format('YYYY-MM-DD')
    setDate(date)
    setOpenFilterBottomSheet(false)
  }, [
    selectedYear,
    selectedMonth,
    scheduleFilter,
    typeFilter,
    hideCompletedFilter,
    setDate,
  ])
  const handleClearFilter = useCallback(() => {
    setScheduleFilter(filterScheduleInitialState)
    setTypeFilter(typeFilterInitialState)
    setHideCompletedFilter(hideCompletedFilterInitialState)
  }, [])

  useEffect(() => {
    if (selectedYear) {
      setTempYear(selectedYear)
    }
  }, [selectedYear])
  return (
    <Wrapper>
      <Left>
        <Date onClick={() => setOpenDateBottomSheet(true)}>
          {dayjs()
            .set('year', selectedYear ?? getNow().year())
            .set('month', selectedMonth ?? getNow().month())
            .format('YYYY년 MM월')}
          <Spacer width={4} />
          <Icon type={IconType.ARROW_BOTTOM} size={12} />
        </Date>
      </Left>
      <Right>
        <Spacer width={20} />
        <Icon
          type={IconType.FILTER}
          size={18}
          onClick={() => setOpenFilterBottomSheet(true)}
        />
        <Spacer width={24} />
        {isShowCalendarView ? (
          <Icon
            type={IconType.SHOW_LIST_VIEW}
            size={18}
            onClick={() => setIsShowCalendarView(false)}
          />
        ) : (
          <Icon
            type={IconType.SHOW_CALENDAR_VIEW}
            size={18}
            onClick={() => setIsShowCalendarView(true)}
          />
        )}
      </Right>

      <BottomSheet
        open={openDateBottomSheet}
        ref={dateBottomSheetRef as Ref<RefHandles>}
        onDismiss={() => setOpenDateBottomSheet(false)}
      >
        <button
          onClick={() => {
            dateBottomSheetRef?.current?.snapTo(({ maxHeight }) => maxHeight)
          }}
        ></button>
        <YearLabelWrapper>
          {Array.from({ length: SHOW_PREVIOUS_YEAR_COUNT }).map(
            (item, itemIndex) => (
              <Label
                key={itemIndex}
                active={tempYear === getYearByIndex(itemIndex)}
                onClick={() => handleYear(itemIndex)}
              >
                {getYearByIndex(itemIndex)}년
              </Label>
            )
          )}
        </YearLabelWrapper>
        <MonthWrapper>
          {chunkedYears.map((years, yearsIndex) => (
            <MonthRow key={`chunked-years-${yearsIndex}`}>
              {years.map((year, yearIndex) => (
                <Month
                  key={yearIndex}
                  onClick={() => handleClickMonth(yearsIndex, yearIndex)}
                >
                  <Item
                    active={
                      selectedMonth !== null
                        ? SHOW_MONTH_CHUNKED_COUNT * yearsIndex +
                            (yearIndex + 1) ===
                          selectedMonth + 1
                        : false
                    }
                  >
                    {SHOW_MONTH_CHUNKED_COUNT * yearsIndex + (yearIndex + 1)}월
                  </Item>
                </Month>
              ))}
            </MonthRow>
          ))}
        </MonthWrapper>
      </BottomSheet>
      <BottomSheet
        open={openFilterBottomSheet}
        ref={filterBottomSheetRef as Ref<RefHandles>}
        onDismiss={() => setOpenFilterBottomSheet(false)}
      >
        <button
          onClick={() => {
            filterBottomSheetRef?.current?.snapTo(({ maxHeight }) => maxHeight)
          }}
        ></button>
        <FilterWrapper>
          <Filter>
            <SmallTitle>공모주 일정</SmallTitle>
            <Label
              active={scheduleFilter.includes(ScheduleType.EX)}
              onClick={() => handleScheduleFilter(ScheduleType.EX)}
              fontSize={14}
            >
              수요예측일
            </Label>
            <Label
              active={scheduleFilter.includes(ScheduleType.OFFERING)}
              onClick={() => handleScheduleFilter(ScheduleType.OFFERING)}
              fontSize={14}
            >
              청약일
            </Label>
            <Label
              active={scheduleFilter.includes(ScheduleType.LISTING)}
              onClick={() => handleScheduleFilter(ScheduleType.LISTING)}
              fontSize={14}
            >
              상장일
            </Label>
          </Filter>
          <Filter>
            <SmallTitle>공모주 종류</SmallTitle>
            {Object.keys(IpoStockGubun).map((value, index) => {
              return (
                <Label
                  key={index}
                  active={typeFilter.includes(value)}
                  onClick={() =>
                    handleTypeFilter(value as keyof typeof IpoStockGubun)
                  }
                  fontSize={14}
                >
                  {IpoStockGubun[value as keyof typeof IpoStockGubun]}
                </Label>
              )
            })}
          </Filter>
          <Filter>
            <SmallTitle>상장완료 종목 숨기기</SmallTitle>
            <Label
              onClick={() => setHideCompletedFilter(true)}
              fontSize={14}
              active={hideCompletedFilter}
            >
              Yes
            </Label>
            <Label
              onClick={() => setHideCompletedFilter(false)}
              fontSize={14}
              active={!hideCompletedFilter}
            >
              No
            </Label>
          </Filter>
          <BottomArea>
            <Button onClick={handleApplyFilter}>적용하기</Button>
            <ResetButton onClick={handleClearFilter}>
              초기화하기
              <Spacer width={4} />
              <Icon type={IconType.RESET} size={14} />
            </ResetButton>
          </BottomArea>
        </FilterWrapper>
      </BottomSheet>
    </Wrapper>
  )
}

export default ScreenHeader
