import { useMediaQuery } from 'react-responsive'
import {
  Container,
  ImageFinger,
  ImageMore,
  ImagePhone,
  YellowBackground,
} from './styles'

const SectionTwo = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  return (
    <Container>
      <ImagePhone
        src={
          isMobile
            ? 'images/main/section-two/phone_mobile.png'
            : 'images/main/section-two/phone_web.png'
        }
        alt='phone'
      />
      <ImageFinger
        src={
          isMobile
            ? 'images/main/section-two/finger_mobile.png'
            : 'images/main/section-two/finger_web.png'
        }
        alt='finger'
      />
      {!isMobile && !isTablet && (
        <ImageMore
          src={'images/main/section-two/more-sign.png'}
          alt='more_web'
        />
      )}
      <YellowBackground></YellowBackground>
    </Container>
  )
}

export default SectionTwo
