import { useEffect } from 'react'
import { useAppDispatch } from '../store/selectors/utils'
import useSearchParams from '@hooks/useSearchParams'
import { setEntryPoint } from '../store/modules/entryPointSlice'

const useSetEntryPoint = () => {
  const dispatch = useAppDispatch()
  const { getParam } = useSearchParams()
  const isFromApp = getParam('fromApp') === 'true'

  useEffect(() => {
    if (isFromApp) {
      dispatch(setEntryPoint(true))
    }
  }, [isFromApp, dispatch])
}

export default useSetEntryPoint
