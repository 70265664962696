import { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Footer from './footer'
import DisalbedModal from './disabledModal'
import SimulatedBottomSheet from './simulated_bottom_sheet'
import useRouterHistoryHandler from '../../hooks/useRouterHistoryHandler'
import SimulatedLayout from '@layouts/SimulatedLayout'
import PasswordModal from './passwordModal'
import { setUser } from '../../store/modules/userSlice'
import { useAppDispatch, useAppSelector } from '../../store/selectors/utils'
import TalkModal from './talkModal'
import useGetBottomInset from '@hooks/useGetBottomInset'
import useScrollIntoView from '@hooks/useScrollIntoView'
import KeyboardToolbar from '@hooks/useKeyboardVisibility'

const Index = (): ReactElement => {
  const isAlready = window.location.href.includes('alreadyapply')
  const { handleGoSimulatedMarketingPage, handleGoSimulatedForgePage } =
    useRouterHistoryHandler()
  const data = useAppSelector((state) => state.userReducer)
  const dispatch = useAppDispatch()
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const [name, setName] = useState(data.name)
  const [phone, setPhone] = useState(data.phone)
  const [pw, setPw] = useState(data.password)
  const [infoAgree, setInfoAgree] = useState(data.infoAgree)
  const [isAble, setIsAble] = useState(false)
  const [showMarketing, setShowMarketing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPwModal, setShowPwModal] = useState(false)
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [showTalkModal, setShowTalkModal] = useState(false)
  const [focusedInput, setFocusedInput] = useState('')
  const [bottomPosition, setBottomPosition] = useState(0)
  const [isUp, setIsUp] = useState(false)
  const bottomInset = useGetBottomInset()
  const nameInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const pwInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (localStorage.getItem('isAgree') === 'true') {
      setIsBottomSheetOpen(true)
    } else {
      setIsBottomSheetOpen(false)
    }
  }, [])

  useEffect(() => {
    if (
      name.trim() !== '' &&
      phone.length >= 10 &&
      pw.length === 4 &&
      isAlready
    ) {
      setIsAble(true)
      return
    }
    if (
      name.trim() !== '' &&
      phone.length >= 10 &&
      pw.length === 4 &&
      infoAgree
    )
      setIsAble(true)
    else setIsAble(false)
  }, [infoAgree, isAlready, name, phone.length, pw.length])

  const handleFocus = (inputName: string) => {
    setFocusedInput(inputName)
  }

  const handleBlur = () => {
    setFocusedInput('')
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
    dispatch(
      setUser({
        name: e.target.value,
        phone: phone,
        password: pw,
        infoAgree: infoAgree,
      })
    )
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const numericValue = value.replace(/[^0-9]/g, '')
    setPhone(numericValue)
    dispatch(
      setUser({
        name: name,
        phone: numericValue,
        password: pw,
        infoAgree: infoAgree,
      })
    )
  }
  const handlePwChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const numericValue = value.replace(/[^0-9]/g, '')
    if (numericValue.length <= 4) {
      setPw(numericValue)
      dispatch(
        setUser({
          name: name,
          phone: phone,
          password: numericValue,
          infoAgree: infoAgree,
        })
      )
    }
  }

  const clearField = (fieldName: string) => {
    switch (fieldName) {
      case 'name':
        setName('')
        break
      case 'phone':
        setPhone('')
        break
      case 'pw':
        setPw('')
        break
      default:
        break
    }
  }

  useScrollIntoView(nameInputRef, focusedInput === 'name')
  useScrollIntoView(phoneInputRef, focusedInput === 'phone')
  useScrollIntoView(pwInputRef, focusedInput === 'pw')

  return (
    <>
      <SimulatedLayout>
        <Container>
          <>
            <ImageArea src='/images/simulate-sign-image.png' alt='sign-image' />
            <DescriptionArea>
              {!isAlready ? (
                <>
                  <Title>일육공 공모주 게임 이벤트</Title>
                  <Description>
                    <DescPeriod>
                      <DescItem>참여기간 : 1.6 ~ 2.5</DescItem>
                      <DescSecondItem>
                        (청약 발생 시, 해당 종목 매도일까지)
                      </DescSecondItem>
                    </DescPeriod>
                    <DescItem>
                      참여대상: 이벤트 신청일 기준 일육공 신규 고객
                    </DescItem>
                    <DescItem>제공 혜택: 최대 53만원 지급</DescItem>
                  </Description>{' '}
                </>
              ) : (
                <Title>이벤트 신청 정보를 입력해주세요</Title>
              )}
            </DescriptionArea>
            <InputArea className={focusedInput === 'name' ? 'focused' : ''}>
              <InputTitle>이름</InputTitle>
              <Input
                ref={nameInputRef}
                placeholder='이름 입력'
                value={name}
                onChange={handleNameChange}
                onFocus={() => handleFocus('name')}
              />
              {name?.length > 0 && focusedInput === 'name' && (
                <ClearIcon
                  src={'/icons/clearIcon.svg'}
                  onClick={() => clearField('name')}
                />
              )}
            </InputArea>
            <InputArea className={focusedInput === 'phone' ? 'focused' : ''}>
              <InputTitle>전화번호</InputTitle>
              <Input
                ref={phoneInputRef}
                placeholder='전화번호 입력(- 제외)'
                value={phone}
                inputMode='numeric'
                onChange={handlePhoneChange}
                onFocus={() => handleFocus('phone')}
              />
              {phone?.length > 0 && focusedInput === 'phone' && (
                <ClearIcon
                  src={'/icons/clearIcon.svg'}
                  onClick={() => clearField('phone')}
                />
              )}
            </InputArea>
            <InputArea className={focusedInput === 'pw' ? 'focused' : ''}>
              <InputTitle>비밀번호 설정(숫자 4자리)</InputTitle>
              <Input
                ref={pwInputRef}
                placeholder='게임현황 확인 시 사용할 비밀번호 입력'
                value={pw}
                inputMode='numeric'
                onChange={handlePwChange}
                onFocus={() => handleFocus('pw')}
              />
              {pw?.length > 0 && focusedInput === 'pw' && (
                <ClearIcon
                  src={'/icons/clearIcon.svg'}
                  onClick={() => clearField('pw')}
                />
              )}
            </InputArea>
            {!isAlready && (
              <MarketingArea>
                <MarKetingItem
                  onClick={() => {
                    dispatch(
                      setUser({
                        name: name,
                        phone: phone,
                        password: pw,
                        infoAgree: !infoAgree,
                      })
                    )
                    setInfoAgree(!infoAgree)
                  }}
                >
                  <CheckIcon
                    src={
                      infoAgree
                        ? '/icons/simulate-check-able.svg'
                        : '/icons/simulate-check.svg'
                    }
                  />
                  개인정보 수집 이용 동의(필수)
                  <RightIcon
                    src='/icons/arrow_right.svg'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setShowMarketing(true)
                      handleGoSimulatedMarketingPage()
                    }}
                  />
                </MarKetingItem>
              </MarketingArea>
            )}
            {isAlready && (
              <ForgetPw
                id={'event_160_01_sign_alreadydoneforgot_btn_click'}
                onClick={() => {
                  handleGoSimulatedForgePage()
                  localStorage.removeItem('isAgree')
                }}
              >
                비밀번호를 잊으셨나요?
              </ForgetPw>
            )}
            <CheckInfoArea>
              <InfoTitle>확인해주세요</InfoTitle>
              <InfoDescriptionArea>
                <InfoDescription>
                  회원 정보가 잘못 기재되어 경품/상금을 받지 못하시는 경우
                  경품/상금은 지급되지 않아요.
                </InfoDescription>
                <InfoDescription>
                  고객의 일육공 앱 서비스 가입 정보(성명, 연락처)와 이벤트 신청
                  정보가 동일해야 경품/상금이 지급돼요.
                </InfoDescription>
                <InfoDescription>
                  다른 이름과 연락처로 경품/상금을 요구하는 경우 당첨이 임의
                  취소될 수 있어요.
                </InfoDescription>
              </InfoDescriptionArea>
            </CheckInfoArea>
          </>
          {!isBottomSheetOpen && (
            <Footer
              isAble={isAble}
              showMarketing={showMarketing}
              isAlready={isAlready}
              setShowPwModal={setShowPwModal}
              setShowTalkModal={setShowTalkModal}
              setIsBottomSheetOpen={setIsBottomSheetOpen}
              paddingBottom={!isFromApp ? bottomInset : 24}
              bottomPosition={bottomPosition}
              isUp={isUp}
            />
          )}
        </Container>
      </SimulatedLayout>
      <DisalbedModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        setIsBottomSheetOpen={setIsBottomSheetOpen}
      />
      <PasswordModal isOpen={showPwModal} setIsOpen={setShowPwModal} />

      <TalkModal
        isOpen={showTalkModal}
        setIsOpen={setShowTalkModal}
        isFromApp={isFromApp}
      />

      <SimulatedBottomSheet
        infoAgree={infoAgree}
        setInfoAgree={setInfoAgree}
        isOpen={isBottomSheetOpen}
        setIsOpen={setIsBottomSheetOpen}
        dispatch={dispatch}
        setShowTalkModal={setShowTalkModal}
        isFromApp={isFromApp}
      />

      <KeyboardToolbar
        setBottomPosition={setBottomPosition}
        setIsUp={setIsUp}
      />
    </>
  )
}

const ForgetPw = styled.div`
  font-family: Pretendard400, sans-serif;
  font-size: 18px;
  line-height: 23.4px;
  text-align: center;
  color: #555555;
  text-decoration: underline;
  margin-top: -24px;
  margin-bottom: 37px;

  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 18px;
    margin-top: -29px;
    margin-bottom: 48px;
  }
`

const InfoDescriptionArea = styled.ul`
  list-style-type: disc;
  padding-left: 32px;
  color: #555564;
`

const InfoDescription = styled.li`
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19.2px;
  line-height: 16px;
  margin-bottom: 8px;
  color: #555564;

  @media (max-width: 800px) {
    font-family: 'Spoqa Han Sans Neo', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
`

const InfoTitle = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 18px;
  line-height: 21.6px;
  margin-bottom: 8px;
  color: #555564;
  @media (max-width: 800px) {
    font-family: 'Spoqa Han Sans Neo', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  }
`

const CheckInfoArea = styled.div<{ isFromApp?: boolean }>`
  background-color: #eaeef3;
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #dcdeeb;
  @media (max-width: 800px) {
    padding: 28px 16px;
    margin: 0 24px;
    border-radius: ${(props) => (props.isFromApp ? '4px' : '16px')};
  }
`

const ClearIcon = styled.img`
  position: absolute;
  right: 40px;
  top: 65%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #999ba9;
  cursor: pointer;
  transition: color 0.3s ease;
`

const RightIcon = styled.img`
  margin-left: auto;
  cursor: pointer;
`
const CheckIcon = styled.img`
  margin-right: 14px;
  cursor: pointer;
`

const MarKetingItem = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  text-align: left;
  margin-bottom: 51px;
  cursor: pointer;
`

const MarketingArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Pretendard400, sans-serif;
  font-size: 20px;
  line-height: 24px;
  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 16.8px;
    padding: 0 24px;
  }
`

const Input = styled.input`
  background-color: #f5f5f9;
  border-radius: 4px;
  padding: 24px;
  border: none;
  font-size: 18px;
  line-height: 21.6px;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 19.2px;
  }
`

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 51px;
  position: relative;

  @media (max-width: 800px) {
    padding: 0 24px;
  }
`

const InputTitle = styled.div`
  font-family: Pretendard500, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #999ba9;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const DescSecondItem = styled.span`
  font-family: Pretendard400, sans-serif;
  font-size: 18px;
  line-height: 21.6px;
  color: #999ba9;
  @media (max-width: 800px) {
    font-family: Pretendare400, sans-serif;
    font-size: 12px;
    line-height: 14.4px;
  }
`

const DescPeriod = styled.div`
  display: flex;
  flex-direction: row;
`
const DescItem = styled.div`
  font-size: 22px;
  line-height: 26.4px;
  color: #555564;
  font-family: Pretendare500, sans-serif;
  margin-bottom: 4px;
  @media (max-width: 800px) {
    font-family: Pretendare400, sans-serif;
    font-size: 14px;
    line-height: 17px;
  }
`

const Description = styled.div`
  margin-top: 12px;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 32px;
  line-height: 38.4px;
  font-family: Pretendard700, sans-serif;
  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 22px;
  }
`

const DescriptionArea = styled.div`
  padding: 32px 0px;
  @media (max-width: 800px) {
    padding: 32px 24px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  margin: auto;
  position: relative;
  @media (max-width: 800px) {
    width: 100%;
  }
`

const ImageArea = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin: 0;
  border: none;
`

export default Index
