import { useEffect } from 'react';

const useScrollIntoView = (ref: React.RefObject<HTMLInputElement>, isFocused: boolean) => {
  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isFocused, ref]);
};

export default useScrollIntoView;
