import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import PageOne from '../components/faqpage/PageOne'
import ScreenOne from '../components/mobile-faqpage/ScreenOne'
import { useEffect, useState } from 'react'
import FloatBtn from '../components/base/FloatBtn'
import BasicInstallModal from '../components/base/modal/basic_install_modal'
import MobileFloatBtn from '../components/base/float_btn/mobile_float_btn'

const Styled = {
  Container: styled.div``,

  MobileContainer: styled.div`
    @media (min-width: 999px) {
      display: none;
    }
  `,
}

const { Container, MobileContainer } = Styled

const FaqPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  return (
    <>
      {isMobile ? (
        <MobileContainer>
          <ScreenOne />
          <MobileFloatBtn />
        </MobileContainer>
      ) : (
        <Container>
          <PageOne />
          <FloatBtn
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowDownBtn={true}
          />
          <BasicInstallModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </Container>
      )}
    </>
  )
}

export default FaqPage
