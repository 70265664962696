import styled from 'styled-components'
import SimulatedLayout from '@layouts/SimulatedLayout'
import useSetEntryPoint from '@hooks/useSetEntryPoint'

const EventKbPage = () => {
  useSetEntryPoint()
  return (
    <SimulatedLayout isFirst={true} paddingBottom={0}>
      <Container>
        <ContentWrapper>
          <ContentImage src='images/event/whole_kb_event.png' />
        </ContentWrapper>
      </Container>
    </SimulatedLayout>
  )
}

export default EventKbPage

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div`
  width: 720px;
  @media (max-width: 720px) {
    width: 100%;
  }
`
const ContentImage = styled.img`
  width: 100%;
`
