import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${Colors.gf};
  position: relative;
  z-index: 1;
  @media (max-width: 1430px) {
    height: auto;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;

  @media (max-width: 1430px) {
  }
  @media (max-width: 767px) {
    margin-top: 52px;
  }
`
export const Image = styled.img`
  width: 100%;
`
export const TextWrapper = styled.div`
  position: absolute;
  left: 15%;
  top: 15%;
  display: flex;
  flex-direction: column;
  z-index: 2;
`
//mobile
export const MobileWrapper = styled.div`
  position: relative;
`
export const ImageMobile = styled.img`
  width: 100%;
  z-index: 2;
`
export const ImageMobileLine = styled.img`
  position: absolute;
  z-index: 1;
  width: 60%;
  right: 0;
  object-fit: contain;
`

export const RegularText = styled.div<{ isMobile: boolean }>`
  font-family: 'SpoqaHanSansNeoRegular', sans-serif;
  font-size: ${(props) => (props.isMobile ? 13 : 19)}px;
  color: ${Colors.g12};
  line-height: ${(props) => (props.isMobile ? 18.2 : 30)}px;
  z-index: 2;
`
