import React from 'react'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'
import { Colors } from '@styles/colors'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const Wrapper = styled.div`
  color: ${Colors.gf};
`

const List = styled.ul`
  display: flex;
  gap: 12px;
  margin: 24px 0;

  @media screen and (max-width: ${Desktop}px) {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    gap: 8px;
  }
`
const Item = styled.li`
  display: flex;
  flex: 1;
  flex-direction: column;
  backdrop-filter: blur(6px);
  background-color: rgba(255, 255, 255, 0.05);
  padding: 32px 56px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  @media screen and (max-width: ${Desktop}px) {
    padding: 16px;
  }
`
const BenefitText = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  @media screen and (max-width: ${Desktop}px) {
    line-height: 15px;
  }
`
const Title = styled.span`
  white-space: pre-wrap;
  line-height: 22px;
  font-family: Pretendard700, sans-serif;
  font-size: 18px;
  @media screen and (max-width: ${Desktop}px) {
    font-family: Pretendard600, sans-serif;
    line-height: 22px;
    font-size: 18px;
  }
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 8px;
`
const TargetBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e0efff;
  color: #2833bf;
  border-radius: 4px;
  padding: 6px;
  font-family: Pretendard700, sans-serif;
  font-size: 14px;
  line-height: 17px;
  @media screen and (max-width: ${Desktop}px) {
    padding: 6px 13px;
  }
`
const MethodText = styled.span`
  font-family: Pretendard500, sans-serif;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 14px;
    line-height: 17px;
  }
`
const Conditions = styled.div`
  display: flex;
  flex-direction: column;
`
const Subtitle = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 16px;
  line-height: 19px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 17px;
  }
`
const Desc = styled.span`
  font-family: Pretendard400, sans-serif;
  margin: 4px 0 8px;
  font-size: 16px;
  line-height: 19px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 14px;
    line-height: 17px;
  }
`
const Caption = styled.span`
  font-family: Pretendard400, sans-serif;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  line-height: 17px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 12px;
    line-height: 14px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  opacity: 0.7;
  @media screen and (max-width: ${Desktop}px) {
    margin-top: 24px;
  }
`

const NavigateButton = styled.button`
  font-family: Pretendard400, sans-serif;
  text-decoration: underline ${Colors.gf};
  text-underline-offset: 3px;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 16px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    letter-spacing: -0.33px;
    line-height: 24px;
  }
`

const BENEFIT_LIST = [
  {
    id: 1,
    tag: '혜택',
    title: '수익금 전액 +1만원 추가 지급\n(최대 3만원)',
    desktopTitle: '수익금 전액 +1만원 추가 지급\n(최대 3만원)',
    target: '참여자 전원',
    payment_method: '3월 2째 주 중 일괄 지급',
  },
  {
    id: 2,
    tag: '혜택',
    title: '청약 지원금 50만원 추가 지급',
    desktopTitle: '청약 지원금 50만원\n 추가 지급',
    target: '추첨 2명',
    payment_method: '대상자에게 개별 연락',
  },
]

const ExpandedDetail = () => {
  const history = useHistory()
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })

  const toNavigateDetailPage = () => {
    history.push('/simulated-trading-main/detail', true)
  }
  return (
    <Wrapper>
      <List>
        {BENEFIT_LIST.map(
          ({ id, tag, target, payment_method, title, desktopTitle }) => (
            <Item key={id}>
              <BenefitText>
                {tag} {id}
              </BenefitText>
              <Title>{isMobile ? title : desktopTitle}</Title>
              <Flex>
                <TargetBox>{target}</TargetBox>
                <MethodText>{payment_method}</MethodText>
              </Flex>
            </Item>
          )
        )}
      </List>
      <Conditions>
        <Subtitle>수령조건:</Subtitle>
        <Desc>
          한 번만 청약해도 혜택 대상이 되며,
          {isMobile ? <br /> : null}
          수령 고객은 청약 완료 기준 선착순 5천 명입니다.
        </Desc>
        <Caption>* 이벤트 기간에 따라 지급일정이 변동될 수 있습니다</Caption>
      </Conditions>
      <ButtonWrapper>
        <NavigateButton onClick={toNavigateDetailPage}>
          이벤트 자세히보기
        </NavigateButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default ExpandedDetail
