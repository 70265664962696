import { Colors } from "../../styles/colors";
import styled, { css } from "styled-components";
import { forwardRef, PropsWithChildren } from "react";
import { SAFE_AREA_BOTTOM } from "../../styles/presets";

interface LayoutFrameProps {
  background: Colors;
  padding?: number;
}

const Styled = {
  Wrapper: styled.div<{ color: Colors; padding?: number }>`
    ${SAFE_AREA_BOTTOM};
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    ${({ color }) =>
      color &&
      css`
        background-color: ${color};
      `};

    ${({ padding }) =>
      css`
        min-height: calc(
          100vh - ${padding}px - constant(safe-area-inset-top) -
            constant(safe-area-inset-bottom)
        );
        min-height: calc(
          100vh - ${padding}px - env(safe-area-inset-top) -
            env(safe-area-inset-bottom)
        );
        padding-top: calc(constant(safe-area-inset-top) + ${padding}px);
        padding-top: calc(env(safe-area-inset-top) + ${padding}px);
        padding-left: ${padding}px;
        padding-right: ${padding}px;
        padding-bottom: ${padding}px;
      `};
  `,
};

const { Wrapper } = Styled;

const LayoutFrame = forwardRef<
  HTMLDivElement,
  PropsWithChildren<LayoutFrameProps>
>(
  (
    { background, padding = 0, children }: PropsWithChildren<LayoutFrameProps>,
    ref
  ) => {
    return (
      <Wrapper color={background} padding={padding} ref={ref}>
        {children}
      </Wrapper>
    );
  }
);

export default LayoutFrame;
