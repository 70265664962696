import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 542px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  @media (max-width: 767px) {
    height: 400px;
  }
  @media (max-width: 480px) {
    height: 300px;
  }
`

export const TitleImage = styled.img`
  width: 733px;
  height: 78px;
  object-fit: contain;
  @media (max-width: 767px) {
    width: 380px;
    height: 50px;
  }
  @media (max-width: 480px) {
    width: 205px;
    height: 62.85px;
  }
`

export const DownloadBtn = styled.div`
  width: 216px;
  height: 70px;
  border-radius: 100px;
  background-color: ${Colors.primary_ios};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 112px;
    height: 38px;
  }
`
