import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { baseSelector } from '../../store/selectors/base'
import { Colors } from '@styles/colors'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import Modal from 'react-modal'
import { useSwipeable } from 'react-swipeable'
import {
  ScheduleType,
  setSelectedDate,
  setSelectedMonth,
  setSelectedYear,
} from '../../store/modules/base'
import { getNow, toDate } from '../../utils/dateHelper'
import ScreenHeader from '../ScreenHeader'
import LayoutFrame from '../base/LayoutFrame'
import MobileCalendar, { CalendarSchedule } from '../base/MobileCalendar'
import Profile from '../base/Profile'
import Spacer from '../base/Spacer'
import { SpoqaBold, SpoqaMedium, SpoqaRegular } from '../base/TextStyle/Spoqa'
import AdsBottomSheet from '../base/bottomsheet/ads_bottom_sheet'
import MobileFloatBtn from '../base/float_btn/mobile_float_btn'
import { AxiosResponse } from 'axios'
import { Schedule } from '@apis/schedule'
import { API_URL, Axios } from '@apis/axios'
import { IpoStockGubun } from '@enums/StockEnum'

const downModalStyle = {
  content: {
    border: 0,
    width: '289px',
    height: '448px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    borderRadius: '20px',
    backgroundColor: Colors.gf,
    padding: '0',
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 20,
  },
}

const Styled = {
  LabelWrapper: styled.div`
    margin-top: 12px;
    padding: 0 20px;
    display: flex;
  `,
  Label: styled.div<{ type: '수요예측일' | '청약일' | '상장일' }>`
    position: relative;
    padding-left: 12px;
    margin-right: 8px;
    font-size: 12px;
    color: ${Colors.g5};

    &:before {
      content: '';
      top: 1px;
      left: 0;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    ${({ type }) => {
      if (!type) {
        return
      }
      switch (type) {
        case '수요예측일':
          return css`
            &:before {
              background-color: ${Colors.predict};
            }
          `
        case '청약일':
          return css`
            &:before {
              background-color: ${Colors.d_day};
            }
          `
        case '상장일':
          return css`
            &:before {
              background-color: ${Colors.open_day};
            }
          `
      }
    }};
  `,
  BottomArea: styled.div<{ minHeight: number }>`
    background: ${Colors.gFa};
    min-height: ${({ minHeight }) => minHeight}px;
  `,
  Content: styled.div`
    padding: 36px 24px;
  `,
  Date: styled.div`
    font-size: 14px;
    color: ${Colors.g12};
    margin-bottom: 12px;
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    box-shadow: 0 5px 20px rgba(109, 109, 109, 0.07);
    border-radius: 10px;
    padding: 20px;
    background-color: ${Colors.gf};
  `,
  Header: styled.div`
    display: flex;
  `,
  Left: styled.div`
    margin-right: 15px;
  `,
  Right: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Name: styled.div`
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
    font-family: 'SpoqaHanSansNeoBold', sans-serif;
  `,
  Desc: styled.div`
    color: ${Colors.g9};
    font-size: 13px;
    font-family: 'SpoqaHanSansNeoRegular', sans-serif;
  `,
  Bottom: styled.div`
    display: flex;
    align-items: center;

    margin-top: 27.5px;
    padding-left: 47px;
  `,
  DayItem: styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: ${({ active }) => (active ? Colors.g5 : Colors.g5)};
    font-family: 'SpoqaHanSansNeoRegular', sans-serif;
  `,
  DayItemTitle: styled.div``,
  DayItemDivider: styled.div`
    width: 1px;
    height: 12px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: ${Colors.gc};
  `,
  Day: styled.div<{ border?: boolean }>`
    margin-top: 6px;
    ${({ border }) =>
      border === false &&
      css`
        border: none;
      `}
    font-size:13px;
    font-family: 'SpoqaHanSansNeoRegular', sans-serif;
  `,
  EmptyBox: styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 22px;
    box-shadow: 0 4px 16px rgba(109, 109, 109, 0.07);
    border-radius: 8px;
    color: ${Colors.ga};
    font-size: 14px;
    font-weight: 700;
    background-color: white;
  `,
  ListViewFrame: styled.div`
    display: flex;
    flex-direction: column;
  `,
  TabpFrame: styled.div`
    display: flex;
    margin-top: 30px;
    width: 100%;
    justify-content: space-around;
    padding: 0 20px;
  `,
  TabButton: styled.div<{ active?: boolean }>`
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    border-radius: 50px;
    cursor: pointer;

    color: ${({ active }) => (active ? Colors.g5 : Colors.g9)};
    background-color: ${({ active }) => (active ? Colors.gf : null)};
    box-shadow: ${({ active }) =>
      active ? '0px 3.29049px 13.162px rgba(109, 109, 109, 0.07)' : null};

    font-family: ${({ active }) =>
      active ? 'SpoqaHanSansNeoBold' : 'SpoqaHanSansNeoRegular'};
  `,

  TabButtonNumber: styled.span<{ active?: boolean }>`
    margin-left: 4px;
    font-size: 16px;
    color: ${({ active }) => (active ? Colors.g5 : Colors.g9)};
    font-family: ${({ active }) =>
      active ? 'SpoqaHanSansNeoRegular' : 'SpoqaHanSansNeoLight'};
  `,

  ListViewCardFrame: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  `,

  DownImage: styled.img`
    width: 101px;
    height: 93px;
    margin-bottom: 30px;
  `,

  DownModalContainer: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
  `,

  DownSign: styled.img`
    width: 32px;
    height: 26px;
    margin-top: 16px;
  `,

  YellowBar: styled.div`
    width: 150px;
    height: 36px;
    border-radius: 6.75px;
    background-color: ${Colors.primary_ios};
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,

  StoreBtnContainer: styled.div`
    width: 442px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
  `,

  StoreBtn: styled.div`
    width: 216px;
    height: 60px;
    background-color: ${Colors.gE2};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  `,

  StoreBtnLogo: styled.img`
    width: 42px;
    height: 45px;
  `,

  CloseText: styled.div`
    margin-top: 16px;
    border-bottom: solid 1px ${Colors.g9};
    cursor: pointer;
  `,

  IntroduceIPOBtn: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 24px;
    background: rgba(252, 203, 51, 0.5);
    border-radius: 100px;
  `,

  ArrowFront: styled.img`
    width: 6px;
  `,
}

const {
  LabelWrapper,
  Label,
  BottomArea,
  Content,
  Date,
  Card,
  Header,
  Left,
  Right,
  Desc,
  Name,
  Bottom,
  DayItem,
  DayItemDivider,
  DayItemTitle,
  Day,
  EmptyBox,
  ListViewFrame,
  TabpFrame,
  TabButton,
  TabButtonNumber,
  ListViewCardFrame,
  DownImage,
  DownModalContainer,
  DownSign,
  YellowBar,
  CloseText,
  IntroduceIPOBtn,
} = Styled

const SWIPER_CONFIG = {
  delta: 10, // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
  trackTouch: true, // track touch input
  trackMouse: false, // track mouse input
  rotationAngle: 0, // set a rotation angle
  swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
  touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
}

interface HighlightDayIndexItem {
  exEndDate: string
  listingDate: string
  offeringStartDate: string | null
}

function MainPage(): ReactElement {
  const {
    selectedYear,
    selectedMonth,
    selectedFilter,
    selectedDate,
    selectedDateSchedule,
  } = useSelector(baseSelector)
  const dispatch = useDispatch()

  const [isShowCalendarView, setIsShowCalendarView] = useState(true)
  const [openDownModal, setOpenDownModal] = useState(false)
  const [oepnBottomSheet, setOpenBottomSheet] = useState(false)

  const getSchedule = async (
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<Schedule>> => {
    return Axios.get(API_URL.SCHEDULE, {
      params: { startDate, endDate },
    })
  }

  const [schedules, setSchedules] = useState<CalendarSchedule[] | undefined>(
    undefined
  )
  const sortSchedules = useCallback(
    (schedules: Schedule) => {
      const newSchedules = schedules.data.ipoStocks
        ?.filter((item) => selectedFilter.type.includes(item.gubun))
        ?.filter((item) =>
          item.listingDate && selectedFilter.hideCompleted
            ? getNow().isSameOrBefore(toDate(item.listingDate))
            : true
        )
        ?.map((item) => {
          const baseItem = {
            title: item.name,
            data: { ...item },
          }
          // 상장일
          const listing = {
            ...baseItem,
            type: ScheduleType.LISTING,
            id: `${item.id}-${ScheduleType.LISTING}`,
            start: item.listingDate,
            end: item.listingDate,
            color: Colors.calender03_ios,
            priority: 1,
          }

          // 청약일
          const offering = {
            ...baseItem,
            type: ScheduleType.OFFERING,
            id: `${item.id}-${ScheduleType.OFFERING}`,
            start: item.offeringStartDate,
            end: item.offeringEndDate,
            color: Colors.calender02_ios,
            priority: 0,
          }

          // 수요예측일
          const ex = {
            ...baseItem,
            type: ScheduleType.EX,
            id: `${item.id}-${ScheduleType.EX}`,
            start: item.exEndDate,
            end: item.exEndDate,
            color: Colors.calender01_ios,
            priority: 2,
          }

          return [offering, listing, ex]
        })
        .flat()
        .filter((item) =>
          selectedFilter.schedule.includes(
            (item as unknown as CalendarSchedule).type
          )
        )
      setSchedules(newSchedules ?? [])
    },
    [selectedFilter]
  )

  const [onceFetched, setOnceFetched] = useState(false)

  const changeDate = useCallback(
    async (date: string): Promise<void> => {
      const response = await getSchedule(
        date,
        toDate(date).endOf('month').add(1, 'day').format('YYYY-MM-DD')
      )

      if (!onceFetched) {
        setOnceFetched(true)
      }

      sortSchedules(response.data)
    },
    [onceFetched, sortSchedules]
  )

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (selectedYear === null || selectedMonth === null) {
        return
      }
      if (eventData.dir === 'Right') {
        if (selectedMonth === 0) {
          const target = dayjs()
            .set('year', selectedYear - 1)
            .set('month', 11)
            .set('date', 1)
            .format('YYYY-MM-DD')
          changeDate(target).then(() => {
            dispatch(setSelectedMonth(11))
            dispatch(setSelectedYear(selectedYear - 1))
            dispatch(setSelectedDate(target))
          })
        } else {
          const target = dayjs()
            .set('year', selectedYear)
            .set('month', selectedMonth - 1)
            .set('date', 1)
            .format('YYYY-MM-DD')
          changeDate(target).then(() => {
            dispatch(setSelectedMonth(selectedMonth - 1))
            dispatch(setSelectedDate(target))
          })
        }
      } else if (eventData.dir === 'Left') {
        if (selectedMonth === 11) {
          const target = dayjs()
            .set('year', selectedYear + 1)
            .set('month', 0)
            .set('date', 1)
            .format('YYYY-MM-DD')
          changeDate(target).then(() => {
            dispatch(setSelectedMonth(0))
            dispatch(setSelectedYear(selectedYear + 1))
            dispatch(setSelectedDate(target))
          })
        } else {
          const target = dayjs()
            .set('year', selectedYear)
            .set('month', selectedMonth + 1)
            .set('date', 1)
            .format('YYYY-MM-DD')
          changeDate(target).then(() => {
            dispatch(setSelectedMonth(selectedMonth + 1))
            dispatch(setSelectedDate(target))
          })
        }
      }
    },
    ...SWIPER_CONFIG,
  })

  const getHighlightDayIndex = useCallback((item: HighlightDayIndexItem) => {
    const now = getNow()
    if (now.isSameOrAfter(item.listingDate, 'day')) {
      return 2
    }
    if (now.isAfter(item.exEndDate, 'day')) {
      return 1
    }
    if (now.isSameOrBefore(item.exEndDate, 'day')) {
      return 0
    }
    return undefined
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const stopAds = localStorage.getItem('stopAds')
      const today = moment().format('YYYY-MM-DD')

      if (stopAds !== today) {
        setOpenBottomSheet(true)
        return
      }
    }, 10000)
  }, [])

  useEffect(() => {
    dispatch(setSelectedYear(selectedYear ?? getNow().year()))
    dispatch(setSelectedMonth(selectedMonth ?? getNow().month()))
  }, [dispatch, selectedMonth, selectedYear])

  useEffect(() => {
    if (onceFetched && !selectedDate) {
      dispatch(setSelectedDate(getNow().format('YYYY-MM-DD')))
    }
  }, [dispatch, onceFetched, selectedDate])

  useEffect(() => {
    if (selectedYear === null || selectedMonth === null) {
      return
    }
    changeDate(
      dayjs()
        .set('year', selectedYear)
        .set('month', selectedMonth)
        .set('date', 1)
        .format('YYYY-MM-DD')
    )
  }, [selectedYear, selectedMonth, changeDate])

  //! listView

  const [selectedTap, setSelectedTap] = useState(0)

  const uniqSchedules = _.uniqBy(schedules, 'title')

  const filteredSchedules = uniqSchedules?.filter((item) =>
    selectedFilter.type.includes(item.data?.gubun)
  )

  const filteredPreOfferingSchedules = filteredSchedules?.filter(
    (item) => item.data?.status === 'preoffering'
  )

  const filteredOfferingSchedules = filteredSchedules?.filter(
    (item) => item.data?.status === 'offering'
  )

  const filteredpreListingSchedules = filteredSchedules?.filter(
    (item) => item.data?.status === 'prelisting'
  )

  const filteredlistingSchedules = filteredSchedules?.filter(
    (item) => item.data?.status === 'listing'
  )

  const entireCount: number = filteredSchedules?.length ?? 0

  const preOfferingCount = filteredPreOfferingSchedules?.length ?? 0

  const offeringCount = filteredOfferingSchedules?.length ?? 0

  const preListingCount = filteredpreListingSchedules?.length ?? 0

  const listingCount = filteredlistingSchedules?.length ?? 0

  const currentTap =
    selectedTap === 0
      ? uniqSchedules
      : selectedTap === 1
      ? filteredPreOfferingSchedules
      : selectedTap === 2
      ? filteredOfferingSchedules
      : selectedTap === 3
      ? filteredpreListingSchedules
      : filteredlistingSchedules

  return (
    <LayoutFrame background={Colors.gf}>
      <Spacer height={70} />
      <ScreenHeader
        setDate={changeDate}
        setIsShowCalendarView={setIsShowCalendarView}
        isShowCalendarView={isShowCalendarView}
      />
      {isShowCalendarView && (
        <LabelWrapper>
          <Label type={'수요예측일'}>수요예측일</Label>
          <Label type={'청약일'}>청약일</Label>
          <Label type={'상장일'}>상장일</Label>
        </LabelWrapper>
      )}

      {isShowCalendarView && (
        <div {...handlers}>
          <MobileCalendar
            schedules={schedules ?? []}
            onClickDay={(date: string) => dispatch(setSelectedDate(date))}
            year={selectedYear ?? getNow().year()}
            month={selectedMonth ?? getNow().month()}
            selectedDate={selectedDate}
          />
        </div>
      )}
      {isShowCalendarView ? (
        <BottomArea minHeight={window.innerHeight * 0.4}>
          <Content>
            <Date>
              {selectedDate && toDate(selectedDate).format('MM월 DD일')}
            </Date>
            {selectedDateSchedule?.filter((item) => item?.data?.id)?.length ? (
              selectedDateSchedule
                ?.filter((item) => item?.data?.id)
                ?.map((item, itemIndex) => (
                  <Card
                    key={`${item.data.code}-${itemIndex}`}
                    onClick={() => setOpenDownModal(true)}
                  >
                    <Header>
                      <Left>
                        <Profile size={32} src={item.data.imgUrl} />
                      </Left>
                      <Right>
                        <Name>{item.title}</Name>
                        <Desc>
                          {IpoStockGubun[item.data.gubun]} | 공모가{' '}
                          {item.data.offeringPrice
                            ? item.data.offeringPrice.toLocaleString()
                            : `${item.data.offeringDownPrice.toLocaleString()} - ${item.data.offeringUpPrice.toLocaleString()}` ??
                              '-'}
                          원
                        </Desc>
                      </Right>
                    </Header>
                    <Bottom>
                      <DayItem
                        active={
                          getHighlightDayIndex(
                            item.data as unknown as HighlightDayIndexItem
                          ) === 0
                        }
                      >
                        <DayItemTitle>수요예측일</DayItemTitle>
                        <Day>{toDate(item.data.exEndDate).format('MM.DD')}</Day>
                      </DayItem>
                      <DayItemDivider />
                      <DayItem
                        active={
                          getHighlightDayIndex(
                            item.data as unknown as HighlightDayIndexItem
                          ) === 1
                        }
                      >
                        <DayItemTitle>청약일</DayItemTitle>
                        <Day>
                          {toDate(item.data.offeringStartDate).format('MM.DD')}{' '}
                          - {toDate(item.data.offeringEndDate).format('MM.DD')}
                        </Day>
                      </DayItem>
                      <DayItemDivider />
                      <DayItem
                        active={
                          getHighlightDayIndex(
                            item.data as unknown as HighlightDayIndexItem
                          ) === 2
                        }
                      >
                        <DayItemTitle>상장일</DayItemTitle>
                        <Day border={false}>
                          {item.data.listingDate
                            ? toDate(item.data.listingDate).format('MM.DD')
                            : '미정'}
                        </Day>
                      </DayItem>
                    </Bottom>
                  </Card>
                ))
            ) : (
              <EmptyBox>IPO 일정이 없어요</EmptyBox>
            )}
          </Content>
        </BottomArea>
      ) : (
        <ListViewFrame>
          <TabpFrame>
            <TabButton
              active={selectedTap === 0}
              onClick={() => {
                setSelectedTap(0)
              }}
            >
              전체
              <TabButtonNumber active={selectedTap === 0}>
                {entireCount}
              </TabButtonNumber>
            </TabButton>
            <TabButton
              active={selectedTap === 1}
              onClick={() => {
                setSelectedTap(1)
              }}
            >
              청약전
              <TabButtonNumber active={selectedTap === 1}>
                {preOfferingCount}
              </TabButtonNumber>
            </TabButton>
            <TabButton
              active={selectedTap === 2}
              onClick={() => {
                setSelectedTap(2)
              }}
            >
              청약중
              <TabButtonNumber active={selectedTap === 2}>
                {offeringCount}
              </TabButtonNumber>
            </TabButton>
            <TabButton
              active={selectedTap === 3}
              onClick={() => {
                setSelectedTap(3)
              }}
            >
              상장전
              <TabButtonNumber active={selectedTap === 3}>
                {preListingCount}
              </TabButtonNumber>
            </TabButton>
            <TabButton
              active={selectedTap === 4}
              onClick={() => {
                setSelectedTap(4)
              }}
            >
              상장완료
              <TabButtonNumber active={selectedTap === 4}>
                {listingCount}
              </TabButtonNumber>
            </TabButton>
          </TabpFrame>
          <ListViewCardFrame>
            {currentTap
              ?.filter((item) => item?.data?.id)
              ?.map((item, itemIndex) => (
                <Card
                  key={`${item.data.code}-${itemIndex}`}
                  onClick={() => {
                    setOpenDownModal(true)
                  }}
                >
                  <Header>
                    <Left>
                      <Profile size={32} src={item.data.imgUrl} />
                    </Left>
                    <Right>
                      <Name>{item.title}</Name>
                      <Desc>
                        {IpoStockGubun[item.data.gubun]} | 공모가{' '}
                        {item.data.offeringPrice
                          ? item.data.offeringPrice.toLocaleString()
                          : `${item.data.offeringDownPrice.toLocaleString()} - ${item.data.offeringUpPrice.toLocaleString()}` ??
                            '-'}
                        원
                      </Desc>
                    </Right>
                  </Header>
                  <Bottom>
                    <DayItem
                      active={
                        getHighlightDayIndex(
                          item.data as unknown as HighlightDayIndexItem
                        ) === 0
                      }
                    >
                      <DayItemTitle>수요예측일</DayItemTitle>
                      <Day>{toDate(item.data.exEndDate).format('MM.DD')}</Day>
                    </DayItem>
                    <DayItemDivider />
                    <DayItem
                      active={
                        getHighlightDayIndex(
                          item.data as unknown as HighlightDayIndexItem
                        ) === 1
                      }
                    >
                      <DayItemTitle>청약일</DayItemTitle>
                      <Day>
                        {toDate(item.data.offeringStartDate).format('MM.DD')} -{' '}
                        {toDate(item.data.offeringEndDate).format('MM.DD')}
                      </Day>
                    </DayItem>
                    <DayItemDivider />
                    <DayItem
                      active={
                        getHighlightDayIndex(
                          item.data as unknown as HighlightDayIndexItem
                        ) === 2
                      }
                    >
                      <DayItemTitle>상장일</DayItemTitle>
                      <Day border={false}>
                        {item.data.listingDate
                          ? toDate(item.data.listingDate).format('MM.DD')
                          : '미정'}
                      </Day>
                    </DayItem>
                  </Bottom>
                </Card>
              ))}
          </ListViewCardFrame>
        </ListViewFrame>
      )}
      {/* <MainFooter /> */}
      <Modal
        isOpen={openDownModal}
        onRequestClose={() => setOpenDownModal(false)}
        style={downModalStyle}
        ariaHideApp={false}
      >
        <DownModalContainer>
          <DownImage src={'images/down-image.png'} />
          <SpoqaRegular size={15}>종목에 대한 매력지수와</SpoqaRegular>
          <Spacer height={6} />
          <SpoqaRegular size={15}>자세한 정보를</SpoqaRegular>
          <Spacer height={6} />
          <div>
            <SpoqaBold size={15}>일육공 어플에서 확인</SpoqaBold>
            <SpoqaRegular size={15}>해보세요!</SpoqaRegular>
          </div>

          <Spacer height={16} />

          <IntroduceIPOBtn
            onClick={() => {
              window.location.href = '/info'
            }}
          >
            <SpoqaMedium size={13}>일육공 매력지수 알아보기</SpoqaMedium>
            <Spacer width={6} />
            <ArrowForwardIosIcon style={{ width: '14px' }} />
          </IntroduceIPOBtn>

          <DownSign src='images/down-sign.png' />
          <YellowBar
            onClick={() => {
              if (isMobile) {
                window.location.href = 'https://160.page.link/landing'
              } else {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
                )
              }
            }}
          >
            <SpoqaMedium size={14}>앱 다운로드</SpoqaMedium>
          </YellowBar>

          <Spacer height={32} />

          <Spacer height={1} background={Colors.ge} width={289} />

          <CloseText
            onClick={() => {
              setOpenDownModal(false)
            }}
          >
            <SpoqaMedium size={14} color={Colors.g9}>
              닫기
            </SpoqaMedium>
          </CloseText>
        </DownModalContainer>
      </Modal>
      <AdsBottomSheet
        isOpen={openDownModal ? false : oepnBottomSheet}
        setIsOpen={setOpenBottomSheet}
      />
      {oepnBottomSheet ? null : <MobileFloatBtn />}
    </LayoutFrame>
  )
}

export default MainPage
