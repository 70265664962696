import { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../styles/colors'
import Spacer from '../base/Spacer'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '../base/TextStyle/Spoqa'

export const Styled = {
  Container: styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
  `,

  ContentContainer: styled.div`
    display: flex;
  `,

  ContentItem: styled('img')``,

  BgBox: styled.div`
    width: 100%;
    height: 690px;
    background-color: ${Colors.maingrey_bg};
    /* background-color: ${Colors.primary_ios}; */
    position: absolute;
    top: 178px;
    z-index: 1;
  `,
  TopContentContainer: styled.div`
    width: 320px;
    height: 550px;
    position: relative;
    display: flex;
    align-items: center;
  `,
  BottomContentContainer: styled.div`
    width: 320px;
    height: 590px;
    position: relative;
    display: flex;
    align-items: center;
  `,

  TopMainBox: styled.div`
    width: 319px;
    height: 330px;
    background-color: ${Colors.gf};
    box-shadow: -28px 28px 70px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    position: absolute;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  TopSubBox: styled.div`
    width: 319px;
    height: 255px;
    border-radius: 20px;
    background-color: ${Colors.ge};
    position: absolute;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    padding-bottom: 30px;
  `,
  BottomMainBox: styled.div`
    width: 319px;
    height: 356px;
    background-color: ${Colors.gf};
    box-shadow: -28px 28px 70px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  BottomSubBox: styled.div`
    width: 319px;
    height: 275px;
    border-radius: 20px;
    background-color: ${Colors.ge};
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    padding-bottom: 40px;
  `,
  TapBox: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
  `,

  TapCenterLine: styled.div`
    width: 2px;
    height: 20px;
    background-color: ${Colors.gE2};
  `,
  TapTextBox: styled.div`
    width: 299px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,

  SubTextContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
}

const {
  Container,

  TopContentContainer,
  BottomContentContainer,
  BgBox,

  TopMainBox,
  TopSubBox,
  BottomMainBox,
  BottomSubBox,
  TapBox,
  TapCenterLine,
  TapTextBox,
  ContentItem,
  SubTextContainer,
} = Styled

const ScreenThree = () => {
  const [isTopTab, setIsTopTab] = useState(true)
  const [isBottomTab, setIsBottomTab] = useState(true)

  return (
    <Container>
      <BgBox />
      <TopContentContainer>
        <TopMainBox>
          <Spacer height={20} />
          {isTopTab ? (
            <TapBox>
              <TapTextBox
                onClick={() => {
                  setIsTopTab(false)
                }}
              >
                <SpoqaRegular size={13} color={Colors.ga}>
                  2021년
                </SpoqaRegular>
              </TapTextBox>
              <TapCenterLine />
              <TapTextBox>
                <SpoqaBold size={13}>2022년</SpoqaBold>
              </TapTextBox>
            </TapBox>
          ) : (
            <TapBox>
              <TapTextBox>
                <SpoqaBold size={13}>2021년</SpoqaBold>
              </TapTextBox>
              <TapCenterLine />
              <TapTextBox
                onClick={() => {
                  setIsTopTab(true)
                }}
              >
                <SpoqaRegular size={13} color={Colors.ga}>
                  2022년 1~3분기
                </SpoqaRegular>
              </TapTextBox>
            </TapBox>
          )}
          <Spacer height={32.06} />
          {isTopTab ? (
            <ContentItem
              src='/images/infopage/item8-1.png'
              style={{ width: '279px', height: '242px' }}
            />
          ) : (
            <ContentItem
              src='/images/infopage/item9-1.png'
              style={{ width: '279px', height: '242px' }}
            />
          )}
        </TopMainBox>
        <TopSubBox>
          <SubTextContainer>
            <SpoqaLight size={14}>
              상장하는 종목의 다양한 정보와 일육공만의
            </SpoqaLight>
            <Spacer height={4} />
            <SpoqaLight size={14}>
              노하우를 담아 단 하나의 평가지표인
            </SpoqaLight>
            <Spacer height={4} />
            <div>
              <SpoqaMedium size={14}>일육공 매력지수</SpoqaMedium>
              <SpoqaLight size={14}>를 개발하였어요.</SpoqaLight>
            </div>
            <Spacer height={16} />
            <SpoqaMedium size={14}>
              과거 상장된 모든 종목과 일육공 매력지수 50점
            </SpoqaMedium>
            <Spacer height={4} />
            <div>
              <SpoqaMedium size={14}>이상의 종목을 비교 </SpoqaMedium>
              <SpoqaLight size={14}>해보면 보다 적은 청약 종목</SpoqaLight>
            </div>
            <Spacer height={4} />
            <div>
              <SpoqaLight size={14}>및 투자금액에도 </SpoqaLight>
              <SpoqaMedium size={14}>확연히 높은 투자손익 및 투자</SpoqaMedium>
            </div>
            <Spacer height={4} />
            <div>
              <SpoqaMedium size={14}>수익확률을 확인</SpoqaMedium>
              <SpoqaLight size={14}>해볼 수 있어요.</SpoqaLight>
            </div>
            <Spacer height={10} />
          </SubTextContainer>
        </TopSubBox>
      </TopContentContainer>
      <Spacer height={100} />

      <BottomContentContainer>
        <BottomMainBox>
          <Spacer height={20} />
          {isBottomTab ? (
            <TapBox>
              <TapTextBox
                onClick={() => {
                  setIsBottomTab(false)
                }}
              >
                <SpoqaRegular size={13} color={Colors.ga}>
                  2021년
                </SpoqaRegular>
              </TapTextBox>
              <TapCenterLine />
              <TapTextBox>
                <SpoqaBold size={13}>2022년</SpoqaBold>
              </TapTextBox>
            </TapBox>
          ) : (
            <TapBox>
              <TapTextBox>
                <SpoqaBold size={13}>2021년</SpoqaBold>
              </TapTextBox>
              <TapCenterLine />
              <TapTextBox
                onClick={() => {
                  setIsBottomTab(true)
                }}
              >
                <SpoqaRegular size={13} color={Colors.ga}>
                  2022년 1~3분기
                </SpoqaRegular>
              </TapTextBox>
            </TapBox>
          )}
          <Spacer height={32.06} />

          {isBottomTab ? (
            <ContentItem
              src='/images/infopage/item8-2.png'
              style={{ width: '279px', height: '267.74px' }}
            />
          ) : (
            <ContentItem
              src='/images/infopage/item9-2.png'
              style={{ width: '279px', height: '267.74px' }}
            />
          )}
        </BottomMainBox>
        <BottomSubBox>
          <SubTextContainer>
            <SpoqaLight size={13}>
              공모주 안에서도 수익이 발생할 확률이 높은
            </SpoqaLight>
            <Spacer height={4} />
            <SpoqaLight size={13}>
              공모주가 있어요. 일육공의 매력지수로 구간을
            </SpoqaLight>

            <Spacer height={4} />
            <div>
              <SpoqaLight size={13}>나누어 살펴보면, </SpoqaLight>
              <SpoqaMedium size={13}>50점 이상만을 투자할 경우</SpoqaMedium>
            </div>
            <Spacer height={4} />
            <div>
              <SpoqaMedium size={13}>전체 수익률이 확연히 증가</SpoqaMedium>
              <SpoqaLight size={13}>하는 것을 확인할</SpoqaLight>
            </div>
            <Spacer height={4} />
            <SpoqaLight size={13}>수 있어요.</SpoqaLight>
            <Spacer height={16} />

            <SpoqaBold size={13}>
              일육공이 자체 개발한 알고리즘과 전문가의
            </SpoqaBold>
            <Spacer height={4} />
            <SpoqaBold size={13}>
              의견을 통해 좋은 공모주를 선별할 수 있도록
            </SpoqaBold>
            <Spacer height={4} />
            <SpoqaBold size={13}>도와드릴게요!</SpoqaBold>
          </SubTextContainer>
        </BottomSubBox>
      </BottomContentContainer>
      <Spacer height={60} />
    </Container>
  )
}

export default ScreenThree
