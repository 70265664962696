import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'

import Footer from './footer'
import SimulatedHeader from '../SimulatedHeader'
import useRouterHistoryHandler from '@hooks/useRouterHistoryHandler'
import { useDispatch } from 'react-redux'
import { setUser } from '../../store/modules/userSlice'
import { useAppSelector } from '../../store/selectors/utils'
import useGetBottomInset from '@hooks/useGetBottomInset'

const Index = (): ReactElement => {
  const { goBack } = useRouterHistoryHandler()
  const dispatch = useDispatch()
  const data = useAppSelector(state => state.userReducer);
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const bottomInset = useGetBottomInset();
function isSignCheck(){
  dispatch(setUser({name:data.name, phone:data.phone, password:data.password, infoAgree:true  }));
  goBack();
}

  return (
    <>
        <Container>
            <SimulatedHeader notClear={true}/>
            <IframeWrapper>
                <Iframe src="https://metalogos.oopy.io/mli/160/eventinfo" title="WebView" />
            </IframeWrapper>
            <Footer isSignCheck={isSignCheck} paddingBottom={!isFromApp ? bottomInset: 24} isFromApp={isFromApp}/>
        </Container>
    </>
  )
}

const IframeWrapper = styled.div`
flex: 1;
overflow: hidden;
margin-top: 42px;
justify-content: start;

`;

const Iframe = styled.iframe`
width: 100vw;
height: 100vh;
border: none;
`;

const Container= styled.div` 
width: 100%;
top: 0px;
left: 0px;
display: flex;
flex-direction: column;
`;

export default Index
