import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import SimulatedHeader from '../SimulatedHeader'
import Footer from './footer'
import NoInfoModal from './noInfoModal'
import useGetSimulatedUserData from '@hooks/useGetSimulatedUserData'
import { formatPhoneNumber } from '../../utils/formatPhoneNumber'
import useRouterHistoryHandler from '@hooks/useRouterHistoryHandler'
import { useAppDispatch, useAppSelector } from '../../store/selectors/utils'
import { setUser } from '../../store/modules/userSlice'
import useGetBottomInset from '@hooks/useGetBottomInset'

const Index = (): ReactElement => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [focusedInput, setFocusedInput] = useState('')
  const [isAble, setIsAble] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isFetch, setIsFetch] = useState(false)
  const dispatch = useAppDispatch()

  const { simulatedCurrentData } = useGetSimulatedUserData(
    name,
    formatPhoneNumber(phone),
    isFetch
  )

  const { handleGoSimulatedChangePwPage } = useRouterHistoryHandler()
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const bottomInset = useGetBottomInset()

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleFocus = (inputName: string) => {
    setFocusedInput(inputName)
  }

  const handleBlur = () => {
    setFocusedInput('')
  }

  useEffect(() => {
    if (name.trim() !== '' && phone.length >= 10) {
      setIsAble(true)
      return
    } else setIsAble(false)
  }, [name, phone.length])

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const numericValue = value.replace(/[^0-9]/g, '')
    setPhone(numericValue)
  }

  function isSignCheck() {
    //API 패치 후 조건에 따라
    // if(true){
    //     // 비밀번호 재설정 페이지로 라우팅
    // } else {
    //     // 신청정보가 없는 모달 보여주기

    // }

    // 비밀번호 재설정에 name,phone값이 store에 저장되지 않아 추가함
    // todo : store password,infoAgree 선택값으로 주거나, 뭔가 변경이 필요함
    if (simulatedCurrentData.isSuccess) {
      dispatch(
        setUser({
          name,
          phone,
          password: '',
          infoAgree: false,
        })
      )
      handleGoSimulatedChangePwPage()
      return
    }
    if (simulatedCurrentData.isError) {
      setShowModal(true)
      return
    }
  }

  return (
    <>
      <SimulatedHeader step={1} />
      <Container>
        <DescriptionArea>
          <Title>
            비밀번호 재설정을 위해 <br />
            이벤트 신청 정보를 확인할게요
          </Title>
        </DescriptionArea>
        <InputArea className={focusedInput === 'name' ? 'focused' : ''}>
          <InputTitle>이름</InputTitle>
          <Input
            placeholder='이름 입력'
            value={name}
            onChange={handleNameChange}
            onFocus={() => handleFocus('name')}
          />
        </InputArea>
        <InputArea className={focusedInput === 'phone' ? 'focused' : ''}>
          <InputTitle>전화번호</InputTitle>
          <Input
            placeholder='전화번호 입력(- 제외)'
            value={phone}
            inputMode='numeric'
            onChange={handlePhoneChange}
            onFocus={() => handleFocus('phone')}
          />
        </InputArea>
        <Footer
          isAble={isAble}
          isSignCheck={isSignCheck}
          setIsFetch={setIsFetch}
          paddingBottom={!isFromApp ? bottomInset : 24}
        />
        <NoInfoModal isOpen={showModal} setIsOpen={setShowModal} />
      </Container>
    </>
  )
}

const Input = styled.input`
  background-color: #f5f5f9;
  border-radius: 4px;
  padding: 24px;
  border: none;
  font-family: Pretendard500, sans-serif;
  font-size: 18px;
  line-height: 21.6px;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 19.2px;
  }
`

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-bottom: 51px;
  position: relative;
`

const InputTitle = styled.div`
  font-family: Pretendard500, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #999ba9;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Title = styled.div`
  font-size: 32px;
  line-height: 38.4px;
  font-family: Pretendard700, sans-serif;
  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 22px;
  }
`

const DescriptionArea = styled.div`
  padding: 0 24px;
  margin-top: 80px;
  margin-bottom: 32px;
`

const Container = styled.div`
  width: 800px;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media (max-width: 800px) {
    width: 100%;
  }
`

export default Index
