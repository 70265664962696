import { configureStore, PreloadedState } from '@reduxjs/toolkit'
import base from './modules/base'
import entryPointSlice from './modules/entryPointSlice'
import userSlice from "./modules/userSlice"

const rootReducer = {
  base: base,
  entryReducer: entryPointSlice,
  userReducer: userSlice
}
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV === 'development',
})
export type RootState = ReturnType<typeof store.getState>
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export default store
