import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${Colors.gf};
  z-index: 1;
  height: 1642px;
  @media (max-width: 1430px) {
    height: auto;
  }
`

export const ContentWrapper = styled.div`
  width: 70%;
  min-height: 1159px;
  position: relative;
  @media (max-width: 1430px) {
    position: relative;
    height: auto;
    width: 100%;
    flex-direction: column;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 1430px) {
    margin-top: 60px;
  }
  @media (max-width: 767px) {
    margin-top: 52px;
  }
`
export const ImageLeft = styled.img`
  position: absolute;
  bottom: 10%;
  width: 45%;
`
export const ImageBorder = styled.img`
  position: absolute;
  bottom: 28%;
  left: 45%;
  width: 10%;
`
export const ImageRight = styled.img`
  position: absolute;
  bottom: 0;
  left: 43.5%;
  width: 55%;
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

//mobile
export const ImageMobile = styled.img`
  width: 100%;
`
