import { useLocation } from 'react-router-dom'

const useSearchParams = () => {
  const { search } = useLocation()

  const getParam = (key: string) => {
    return new URLSearchParams(search).get(key) || ''
  }
  const getParams = (keys: string[]) => {
    return keys.reduce((acc, key) => {
      acc[key] = getParam(key)
      return acc
    }, {} as Record<string, string>)
  }
  return { getParam, getParams }
}
export default useSearchParams
