import React from 'react'
import styled from 'styled-components'

const Container = styled.div<{ isFirst: boolean }>`
  width: 100%;
  height: 164px;
  background-color: #fff;
  border-top: 1px solid #d8d8d8;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding: 24px 15% 0;
  min-width: 800px;

  @media (max-width: 800px) {
    min-width: auto;
    flex-direction: column;
    padding-left: 24px;
  }
`
const CorporateInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`
const Address = styled.div``

const AddressDetaiil = styled.div`
  margin-top: 12px;
`
const BusinessInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  line-height: 19.09px;

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 16.71px;
  }
`

const Name = styled.div`
  display: flex;
  gap: 8px;
`
const CopyRightContainer = styled.div`
  width: 100%;
  display: flex;
`

const CopyRight = styled.div`
  display: flex;
`

const LeftText = styled.div`
  color: #808491;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19.09px;
  margin-top: 12px;
  @media (max-width: 800px) {
    font-size: 11px;
    line-height: 13.13px;
    margin-top: 0;
  }
`

const FooterLinks = styled.div`
  display: flex;
  gap: 16px;
  font-size: 16px;
  font-family: Pretendard600, sans-serif;
  line-height: 19.09px;

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 16.71px;
    margin-top: 24px;
  }
`
const Navigate = styled.div`
  cursor: pointer;
  word-break: keep-all;
`

// 시뮬레이션 게임 이벤트 작업에 만든 Footer

const Footer = (props: { isFirst?: boolean }) => {
  const { isFirst } = props
  return (
    <Container isFirst={isFirst || false}>
      <div>
        <CorporateInfo>
          <BusinessInfo>
            <Name>
              엠엘투자자문(주)
              <img src={'/img/main/footer_line.svg'} alt='' />
              대표 윤도선
            </Name>
            <Address>{`사업자등록번호 : 341-88-02703`}</Address>
            <AddressDetaiil>{`서울특별시 강남구 역삼로17길 10 부강빌딩 3층`}</AddressDetaiil>
          </BusinessInfo>
        </CorporateInfo>
        <CopyRightContainer>
          <CopyRight>
            <LeftText>
              ⓒ ML Investment Advisory Co.,Ltd. All Rights Reserved.
            </LeftText>
          </CopyRight>
        </CopyRightContainer>
      </div>
      <div>
        <FooterLinks>
          <Navigate
            onClick={() =>
              window.open('https://metalogos.oopy.io/mli/160/privacy_policy')
            }
          >
            개인정보처리방침
          </Navigate>
          <Navigate
            onClick={() =>
              window.open('https://metalogos.oopy.io/mli/160/serviceinfo')
            }
          >
            서비스이용약관
          </Navigate>
        </FooterLinks>
      </div>
    </Container>
  )
}

export default Footer
