import Spacer from '../../base/Spacer'
import { SpoqaBold } from '../../base/TextStyle/Spoqa'
import {
  Container,
  Image,
  ImageWrapper,
  TextWrapper,
  ImageMobile,
  ImageMobileLine,
  MobileWrapper,
  RegularText,
} from './styles'
import { Colors } from '@styles/colors'
import { useMediaQuery } from 'react-responsive'
import { BoldText, PaddingWrapper } from '../SectionSeven/styles'

const SectionTen = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  const isTabletL = useMediaQuery({ query: '(max-width: 1430px)' })
  return (
    <Container>
      {isTabletL ? (
        <MobileWrapper>
          {isTablet ? (
            <ImageMobileLine
              src={'images/main/section-ten/line_mobile.png'}
              alt='line_mobile'
            />
          ) : null}
          <Spacer height={120} />
          <PaddingWrapper>
            <BoldText
              size={isMobile ? 14 : 24}
              color={Colors.primary_ios}
              weight={700}
            >
              매력지수·자문 정보
            </BoldText>
            <Spacer height={16} />
            <SpoqaBold
              size={isMobile ? 22 : 46}
              color={Colors.g12}
              lineHeight={isMobile ? 28 : 60}
            >
              투자판단,
              <br />
              매력지수로 똑똑하게
            </SpoqaBold>
            <Spacer height={32} />
            <RegularText isMobile={isMobile}>
              많은 정보를 확인하고 분석해보아도
              <br />
              투자를 해야 할지 말아야 할지 헷갈리시죠?
              <br />
              일육공만의 퀀트분석으로 개발한
              <br />
              매력지수가 답을 알려드려요! <br />
            </RegularText>
          </PaddingWrapper>
          {isTablet ? (
            <ImageWrapper>
              <ImageMobile
                src={'images/main/section-ten/mobile.png'}
                alt='mobile'
              />
            </ImageWrapper>
          ) : (
            <ImageWrapper>
              <Image
                src={'/images/main/section-ten/phone_detail.png'}
                alt='phone_detail'
              />
            </ImageWrapper>
          )}
        </MobileWrapper>
      ) : (
        <>
          <ImageWrapper>
            <Image
              src={'/images/main/section-ten/phone_detail.png'}
              alt='phone_detail'
            />
          </ImageWrapper>
          <TextWrapper>
            <SpoqaBold size={24} color={Colors.primary_ios}>
              매력지수·자문 정보
            </SpoqaBold>
            <Spacer height={16} />
            <SpoqaBold size={48} color={Colors.g12} lineHeight={62.4}>
              투자판단,
              <br /> 매력지수로 똑똑하게
            </SpoqaBold>
          </TextWrapper>
        </>
      )}
    </Container>
  )
}

export default SectionTen
