import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${Colors.maingrey_bg};
  position: relative;
  z-index: 1;
  padding-top: 222px;
  height: 1033px;

  @media (max-width: 1430px) {
    height: auto;
  }
  @media (max-width: 767px) {
    height: auto;
    padding-top: 120px;
  }
`

export const ContentWrapper = styled.div`
  width: 80%;
  z-index: 1;
  bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1850px) {
    width: 100%;
  }
  @media (max-width: 1430px) {
    position: relative;
    height: auto;
    width: 100%;
    flex-direction: column;
    align-items: initial;
  }
`
export const ImageWrapper = styled.div`
  width: 60%;
  object-fit: contain;

  @media (max-width: 1430px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const ImageMobile = styled.img`
  object-fit: contain;
  width: 100%;
  @media (max-width: 1430px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`
export const Image = styled.img`
  width: 100%;
`
export const TextWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`
