import { ReactElement, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import SimulatedHeader from '../SimulatedHeader'
import Footer from './footer'
import SuccessModal from './successModal'
import usePatchSimulatedPassword from '@hooks/usePatchSimulatedPassword'
import { formatPhoneNumber } from '../../utils/formatPhoneNumber'
import { useAppSelector } from '../../store/selectors/utils'
import useGetBottomInset from '@hooks/useGetBottomInset'
// import NoInfoModal from './noInfoModal'

const Index = (): ReactElement => {
  const [pw, setPw] = useState('');
  const [isAble, setIsAble] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { simulatedPasswordData } = usePatchSimulatedPassword();
  const { mutate: patchSimulatedPassword } = simulatedPasswordData;
  const data = useAppSelector(state => state.userReducer);
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const bottomInset = useGetBottomInset();


  const handlePwChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    if (numericValue.length <= 4) {
        setPw(numericValue);
    }
};


useEffect(()=>{
    pw?.length ===4 ? setIsAble(true) : setIsAble(false)
},[pw?.length])


function isSignCheck(){
    const postData = {
        name: data.name,
        mobile: formatPhoneNumber(data.phone),
        newPassword: pw
    }
    patchSimulatedPassword(postData, {
        onSuccess: (responseData) => {
         setShowModal(true);

        },
        onError: (error) => {
          console.error("실패:", error);
        },
      });
}

  return (
    <>
    <SimulatedHeader step={2}/>
    <Container>
      <DescriptionArea>
       <Title>
            비밀번호를 다시 설정해주세요
        </Title>
      </DescriptionArea>
      <InputArea>
      <InputTitle>
            비밀번호 설정(숫자 4자리)
        </InputTitle>
        <Input placeholder='게임현황 확인 시 사용할 비밀번호 입력'
                value={pw}
                inputMode='numeric'
                onChange={handlePwChange}/>
   
      </InputArea>
      <Footer isAble={isAble} isSignCheck={isSignCheck} paddingBottom={!isFromApp ? bottomInset: 24}/>
    <SuccessModal isOpen={showModal} setIsOpen={setShowModal} isFromApp={isFromApp}/>

    </Container>
    </>
  )
}

const Input = styled.input`
    background-color: #F5F5F9;
    border-radius: 4px;
    padding:24px;
    border:none;
    font-family: Pretendard500, sans-serif;
    font-size:18px;
    line-height: 21.6px;
    @media (max-width:800px){
        font-size:16px;
        line-height:19.2px;
    }
`

const InputArea = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    margin-bottom: 51px;
    position: relative; 

`

const InputTitle = styled.div`
    font-family: Pretendard500, sans-serif;
    font-size:20px;
    line-height:24px;
    margin-bottom: 8px;
    color:#999BA9;
    @media (max-width:800px){
        font-size:16px;
        line-height:20px;
    }
`

const Title = styled.div`
    font-size: 32px;
    line-height: 38.4px;
    font-family: Pretendard700, sans-serif;
    @media (max-width:800px){
        font-size: 18px;
        line-height: 22px;
    }
`

const DescriptionArea= styled.div`
    padding: 0 24px;
    margin-top:80px;
    margin-bottom:32px;
`;

const Container= styled.div` 
width: 800px;
margin:auto;
top: 0px;
left: 0px;
display: flex;
flex-direction: column;
@media (max-width:800px){
    width:100%;
    }
`;

export default Index
