import { Colors } from '@styles/colors'
import Spacer from '../../base/Spacer'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '../../base/TextStyle/Spoqa'
import {
  Btn,
  BtnContainer,
  BtnImage,
  Container,
  FlagImage,
  Row,
} from './styles'
import { useMediaQuery } from 'react-responsive'
import { isAndroid, isMobile } from 'react-device-detect'
const SectionBottom = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  const isTabletL = useMediaQuery({ query: '(max-width: 1200px)' })

  const handleClickDown = () => {
    if (isAndroid) {
      console.log('aos')
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
    } else {
      console.log('ios')
      window.location.href =
        'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
    }
  }
  return (
    <Container>
      {isTablet ? (
        <>
          <SpoqaRegular color={Colors.g12} size={18}>
            똑똑한 공모주 투자의 시작,
          </SpoqaRegular>

          <Row>
            <SpoqaBold color={Colors.g12} size={18}>
              일육공과 함께
            </SpoqaBold>
            <SpoqaRegular color={Colors.g12} size={18}>
              해보세요
            </SpoqaRegular>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <SpoqaRegular color={Colors.g12} size={44}>
              똑똑한 공모주 투자의 시작,
            </SpoqaRegular>
            <Spacer width={8} />
            <SpoqaBold color={Colors.g12} size={44}>
              일육공과 함께
            </SpoqaBold>
            <Spacer width={10} />
            <SpoqaRegular color={Colors.g12} size={44}>
              해보세요
            </SpoqaRegular>
          </Row>
          <Spacer height={40} />
        </>
      )}
      <BtnContainer>
        {isMobile ? (
          <Btn onClick={handleClickDown}>
            <SpoqaMedium size={14} color={Colors.g12}>
              앱 다운로드
            </SpoqaMedium>
          </Btn>
        ) : (
          <>
            {' '}
            <Btn
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
                )
              }}
            >
              <BtnImage
                src={'/images/main/section-bottom/google_logo.png'}
                alt='google'
              />

              {isTablet ? (
                <>
                  <Spacer width={12} />
                  <SpoqaBold color={Colors.g12} size={18} lineHeight={20}>
                    Google Play
                  </SpoqaBold>
                </>
              ) : (
                <>
                  <Spacer width={10} />
                  <SpoqaRegular color={Colors.g12} size={24} lineHeight={20}>
                    Google Play
                  </SpoqaRegular>
                </>
              )}
            </Btn>
            {isTablet ? <Spacer width={14} /> : null}
            <Btn
              onClick={() => {
                window.open(
                  'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
                )
              }}
            >
              <BtnImage
                src={'/images/main/section-bottom/apple_logo.png'}
                alt='google'
              />

              {isTablet ? (
                <>
                  <Spacer width={12} />
                  <SpoqaBold color={Colors.g12} size={18} lineHeight={20}>
                    App Store
                  </SpoqaBold>
                </>
              ) : (
                <>
                  <Spacer width={10} />
                  <SpoqaRegular color={Colors.g12} size={24} lineHeight={20}>
                    App Store
                  </SpoqaRegular>
                </>
              )}
            </Btn>
          </>
        )}
      </BtnContainer>
      <FlagImage src={'/images/main/section-bottom/flag.png'} />
    </Container>
  )
}

export default SectionBottom
