import { Colors } from '../../../styles/colors'
import styled from 'styled-components'
import { SpoqaMedium } from '../TextStyle/Spoqa'

const Styled = {
  Container: styled.div`
    position: fixed;
    background-color: ${Colors.primary_ios};
    border-radius: 100px;
    width: 112px;
    height: 38px;
    bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    left: calc(50vw - 56px);
  `,
}

const { Container } = Styled

const MobileFloatBtn = () => {
  return (
    <Container
      onClick={() => {
        window.location.href = 'https://160.page.link/landing'
      }}
    >
      <SpoqaMedium size={18}>앱 다운로드</SpoqaMedium>
    </Container>
  )
}

export default MobileFloatBtn
