import React from "react";
import styled from "styled-components";

const content01 = () => {
  const IconImgs = [
    "/img/main/icon/icon1.svg",
    "/img/main/icon/icon2.svg",
    "/img/main/icon/icon3.svg",
    "/img/main/icon/icon4.svg",
    "/img/main/icon/icon5.svg",
    "/img/main/icon/icon6.svg"
  ];

  return (
    <Container>
      {IconImgs.map((itm) => (
        <IconBox>
          <img src={itm} />
        </IconBox>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: auto;
  gap: 2px;
  img {
    width: 72px;
    height: 72px;
  }
  width: 312px;
  border-radius: 16px;
  overflow: hidden;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
`;

const IconBox = styled.div`
  background-color: #f0f4f9;
  padding: 28px 15px;
  box-sizing: border-box;
`;

export default content01;
