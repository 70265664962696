import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 56px;
  @media (max-width: 1445px) {
    padding: 0;
  }
`
export const ModalImg = styled.img`
  width: 278px;
  height: 314px;
  @media (max-width: 1445px) {
    width: 50%;
    object-fit: contain;
  }
`
export const DownloadTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 75px;
  padding-right: 87px;
`

export const DonwloadTitle = styled.div`
  font-size: 18px;
  color: ${Colors.g9};
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 87px 0 56px;
  align-items: center;
  @media (max-width: 1445px) {
    padding: 0 30px;
  }
`

export const StoreBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 126px;
  justify-content: space-between;
`

export const StoreBtn = styled.div`
  width: 158px;
  height: 48px;
  background-color: #fff7de;
  border-radius: 6.3px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`

export const ContentLogo = styled.img`
  width: 33.47px;
  height: 35.86px;
`

export const QRcontent = styled.img`
  width: 126px;
  height: 126px;
`

export const CloseContainer = styled.div`
  border-top: 1.5px solid ${Colors.ge};
  width: 100%;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  // @media (max-width: 1430px) {
  //   border: 1px solid red;
  //   padding-top: 10px;
  //   height: 50px;
  // }
`
