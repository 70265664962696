import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CalendarSchedule } from '@components/base/Calendar'
import { IpoStockGubun } from '@enums/StockEnum'

export type ScheduleTypes =
  | ScheduleType.EX
  | ScheduleType.LISTING
  | ScheduleType.OFFERING

export enum ScheduleType {
  EX = 'ex', // 수요예측일
  OFFERING = 'offering', // 청약일
  LISTING = 'listing', // 상장일
}
export interface FilterState {
  schedule: Array<ScheduleTypes>
  type: string[]
  hideCompleted: boolean
}

export interface DateState {
  selectedYear: number | null
  selectedMonth: number | null
  selectedDate: string | null
}

export interface BaseState {
  showLoader: boolean
  showSplash: boolean
  date: DateState
  filter: FilterState
  selectedDateSchedule: CalendarSchedule[] | null
  ci: string
}

export const filterScheduleInitialState = [
  ScheduleType.EX,
  ScheduleType.LISTING,
  ScheduleType.OFFERING,
]
export const typeFilterInitialState = Object.keys(IpoStockGubun)
export const hideCompletedFilterInitialState = false

const filterInitialState = {
  schedule: filterScheduleInitialState,
  type: typeFilterInitialState,
  hideCompleted: hideCompletedFilterInitialState,
}

const initialState: BaseState = {
  showLoader: false,
  showSplash: true,
  date: {
    selectedYear: null,
    selectedMonth: null,
    selectedDate: null,
  },
  filter: filterInitialState,
  selectedDateSchedule: null,
  ci: '',
}

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setShowLoader(state, action: PayloadAction<boolean>) {
      state.showLoader = action.payload
    },
    setShowSplash(state, action: PayloadAction<boolean>) {
      state.showSplash = action.payload
    },
    setSelectedYear(state, action: PayloadAction<number>) {
      state.date.selectedYear = action.payload
    },
    setSelectedMonth(state, action: PayloadAction<number>) {
      state.date.selectedMonth = action.payload
    },
    setSelectedDateSchedule(state, action: PayloadAction<CalendarSchedule[]>) {
      state.selectedDateSchedule = action.payload
    },
    setSelectedDate(state, action: PayloadAction<string | null>) {
      state.date.selectedDate = action.payload
    },
    setFilter(state, action: PayloadAction<FilterState>) {
      state.filter = action.payload
    },
    setClearFilter(state) {
      state.filter = filterInitialState
    },
    setCi(state, action: PayloadAction<string>) {
      state.ci = action.payload
    },
  },
})

export const {
  setShowLoader,
  setShowSplash,
  setSelectedYear,
  setSelectedMonth,
  setSelectedDate,
  setFilter,
  setSelectedDateSchedule,
  setClearFilter,
  setCi,
} = baseSlice.actions
export default baseSlice.reducer
