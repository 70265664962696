import { ReactElement } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/colors";
import Spacer from "../base/Spacer";
import { SpoqaRegular } from "../base/TextStyle/Spoqa";

export const Styled = {
  Container: styled.div`
    width: 100%;
    height: 406px;
    background-color: #fafafa;

    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${Colors.gE2};
  `,
  ContentContainer: styled.div`
    width: 1110px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  `,

  MarginTopSpoqaRegular: styled(SpoqaRegular)`
    margin-top: 8px;
  `,
};

const { Container, ContentContainer, MarginTopSpoqaRegular } = Styled;

const PageFour = (): ReactElement => {
  return (
    <Container>
      <ContentContainer>
        <MarginTopSpoqaRegular size={18} color={Colors.g9}>
          ※ 일육공은 매력지수 50점 이상인 경우 청약하는 것을 가정하였습니다.
        </MarginTopSpoqaRegular>
        <Spacer height={4} />
        <MarginTopSpoqaRegular size={18} color={Colors.g9}>
          ※ 투자금액은 2021년 이후 균등배정참여 목적으로 최소 청약증거금으로
          청약하고, 실제 균등배정 경쟁률을 고려해 배정받은 주식수를
          가정하였습니다.
        </MarginTopSpoqaRegular>
        <Spacer height={4} />
        <MarginTopSpoqaRegular size={18} color={Colors.g9}>
          ※ 투자손익은 상장일 종가에 배정받은 주식 전체 매도를 가정하여
          계산하였습니다.{" "}
        </MarginTopSpoqaRegular>
        <Spacer height={4} />
        <MarginTopSpoqaRegular size={18} color={Colors.g9}>
          ※ 투자수익률 = 투자손익/투자금액
        </MarginTopSpoqaRegular>
        <Spacer height={4} />
        <MarginTopSpoqaRegular size={18} color={Colors.g9}>
          ※ 투자수익확률은 전체청약종목수 중에서 상장일 종가가 공모가 이상이었던
          종목수의 비율입니다.
        </MarginTopSpoqaRegular>
        <Spacer height={4} />
        <MarginTopSpoqaRegular size={18} color={Colors.g9}>
          ※ 기재된 투자수익률 및 수익확률은 시뮬레이션 결과로 과거의
          운용실적이며, 과거의 운용실적이 미래의 수익을 보장하는 것은 아닙니다.{" "}
        </MarginTopSpoqaRegular>
        <Spacer height={4} />
        <MarginTopSpoqaRegular size={18} color={Colors.g9}>
          ※ 동 자문에 따라 투자자의 투자목적 또는 성과목표가 달성된다는 보장은
          없습니다.
        </MarginTopSpoqaRegular>
      </ContentContainer>
    </Container>
  );
};

export default PageFour;
