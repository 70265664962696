import { useAnimation, useInView } from 'framer-motion'
import Spacer from '../../base/Spacer'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '../../base/TextStyle/Spoqa'
import {
  ArrowImg,
  Container,
  ImagePhone,
  Row,
  TextWrapper,
  WhiteBackground,
} from './styles'
import { Colors } from '@styles/colors'
import { useMediaQuery } from 'react-responsive'

const SectionSix = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  const isTabletL = useMediaQuery({ query: '(max-width: 1430px)' })
  return (
    <Container>
      {isTabletL ? (
        <>
          <SpoqaBold size={isMobile ? 14 : 24} color={Colors.primary_ios}>
            공모주 투자일임
          </SpoqaBold>
          <Spacer height={16} />
          <Row>
            <SpoqaRegular size={isMobile ? 22 : 48} color={Colors.g12}>
              수많은
            </SpoqaRegular>
            <Spacer width={6} />
            <SpoqaBold size={isMobile ? 22 : 48} color={Colors.g12}>
              청약고민,
            </SpoqaBold>
          </Row>
          <Spacer height={8} />
          <Row>
            <SpoqaBold size={isMobile ? 22 : 48} color={Colors.g12}>
              일육공 투자일임
            </SpoqaBold>
            <Spacer width={6} />
            <SpoqaRegular size={isMobile ? 22 : 48} color={Colors.g12}>
              으로
            </SpoqaRegular>
          </Row>
          <Spacer height={8} />
          <SpoqaRegular size={isMobile ? 22 : 48} color={Colors.g12}>
            단번에 해결하기
          </SpoqaRegular>
          <Spacer height={32} />
          <SpoqaRegular
            size={isMobile ? 13 : 19}
            color={Colors.g12}
            lineHeight={isMobile ? 18.2 : 30}
          >
            일육공은 로보어드바이저 테스트베드 심사를 통과한 <br /> 국내유일
            우량 공모주 선별 AI 알고리즘을 개발하여 <br /> 적은 투자금으로
            쏠쏠한 수익을 만들 수 있는 공모주 <br /> 올인원 서비스를 오픈했어요.
          </SpoqaRegular>
          <Spacer height={18} />
          <SpoqaBold
            size={isMobile ? 13 : 19}
            color={Colors.g12}
            lineHeight={isMobile ? 18.2 : 30}
          >
            청약의 A부터 Z까지 알아서 다 해주는 일육공 투자일임
            <br /> 서비스로 새로운 투자경험을 느껴보세요!
          </SpoqaBold>

          <ImagePhone
            src={'images/main/section-six/phone2_mobile.png'}
            alt='phone2_mobile'
          />
          <WhiteBackground />
        </>
      ) : (
        <>
          <ImagePhone
            src={'images/main/section-six/phone2_web.png'}
            alt='phone2_web'
          />
          <TextWrapper>
            <SpoqaBold size={24} color={Colors.primary_ios}>
              공모주 투자일임
            </SpoqaBold>
            <Spacer height={16} />
            <Row>
              <SpoqaRegular size={48} color={Colors.g12}>
                수많은
              </SpoqaRegular>
              <Spacer width={6} />
              <SpoqaBold size={48} color={Colors.g12}>
                청약고민,
              </SpoqaBold>
            </Row>
            <Spacer height={16} />
            <Row>
              <SpoqaBold size={48} color={Colors.g12}>
                일육공 투자일임
              </SpoqaBold>
              <SpoqaRegular size={48} color={Colors.g12}>
                으로
              </SpoqaRegular>
            </Row>
            <Spacer height={16} />
            <SpoqaRegular size={48} color={Colors.g12}>
              단번에 해결하기
            </SpoqaRegular>
            <Spacer height={55} />
            <SpoqaRegular size={22} color={Colors.g12} lineHeight={30.8}>
              일육공은 로보어드바이저 테스트베드 심사를 통과한 국내유일
              <br />
              우량 공모주 선별 AI 알고리즘을 개발하여 적은 투자금으로 <br />
              쏠쏠한 수익을 만들 수 있는 공모주 올인원 서비스를 오픈했어요.
            </SpoqaRegular>
            <Spacer height={55} />
            <SpoqaBold size={22} color={Colors.g12} lineHeight={30.8}>
              청약의 A부터 Z까지 알아서 다 해주는 일육공 투자일임 서비스로{' '}
              <br />
              새로운 투자경험을 느껴보세요!
            </SpoqaBold>
            <Spacer height={18} />
            {/* 일육공 어플 딥링크 생성되면 살리기 */}
            {/* <Row>
          <SpoqaMedium size={22} color={Colors.primary_ios}>
            일임서비스 더 알아보기
          </SpoqaMedium>
          <ArrowImg src={'/icons/arrow_right_yellow.png'} alt='arrow' />
        </Row> */}
          </TextWrapper>
          <WhiteBackground />
        </>
      )}
    </Container>
  )
}

export default SectionSix
