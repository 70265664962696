import { useMediaQuery } from 'react-responsive'
import Spacer from '../../base/Spacer'
import { SpoqaLight, SpoqaMedium } from '../../base/TextStyle/Spoqa'
import { Container, DownloadBtn, TitleImage } from './styles'
import { isAndroid, isMobile } from 'react-device-detect'

type ModalProps = {
  setIsOpenModal: (state: boolean) => void
}
const SectionOne = ({ setIsOpenModal }: ModalProps) => {
  const isMobileQuery = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })

  const handleClickDown = () => {
    if (isMobile || isMobileQuery) {
      if (isAndroid) {
        console.log('aos')
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
      } else {
        console.log('ios')
        window.location.href =
          'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
      }
    } else {
      setIsOpenModal(true)
    }
  }
  return (
    <Container>
      <Spacer height={isMobileQuery ? 48 : 72} />
      <SpoqaLight size={isMobileQuery ? 16 : isTablet ? 33 : 42}>
        공모주로 돈 벌기, 이렇게 간단해?
      </SpoqaLight>

      {isMobileQuery ? (
        <>
          <Spacer height={6} />
          <TitleImage
            src={'images/main/section-one/title_mobile.png'}
            alt='title_mobile'
          />
        </>
      ) : (
        <TitleImage
          src={'images/main/section-one/title_web.png'}
          alt='title_web'
        />
      )}

      <Spacer height={24} />
      <DownloadBtn onClick={handleClickDown}>
        <SpoqaMedium size={isMobileQuery ? 14 : 22}>앱 다운받기</SpoqaMedium>
      </DownloadBtn>
    </Container>
  )
}

export default SectionOne
