import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSwipeable } from 'react-swipeable'
import styled, { css } from 'styled-components'
import FloatBtn from '../../components/base/FloatBtn'
import {
  ScheduleType,
  setSelectedDate,
  setSelectedMonth,
  setSelectedYear,
} from '../../store/modules/base'
import { baseSelector } from '../../store/selectors/base'
import { Colors } from '@styles/colors'
import { getNow, toDate } from '../../utils/dateHelper'
import CalendarHeader from '../CalendarHeader'
import Calendar, { CalendarSchedule } from '../base/Calendar'
import Profile from '../base/Profile'
import Spacer from '../base/Spacer'
import { SpoqaMedium, SpoqaRegular } from '../base/TextStyle/Spoqa'
import BasicInstallModal from '../base/modal/basic_install_modal'
import CalendarInstallModal from '../base/modal/calendar_install_modal'
import { API_URL, Axios } from '@apis/axios'
import { AxiosResponse } from 'axios'
import { Schedule } from '@apis/schedule'
import { IpoStockGubun } from '@enums/StockEnum'

const Styled = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: start;

    padding-top: 186px;
    padding-bottom: 60px;
    width: 100%;
    min-height: 100vh;
    background-color: ${Colors.background_sub};
  `,
  CalendarContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 1200px;
  `,
  LabelWrapper: styled.div`
    margin-top: 12px;
    padding: 0 20px;
    display: flex;
  `,
  Label: styled.div<{ type: '수요예측일' | '청약일' | '상장일' }>`
    position: relative;
    padding-left: 24px;
    margin-right: 8px;
    font-size: 20px;
    color: ${Colors.g5};
    font-family: 'SpoqaHanSansNeoRegular', serif;

    &:before {
      content: '';
      top: 1px;
      left: 0;
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    ${({ type }) => {
      if (!type) {
        return
      }
      switch (type) {
        case '수요예측일':
          return css`
            &:before {
              background-color: ${Colors.predict};
            }
          `
        case '청약일':
          return css`
            &:before {
              background-color: ${Colors.d_day};
            }
          `
        case '상장일':
          return css`
            &:before {
              background-color: ${Colors.open_day};
            }
          `
      }
    }};
  `,
  BottomArea: styled.div<{ minHeight: number }>`
    background: ${Colors.gFa};
    min-height: ${({ minHeight }) => minHeight}px;
  `,
  Content: styled.div`
    padding: 43px 40px;
  `,
  Date: styled.div`
    font-size: 20px;
    color: ${Colors.g12};
    margin-bottom: 27px;
    font-family: 'SpoqaHanSansNeoRegular', serif;
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    box-shadow: 0 5px 20px rgba(109, 109, 109, 0.07);
    border-radius: 10px;
    padding: 26px;
    cursor: pointer;
    background-color: ${Colors.gf};
    // hover background-color change add animation
    transition: background-color 0.5s ease-in-out;
    &:hover {
      background-color: ${Colors.gFa};
    }
  `,
  Header: styled.div`
    display: flex;
  `,
  Left: styled.div`
    margin-right: 18px;
  `,
  Right: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Name: styled.div`
    font-size: 20px;

    margin-bottom: 5px;
    font-family: 'SpoqaHanSansNeoBold', sans-serif;
  `,
  Desc: styled.div`
    color: ${Colors.g9};
    font-family: 'SpoqaHanSansNeoRegular', sans-serif;
    font-size: 16px;
  `,
  Bottom: styled.div`
    display: flex;
    align-items: center;

    margin-top: 37.5px;
    padding-left: 68px;
  `,
  DayItem: styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    /* font-family: ${({ active }) =>
      active ? 'SpoqaHanSansNeoMedium' : 'SpoqaHanSansNeoRegular'},
      sans-serif; */
    /* color: ${({ active }) => (active ? Colors.g5 : Colors.g9)}; */

    font-family: 'SpoqaHanSansNeoMedium', sans-serif;
    color: ${Colors.g5};
    font-size: 18px;
  `,
  DayItemTitle: styled.div``,
  DayItemDivider: styled.div`
    width: 1px;
    height: 12px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: ${Colors.gc};
  `,
  Day: styled.div<{ border?: boolean }>`
    margin-top: 6px;
    ${({ border }) =>
      border === false &&
      css`
        border: none;
      `}
  `,
  EmptyBox: styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 22px;
    box-shadow: 0 4px 16px rgba(109, 109, 109, 0.07);
    border-radius: 8px;
    color: ${Colors.ga};
    font-size: 14px;
    font-weight: 700;
    background-color: white;
  `,

  HeadFrame: styled.div`
    background-color: ${Colors.gf};
    height: 100px;
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: -14px 14px 70px rgba(0, 0, 0, 0.08);
  `,
  CalendarFrame: styled.div`
    width: 676px;
    background-color: ${Colors.gf};
    border-radius: 20px;
    box-shadow: -14px 14px 70px rgba(0, 0, 0, 0.08);
    padding: 40px;
  `,
  CardFrame: styled.div`
    width: 524px;
    min-height: 732px;
    background-color: ${Colors.maingrey_bg};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
  `,
  BodyFrame: styled.div`
    display: flex;
  `,
  ListViewFrame: styled.div`
    width: 100%;
    min-height: 500px;
    background-color: ${Colors.maingrey_bg};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  `,
  TabFrame: styled.div`
    display: flex;
    justify-content: start;
    margin-bottom: 16px;
    padding-left: 40px;
    padding-top: 40px;
  `,
  TabButton: styled.div<{ active?: boolean }>`
    padding: 0 20px;
    height: 55.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 50px;
    cursor: pointer;

    color: ${({ active }) => (active ? Colors.g5 : Colors.g9)};
    background-color: ${({ active }) => (active ? Colors.gf : null)};
    box-shadow: ${({ active }) =>
      active ? '0px 6.09447px 24.3779px rgba(109, 109, 109, 0.07)' : null};
    font-family: ${({ active }) =>
      active ? 'SpoqaHanSansNeoBold' : 'SpoqaHanSansNeoRegular'};
  `,

  ListItem: styled.div<{ hide?: boolean }>`
    display: flex;
    min-height: 108px;
    border-bottom: 2px dashed #e2e2e2;
    padding-top: 24px;
    padding-bottom: 14px;

    align-items: flex-start;

    position: relative;

    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
  `,

  ListItemContainer: styled.div<{ isToday?: boolean }>`
    padding-left: 40px;
    padding-right: 40px;

    background-color: ${({ isToday }) =>
      isToday ? Colors.gf : Colors.maingrey_bg};
  `,

  ListItemDay: styled.div<{ isToday?: boolean }>`
    background-color: ${Colors.gf};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SpoqaHanSansNeoMedium', sans-serif;
    font-size: 20px;
    width: 128px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 5.02752px 20.1101px rgba(109, 109, 109, 0.07);
    color: ${({ isToday }) => (isToday ? Colors.secondary : Colors.g9)};
  `,

  ListItemCard: styled.div`
    width: 300px;
    height: 60px;
    border-radius: 10px;
    margin-left: 10px;
    background-color: ${Colors.gf};
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 20px rgba(109, 109, 109, 0.1);
  `,
  ListItemLeft: styled.div``,
  ListItemRight: styled.div``,
  ListItemName: styled.div`
    font-size: 14px;
    margin-bottom: 4px;
    font-family: 'SpoqaHanSansNeoMedium', sans-serif;
  `,
  ListItemDesc: styled.div`
    font-size: 12px;
    color: ${Colors.g9};
    font-family: 'SpoqaHanSansNeoRegular', sans-serif;
    display: flex;
    align-items: center;
  `,

  ListItemCardFrame: styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 1000px;
    cursor: pointer;
  `,

  TodayBubble: styled.img`
    position: absolute;
    width: 88px;
    height: 40px;
    left: -20px;
    top: 10px;
  `,

  DownImage: styled.img`
    width: 160px;
    height: 140px;
    margin-bottom: 32px;
  `,

  DownModalContainer: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 56px;
  `,

  DownSign: styled.img`
    width: 48px;
    height: 40px;
    margin-top: 21px;
  `,

  YellowBar: styled.div`
    width: 442px;
    height: 54px;
    border-radius: 10px;
    background-color: ${Colors.primary_ios};
    margin-top: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,

  StoreBtnContainer: styled.div`
    width: 442px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
  `,

  StoreBtn: styled.div`
    width: 216px;
    height: 60px;
    background-color: ${Colors.gE2};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  `,

  StoreBtnLogo: styled.img`
    width: 42px;
    height: 45px;
  `,

  CloseText: styled.div`
    margin-top: 24px;
    border-bottom: solid 1px ${Colors.g9};
    cursor: pointer;
  `,

  ListItemType: styled.div<{ type?: string }>`
    padding: 4px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ type }) =>
      type === 'ex'
        ? Colors.predict
        : type === 'offering'
        ? Colors.primary_ios
        : Colors.open_day};
    border-radius: 100px;
  `,
}

const {
  Container,
  CalendarContainer,
  LabelWrapper,
  Label,
  Content,
  Date,
  Card,
  Header,
  Left,
  Right,
  Desc,
  Name,
  Bottom,
  DayItem,
  DayItemDivider,
  DayItemTitle,
  Day,
  EmptyBox,
  HeadFrame,
  CalendarFrame,
  CardFrame,
  BodyFrame,
  ListViewFrame,
  TabFrame,
  TabButton,
  ListItem,
  ListItemDay,
  ListItemCard,
  ListItemLeft,
  ListItemRight,
  ListItemName,
  ListItemDesc,
  ListItemCardFrame,
  TodayBubble,
  ListItemContainer,
  ListItemType,
} = Styled

const SWIPER_CONFIG = {
  delta: 10, // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
  trackTouch: true, // track touch input
  trackMouse: false, // track mouse input
  rotationAngle: 0, // set a rotation angle
  swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
  touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
}

interface HighlightDayIndexItem {
  exEndDate: string
  listingDate: string
  offeringStartDate: string | null
}

const PageOne = () => {
  const {
    selectedYear,
    selectedMonth,
    selectedFilter,
    selectedDate,
    selectedDateSchedule,
  } = useSelector(baseSelector)
  const dispatch = useDispatch()

  //! calendar,list view change
  const [isShowCalendarView, setIsShowCalendarView] = useState(true)
  const [openDownModal, setOpenDownModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const getSchedule = async (
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<Schedule>> => {
    return Axios.get(API_URL.SCHEDULE, {
      params: { startDate, endDate },
    })
  }

  const [schedules, setSchedules] = useState<CalendarSchedule[] | undefined>(
    undefined
  )

  const sortSchedules = useCallback(
    (schedules: Schedule) => {
      const newSchedules = schedules.data.ipoStocks
        ?.filter((item) => selectedFilter.type.includes(item.gubun))
        ?.filter((item) =>
          item.listingDate && selectedFilter.hideCompleted
            ? getNow().isSameOrBefore(toDate(item.listingDate))
            : true
        )
        ?.map((item) => {
          const baseItem = {
            title: item.name,
            data: { ...item },
          }
          // 상장일
          const listing = {
            ...baseItem,
            type: ScheduleType.LISTING,
            id: `${item.id}-${ScheduleType.LISTING}`,
            start: item.listingDate,
            end: item.listingDate,
            color: Colors.calender03_ios,
            priority: 1,
          }

          // 청약일
          const offering = {
            ...baseItem,
            type: ScheduleType.OFFERING,
            id: `${item.id}-${ScheduleType.OFFERING}`,
            start: item.offeringStartDate,
            end: item.offeringEndDate,
            color: Colors.calender02_ios,
            priority: 0,
          }

          // 수요예측일
          const ex = {
            ...baseItem,
            type: ScheduleType.EX,
            id: `${item.id}-${ScheduleType.EX}`,
            start: item.exEndDate,
            end: item.exEndDate,
            color: Colors.calender01_ios,
            priority: 2,
          }

          return [offering, listing, ex]
        })
        .flat()
        .filter((item) =>
          selectedFilter.schedule.includes(
            (item as unknown as CalendarSchedule).type
          )
        )
      setSchedules(newSchedules ?? [])
    },
    [selectedFilter]
  )

  const [onceFetched, setOnceFetched] = useState(false)
  const changeDate = useCallback(
    async (date: string): Promise<void> => {
      const response = await getSchedule(
        date,
        toDate(date).endOf('month').add(1, 'day').format('YYYY-MM-DD')
      )

      if (!onceFetched) {
        setOnceFetched(true)
      }

      sortSchedules(response.data)
    },
    [onceFetched, sortSchedules]
  )

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (selectedYear === null || selectedMonth === null) {
        return
      }
      if (eventData.dir === 'Right') {
        if (selectedMonth === 0) {
          const target = dayjs()
            .set('year', selectedYear - 1)
            .set('month', 11)
            .set('date', 1)
            .format('YYYY-MM-DD')
          changeDate(target).then(() => {
            dispatch(setSelectedMonth(11))
            dispatch(setSelectedYear(selectedYear - 1))
            dispatch(setSelectedDate(target))
          })
        } else {
          const target = dayjs()
            .set('year', selectedYear)
            .set('month', selectedMonth - 1)
            .set('date', 1)
            .format('YYYY-MM-DD')
          changeDate(target).then(() => {
            dispatch(setSelectedMonth(selectedMonth - 1))
            dispatch(setSelectedDate(target))
          })
        }
      } else if (eventData.dir === 'Left') {
        if (selectedMonth === 11) {
          const target = dayjs()
            .set('year', selectedYear + 1)
            .set('month', 0)
            .set('date', 1)
            .format('YYYY-MM-DD')
          changeDate(target).then(() => {
            dispatch(setSelectedMonth(0))
            dispatch(setSelectedYear(selectedYear + 1))
            dispatch(setSelectedDate(target))
          })
        } else {
          const target = dayjs()
            .set('year', selectedYear)
            .set('month', selectedMonth + 1)
            .set('date', 1)
            .format('YYYY-MM-DD')
          changeDate(target).then(() => {
            dispatch(setSelectedMonth(selectedMonth + 1))
            dispatch(setSelectedDate(target))
          })
        }
      }
    },
    ...SWIPER_CONFIG,
  })

  const getHighlightDayIndex = useCallback((item: HighlightDayIndexItem) => {
    const now = getNow()
    if (now.isSameOrAfter(item.listingDate, 'day')) {
      return 2
    }
    if (now.isAfter(item.exEndDate, 'day')) {
      return 1
    }
    if (now.isSameOrBefore(item.exEndDate, 'day')) {
      return 0
    }
    return undefined
  }, [])

  useEffect(() => {
    dispatch(setSelectedYear(selectedYear ?? getNow().year()))
    dispatch(setSelectedMonth(selectedMonth ?? getNow().month()))
  }, [dispatch, selectedMonth, selectedYear])

  useEffect(() => {
    if (onceFetched && !selectedDate) {
      dispatch(setSelectedDate(getNow().format('YYYY-MM-DD')))
    }
  }, [dispatch, onceFetched, selectedDate])

  useEffect(() => {
    if (selectedYear === null || selectedMonth === null) {
      return
    }
    changeDate(
      dayjs()
        .set('year', selectedYear)
        .set('month', selectedMonth)
        .set('date', 1)
        .format('YYYY-MM-DD')
    )
  }, [selectedYear, selectedMonth, changeDate])

  //! listView

  const [selectedTap, setSelectedTap] = useState(0)

  const filteredExSchedules = schedules?.filter((item) => item.type === 'ex')

  const filteredOfferingSchedules = schedules?.filter(
    (item) => item.type === 'offering'
  )

  const filteredlistingSchedules = schedules?.filter(
    (item) => item.type === 'listing'
  )

  const maxMonthsArr = Array(31)
    .fill(0)
    .map((_, i) => i + 1)

  const getSchedulesByDate = (
    date: string,
    schedules: CalendarSchedule[]
  ): CalendarSchedule[] => {
    return schedules?.reduce<CalendarSchedule[]>((acc: any, cur: any) => {
      if (
        toDate(date).isSameOrAfter(toDate(cur.start), 'date') &&
        toDate(date).isSameOrBefore(toDate(cur.end), 'date')
      ) {
        acc.push(cur)
      }
      return acc
    }, [])
  }

  function renderListItem(
    day: any,
    year: any,
    month: any,
    selectedTap: number
  ) {
    const newDate = dayjs().set('year', year).set('month', month)
    const date = newDate.set('month', month).set('date', day)
    const isToday = getNow().format('YYYY-MM-DD') === date.format('YYYY-MM-DD')

    const listDate = date.format('YYYY-MM-DD')

    const dayString =
      date.day() === 1
        ? '월'
        : date.day() === 2
        ? '화'
        : date.day() === 3
        ? '수'
        : date.day() === 4
        ? '목'
        : date.day() === 5
        ? '금'
        : date.day() === 6
        ? '토'
        : '일'

    return (
      <ListItemContainer
        key={day + year + month + selectedTap}
        isToday={isToday}
      >
        <ListItem
          hide={date.day() === 0 || date.day() === 6}
          key={`${month}-${day}`}
        >
          <ListItemDay isToday={isToday}>
            {month + 1}.{day.toString().length === 1 ? `0${day}` : day}(
            {dayString})
          </ListItemDay>
          <ListItemCardFrame>
            {renderListCard(listDate, selectedTap)}
          </ListItemCardFrame>
          {isToday ? <TodayBubble src='images/today.png' /> : null}
        </ListItem>
      </ListItemContainer>
    )
  }

  function renderListCard(listDate: any, selectedTap: number) {
    const currentTap =
      selectedTap === 0
        ? schedules
        : selectedTap === 1
        ? filteredExSchedules
        : selectedTap === 2
        ? filteredOfferingSchedules
        : filteredlistingSchedules

    const result = getSchedulesByDate(
      listDate,
      currentTap as CalendarSchedule[]
    )

    //! result sort
    //result sort offering -> listing -> ex 순서대로
    result?.sort((a: any, b: any) => {
      if (a.type === 'offering' && b.type === 'listing') {
        return -1
      } else if (a.type === 'listing' && b.type === 'ex') {
        return -1
      } else if (a.type === 'offering' && b.type === 'ex') {
        return -1
      } else {
        return 1
      }
    })

    return (
      <>
        {result
          ?.filter((item) => item?.data?.id)
          ?.map((item, itemIndex) => (
            <ListItemCard
              key={`${item.data.code}-${itemIndex}`}
              onClick={() => setOpenDownModal(true)}
            >
              <ListItemLeft>
                <Profile size={40} src={item.data.imgUrl} />
              </ListItemLeft>
              <Spacer width={10} />

              <ListItemRight>
                <ListItemName>{item.title}</ListItemName>

                <ListItemDesc>
                  {selectedTap === 0 ? (
                    <>
                      <ListItemType type={item.type}>
                        <SpoqaMedium size={10} color={Colors.gf}>
                          {item.type === 'ex'
                            ? '수요예측'
                            : item.type === 'offering'
                            ? '청약일'
                            : '상장일'}
                        </SpoqaMedium>
                      </ListItemType>
                      <Spacer width={4} />
                    </>
                  ) : null}
                  {IpoStockGubun[item.data.gubun]} | 공모가{' '}
                  {item.data.offeringPrice
                    ? item.data.offeringPrice.toLocaleString()
                    : `${item.data.offeringDownPrice.toLocaleString()} - ${item.data.offeringUpPrice.toLocaleString()}` ??
                      '-'}
                  원
                </ListItemDesc>
              </ListItemRight>
            </ListItemCard>
          ))}
      </>
    )
  }

  return (
    <Container>
      <CalendarContainer>
        <HeadFrame>
          <CalendarHeader
            setDate={changeDate}
            setIsShowCalendarView={setIsShowCalendarView}
            isShowCalendarView={isShowCalendarView}
          />
        </HeadFrame>
        <Spacer height={20} />
        {isShowCalendarView ? (
          <BodyFrame>
            <CalendarFrame>
              <LabelWrapper>
                <Label type={'수요예측일'}>수요예측일</Label>
                <Label type={'청약일'}>청약일</Label>
                <Label type={'상장일'}>상장일</Label>
              </LabelWrapper>
              <div {...handlers}>
                <Calendar
                  schedules={schedules ?? []}
                  onClickDay={(date: string) => dispatch(setSelectedDate(date))}
                  year={selectedYear ?? getNow().year()}
                  month={selectedMonth ?? getNow().month()}
                  selectedDate={selectedDate}
                />
              </div>
            </CalendarFrame>
            <Spacer width={20} />
            <CardFrame>
              <Content>
                <Date>
                  {selectedDate && toDate(selectedDate).format('MM월 DD일')}
                </Date>
                {selectedDateSchedule?.filter((item) => item?.data?.id)
                  ?.length ? (
                  selectedDateSchedule
                    ?.filter((item) => item?.data?.id)
                    ?.map((item, itemIndex) => (
                      <Card
                        key={`${item.data.code}-${itemIndex}`}
                        onClick={() => setOpenDownModal(true)}
                      >
                        <Header>
                          <Left>
                            <Profile size={48} src={item.data.imgUrl} />
                          </Left>
                          <Right>
                            <Name>{item.title}</Name>
                            <Desc>
                              {IpoStockGubun[item.data.gubun]} | 공모가{' '}
                              {item.data.offeringPrice
                                ? item.data.offeringPrice.toLocaleString()
                                : `${item.data.offeringDownPrice.toLocaleString()} - ${item.data.offeringUpPrice.toLocaleString()}` ??
                                  '-'}
                              원
                            </Desc>
                          </Right>
                        </Header>
                        <Bottom>
                          <DayItem
                            active={
                              getHighlightDayIndex(
                                item.data as unknown as HighlightDayIndexItem
                              ) === 0
                            }
                          >
                            <DayItemTitle>
                              <SpoqaRegular size={18}>수요예측일</SpoqaRegular>
                            </DayItemTitle>
                            <Day>
                              <SpoqaRegular size={18}>
                                {toDate(item.data.exEndDate).format('MM.DD')}
                              </SpoqaRegular>
                            </Day>
                          </DayItem>
                          <DayItemDivider />
                          <DayItem
                            active={
                              getHighlightDayIndex(
                                item.data as unknown as HighlightDayIndexItem
                              ) === 1
                            }
                          >
                            <DayItemTitle>
                              <SpoqaRegular size={18}>청약일</SpoqaRegular>
                            </DayItemTitle>
                            <Day>
                              <SpoqaRegular size={18}>
                                {toDate(item.data.offeringStartDate).format(
                                  'MM.DD'
                                )}{' '}
                                -{' '}
                                {toDate(item.data.offeringEndDate).format(
                                  'MM.DD'
                                )}
                              </SpoqaRegular>
                            </Day>
                          </DayItem>
                          <DayItemDivider />
                          <DayItem
                            active={
                              getHighlightDayIndex(
                                item.data as unknown as HighlightDayIndexItem
                              ) === 2
                            }
                          >
                            <DayItemTitle>
                              <SpoqaRegular size={18}>상장일</SpoqaRegular>
                            </DayItemTitle>

                            <Day border={false}>
                              <SpoqaRegular size={18}>
                                {item.data.listingDate
                                  ? toDate(item.data.listingDate).format(
                                      'MM.DD'
                                    )
                                  : '미정'}
                              </SpoqaRegular>
                            </Day>
                          </DayItem>
                        </Bottom>
                      </Card>
                    ))
                ) : (
                  <EmptyBox>IPO 일정이 없어요</EmptyBox>
                )}
              </Content>
            </CardFrame>
          </BodyFrame>
        ) : (
          <ListViewFrame>
            <TabFrame>
              <TabButton
                active={selectedTap === 0}
                onClick={() => {
                  setSelectedTap(0)
                }}
              >
                전체
                {/* {entireCount} */}
              </TabButton>
              <TabButton
                active={selectedTap === 1}
                onClick={() => {
                  setSelectedTap(1)
                }}
              >
                수요예측일
                {/* {preOfferingCount} */}
              </TabButton>
              <TabButton
                active={selectedTap === 2}
                onClick={() => {
                  setSelectedTap(2)
                }}
              >
                청약일
                {/* {offeringCount} */}
              </TabButton>
              <TabButton
                active={selectedTap === 3}
                onClick={() => {
                  setSelectedTap(3)
                }}
              >
                상장일
                {/* {preListingCount} */}
              </TabButton>
            </TabFrame>
            {maxMonthsArr.map((day) =>
              renderListItem(
                day,
                selectedYear ?? getNow().year(),
                selectedMonth ?? getNow().month(),
                selectedTap
              )
            )}
          </ListViewFrame>
        )}
      </CalendarContainer>
      <CalendarInstallModal
        isOpen={openDownModal}
        setIsOpen={setOpenDownModal}
      />
      {openDownModal ? null : (
        <FloatBtn isOpen={isOpen} setIsOpen={setIsOpen} isShowDownBtn={true} />
      )}
      <BasicInstallModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Container>
  )
}

export default PageOne
