import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { getNow, toDate } from '../../utils/dateHelper'
import dayjs from 'dayjs'
import { shorteningTitle } from '../../utils'
import {
  ScheduleTypes,
  setSelectedDateSchedule,
} from '../../store/modules/base'
import { useDispatch } from 'react-redux'
import { ScheduleDataIpoStock } from '@apis/schedule'
import { MobileCalendarStyled } from '@styles/mobile-calendar'

export const DAY_OF_WEEK_COUNT = 7 // 한 주에는 7개의 요일
export const WEEK_OF_MONTH_COUNT = 6 // 한 달에는 최대 6개의 주
export const DAY_OF_WEEKDAY_COUNT = 5 // 평일 갯수

export interface CalendarSchedule {
  title: string
  color: string
  start: string
  priority: number
  end: string
  data: ScheduleDataIpoStock
  type: ScheduleTypes
}

interface CalendarProps {
  schedules: CalendarSchedule[]
  year: number
  month: number
  onClickDay?: (e: string) => void
  selectedDate: string | null
}

type LineMap = { [key: string]: number }

function MobileCalendar({
  year,
  month,
  schedules,
  onClickDay,
  selectedDate,
}: CalendarProps) {
  const ref = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const [blockWidth, setBlockWidth] = useState(0)

  useLayoutEffect(() => {
    const current = ref?.current
    if (current) {
      setBlockWidth(current.offsetWidth / DAY_OF_WEEKDAY_COUNT)
    }
  }, [ref])

  const newDate = dayjs().set('year', year).set('month', month)

  const getKey = (schedule: CalendarSchedule): string =>
    `${schedule.title}/${schedule.start}/${schedule.end}`

  const sortedByDate = schedules
    .filter((item) => item.start && item.end)
    .sort((a, b) => {
      const diffInDays = toDate(a.start).diff(toDate(b.start), 'day')
      if (diffInDays === 0) {
        return a.priority - b.priority
      }

      return diffInDays
    })

  // 일별 스케쥴 라인 정보
  let lineMap: LineMap = {}
  // TODO : useMemo로 변경
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newSchedules: any[] = []

  for (let i = 0; i < sortedByDate.length; i++) {
    const schedule = sortedByDate[i]
    const scheduleKey = getKey(schedule)

    // 중복 아이템 갯수
    let newLine = 0

    // 시작 날짜와 겹치는 아이템 중에 마지막 라인 정보를 가져옴
    for (let k = i - 1; k >= 0; k--) {
      const checkSchedule = sortedByDate[k]
      const checkScheduleLine = lineMap[getKey(checkSchedule)]
      if (
        toDate(schedule.start).isBetween(
          checkSchedule.start,
          checkSchedule.end,
          'day',
          '[]'
        ) ||
        toDate(schedule.end).isBetween(
          checkSchedule.start,
          checkSchedule.end,
          'day',
          '[]'
        )
      ) {
        newLine = Math.max(newLine, checkScheduleLine + 1)
      }
    }

    //  비어있는 위치 찾기
    const checkedScheduleLines = sortedByDate
      .slice(0, i)
      .filter((item) => {
        return (
          toDate(schedule.start).isBetween(item.start, item.end, 'day', '[]') ||
          toDate(schedule.end).isBetween(item.start, item.end, 'day', '[]')
        )
      })
      .map((item) => lineMap[getKey(item)])
    checkedScheduleLines.sort()
    if (checkedScheduleLines.length > 0) {
      for (
        let k = 0;
        k < checkedScheduleLines[checkedScheduleLines.length - 1];
        k++
      ) {
        if (k !== checkedScheduleLines[k]) {
          newLine = k
          break
        }
      }
    }

    if (
      sortedByDate.slice(0, i).every((a) => {
        const pastLine = lineMap[getKey(a)]
        const isBetweenStart = toDate(schedule.start).isBetween(
          a.start,
          a.end,
          'day',
          '[]'
        )
        const isBetweenEnd = toDate(schedule.end).isBetween(
          a.start,
          a.end,
          'day',
          '[]'
        )
        const hasSameDay = isBetweenStart || isBetweenEnd
        return !hasSameDay || pastLine > 0
      })
    ) {
      newLine = 0
    }

    // 스케쥴 라인 정보 설정 및 새로운 스케쥴 정보 저장
    lineMap[scheduleKey] = newLine
    newSchedules[i] = {
      ...sortedByDate[i],
      idx: newLine,
    }
  }

  const startDayOfMonth = useMemo(
    () => newDate.startOf('month').day(),
    [newDate]
  )
  const endDayOfMonth = useMemo(() => newDate.daysInMonth(), [newDate])

  const getSchedulesByDate = (date: string, schedules: any[]) => {
    const result = schedules.reduce((acc: any, cur: any) => {
      if (
        toDate(date).isSameOrAfter(toDate(cur.start), 'date') &&
        toDate(date).isSameOrBefore(toDate(cur.end), 'date')
      ) {
        acc.push(cur)
      }
      return acc
    }, [])

    const arr = []
    const sort = result

    for (let i = 0; i <= Math.max(...sort.map((o: any) => o.idx), 0); i++) {
      if (sort.some((item: any) => item.idx === i)) {
        arr[i] = sort.find((item: any) => item.idx === i)
      } else {
        arr[i] = {
          color: 'transparent',
          title: '-',
        }
      }
    }
    return arr
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderSchedules = (date: string) => {
    const result = getSchedulesByDate(date, newSchedules)
    return result.map((item, itemIndex) => {
      const find = newSchedules.find(
        (schedule: any) =>
          schedule.start === item.start && schedule.title === item.title
      )

      // 겹치는 일정
      const isSameStartDay =
        find?.start && toDate(date).isSame(toDate(find.start), 'day')
      const isSameMonth =
        find?.start &&
        toDate(date).get('month') === toDate(find.start).get('month') &&
        toDate(date).get('month') === toDate(find.end).get('month') &&
        toDate(date).get('year') === toDate(find.start).get('year')
      const sequenceScheduleCount = () => {
        if (!find || !find?.end || !find?.start) {
          return 0
        }
        if (!isSameStartDay || !isSameMonth) {
          return 0
        }
        return toDate(find.end).diff(toDate(find.start), 'day')
      }

      if (sequenceScheduleCount() > 0) {
        if (toDate(date).get('day') === 5) {
          if (!toDate(date).add(3, 'day').isSame(find.start, 'week')) {
            return (
              <MobileCalendarStyled.Schedule
                color={item.color}
                key={itemIndex}
                margin={isSameStartDay}
              >
                <MobileCalendarStyled.Title width={blockWidth}>
                  {shorteningTitle(item.title, 0)}
                </MobileCalendarStyled.Title>
              </MobileCalendarStyled.Schedule>
            )
          }
        } else {
          if (!toDate(date).add(1, 'day').isSame(find.start, 'week')) {
            return (
              <MobileCalendarStyled.Schedule
                color={item.color}
                key={itemIndex}
                margin={isSameStartDay}
              >
                <MobileCalendarStyled.Title width={blockWidth} block={1}>
                  {shorteningTitle(item.title, 0)}
                </MobileCalendarStyled.Title>
              </MobileCalendarStyled.Schedule>
            )
          }
        }

        if (isSameMonth) {
          return (
            <MobileCalendarStyled.Schedule
              color={item.color}
              key={itemIndex}
              margin={isSameStartDay}
            >
              <MobileCalendarStyled.Title
                width={blockWidth}
                block={sequenceScheduleCount()}
              >
                {shorteningTitle(item.title, sequenceScheduleCount())}
              </MobileCalendarStyled.Title>
            </MobileCalendarStyled.Schedule>
          )
        }

        return (
          <MobileCalendarStyled.Schedule
            color={item.color}
            key={itemIndex}
            margin={isSameStartDay}
          />
        )
      }

      if (!isSameMonth) {
        return (
          <MobileCalendarStyled.Schedule
            color={item.color}
            key={itemIndex}
            margin={isSameStartDay}
          >
            {shorteningTitle(item.title, 0)}
          </MobileCalendarStyled.Schedule>
        )
      }

      if (
        !toDate(date).isSame(find.start, 'week') ||
        !toDate(date).isSame(find.end, 'week')
      ) {
        return (
          <MobileCalendarStyled.Schedule color={item.color} key={itemIndex}>
            {shorteningTitle(item.title, 0)}
          </MobileCalendarStyled.Schedule>
        )
      }

      if (!isSameStartDay) {
        return (
          <MobileCalendarStyled.Schedule color={item.color} key={itemIndex} />
        )
      }

      return (
        <MobileCalendarStyled.Schedule
          color={item.color}
          key={itemIndex}
          margin={isSameStartDay}
        >
          <MobileCalendarStyled.Title width={blockWidth}>
            {shorteningTitle(item.title, 0)}
          </MobileCalendarStyled.Title>
        </MobileCalendarStyled.Schedule>
      )
    })
  }
  const renderRow = useCallback(
    function renderRow(row: any) {
      return [...Array(DAY_OF_WEEK_COUNT)].map((v, col) => {
        const day = row * DAY_OF_WEEK_COUNT + col - startDayOfMonth + 1
        const date = newDate.set('month', month).set('date', day)
        const isValidDay = day > 0 && day <= endDayOfMonth

        return isValidDay ? (
          <MobileCalendarStyled.Day
            hide={date.day() === 0 || date.day() === 6}
            onClick={() => {
              onClickDay?.(date.format('YYYY-MM-DD'))
            }}
            key={`${col}-${day}`}
          >
            <MobileCalendarStyled.DayItem>
              <MobileCalendarStyled.Item
                today={date.isSame(getNow(), 'date')}
                isSelected={
                  selectedDate
                    ? date.isSame(toDate(selectedDate), 'date')
                    : false
                }
              >
                {day}
              </MobileCalendarStyled.Item>
            </MobileCalendarStyled.DayItem>
            <MobileCalendarStyled.ScheduleWrapper>
              {renderSchedules(date.format('YYYY-MM-DD'))}
            </MobileCalendarStyled.ScheduleWrapper>
          </MobileCalendarStyled.Day>
        ) : (
          <MobileCalendarStyled.EmptyDay
            hide={date.day() === 0 || date.day() === 6}
            key={day}
          />
        )
      })
    },
    [
      startDayOfMonth,
      newDate,
      month,
      endDayOfMonth,
      selectedDate,
      renderSchedules,
      onClickDay,
    ]
  )

  useEffect(() => {
    if (selectedDate) {
      const result = getSchedulesByDate(selectedDate, newSchedules)
      dispatch(setSelectedDateSchedule(result))
    }
  }, [selectedDate, schedules, newSchedules, dispatch])

  return (
    <MobileCalendarStyled.Wrap ref={ref}>
      <MobileCalendarStyled.YearAndMonth>
        {[...Array(DAY_OF_WEEK_COUNT)].map((_, index) => (
          <MobileCalendarStyled.DayHeader
            key={index}
            hide={
              index === 0 || index === [...Array(DAY_OF_WEEK_COUNT)].length - 1
            }
          >
            {dayjs().set('day', index).format('dd')}
          </MobileCalendarStyled.DayHeader>
        ))}
      </MobileCalendarStyled.YearAndMonth>
      {[...Array(WEEK_OF_MONTH_COUNT)].map((v, row) => (
        <MobileCalendarStyled.DaysRow key={row}>
          {renderRow(row)}
        </MobileCalendarStyled.DaysRow>
      ))}
    </MobileCalendarStyled.Wrap>
  )
}

export default MobileCalendar
