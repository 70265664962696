import { ReactElement } from 'react'
import styled from 'styled-components'

interface IconProps {
  type: IconType
  className?: string
  size?: number
  rotate?: number
  onClick?: () => void
}

interface IconStyleProps {
  type: IconType
  size: number
  rotate: number
}

export enum IconType {
  FILTER = 'filter',
  SEARCH = 'search',
  BACK_WHITE = 'back_white',
  BACK = 'back',
  ARROW_BOTTOM = 'arrow_bottom',
  INPUT_CANCEL = 'input_cancel',
  ARROW_RIGHT_WHITE = 'arrow_right_white',
  ARROW_RIGHT_GRAY = 'arrow_right_gray',
  SHARE = 'share',
  RESET = 'reset',
  CHECK = 'check',
  CHECKED = 'checked',
  CHECK_CIRCLE = 'check_circle',
  CHECKED_CIRCLE = 'checked_circle',
  SHOW_CALENDAR_VIEW = 'show_calendar_view',
  SHOW_LIST_VIEW = 'show_list_view',
}

const iconUrlResolver = (name: string): string => `/icons/${name}`

const Styled = {
  Icon: styled.i<IconStyleProps>`
    display: inline-block;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    background-image: url(${({ type }) => iconUrlResolver(`${type}.png`)}), none; // SVG
    background-image: url(${({ type }) =>
      iconUrlResolver(`${type}.svg`)}); // Compatibility
    background-size: ${({ size }) => size}px ${({ size }) => size}px;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
    transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  `,
}

function Icon({
  type,
  className,
  size,
  rotate,
  onClick,
}: IconProps): ReactElement {
  return (
    <Styled.Icon
      onClick={onClick}
      type={type}
      size={size ?? 0}
      rotate={rotate ?? 0}
      className={className}
    />
  )
}

export default Icon
