import styled from 'styled-components'
import { Colors } from '../../styles/colors'

import { useMediaQuery } from 'react-responsive'

import { useEffect, useRef, useState } from 'react'
import { useInView } from 'framer-motion'
import WithHana from '../../components/mainpage/WithHana'
import WithHanaMobile from '../../components/mobile-mainpage/WithHanaMobile'
import BasicInstallModal from '../../components/base/modal/basic_install_modal'
import InstaEventModal from '../../components/base/modal/insta_event_modal'
import moment from 'moment'
import MobileFloatBtn from '../../components/base/float_btn/mobile_float_btn'
import SectionOne from '@components/main/SectionOne'
import SectionTwo from '@components/main/SectionTwo'
import SectionThree from '@components/main/SectionThree'
import SectionFour from '@components/main/SectionFour'
import SectionFive from '@components/main/SectionFive'
import SectionSix from '@components/main/SectionSix'
import SectionSeven from '@components/main/SectionSeven'
import SectionEight from '@components/main/SectionEight'
import SectionNine from '@components/main/SectionNine'
import SectionTen from '@components/main/SectionTen'
import SectionEleven from '@components/main/SectionEleven'
import SectionTwelve from '@components/main/SectionTwelve'
import PageTen from '@components/mainpage/PageTen'
import SectionBottom from '@components/main/SectionBottom'
import DownloadModal from '@components/base/modal/DownloadModal'
import FloatBtn from '../../components/base/FloatBtn'

export const Styled = {
  Container: styled.div`
    display: flex;
    background-color: ${Colors.gf};
    flex-direction: column;
    align-items: center;
  `,

  MobileContainer: styled.div`
    @media (min-width: 999px) {
      display: none;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Colors.gf};
  `,

  FloatingBtnContainer: styled.div`
    position: fixed;
    background-color: ${Colors.primary_ios};
    border-radius: 100px;
    width: 112px;
    height: 38px;
    bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  `,

  OpenBox: styled.div`
    width: 100%;
    position: absolute;
    z-index: 100;
    /* border: solid 1px red; */
    top: calc(100vh + 220px);
  `,

  CloseEventBox: styled.div`
    width: 100%;
    position: absolute;
    z-index: 100;
    /* border: solid 1px red; */
    top: 220px;
  `,

  Box: styled.div`
    width: 100%;
    height: 120px;
    background-color: ${Colors.maingrey_bg};
  `,

  FloatBtnContainer: styled.div`
    position: fixed;

    display: flex;

    z-index: 100;
    bottom: 50px;
    right: 50px;
  `,

  FloatDownloadBtn: styled.div`
    background-color: ${Colors.primary_ios};
    border-radius: 100px;
    padding: 0px 54.5px;
    height: 70px;

    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
  `,

  FloatScrollUpBtn: styled.div`
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    background-color: ${Colors.primary_ios};
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  `,

  FloatScrollUpIcon: styled.img`
    width: 18.14px;
    height: 21.6px;
  `,
}

const {
  Container,
  MobileContainer,
  FloatingBtnContainer,
  CloseEventBox,
  OpenBox,
  Box,
} = Styled

const MainTestPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isShowFloatBtn, setIsShowFloatBtn] = useState(false)
  const [isShowDownBtn, setIsShowDownBtn] = useState(false)
  const [isOpenInstaModal, setIsOpenInstaModal] = useState(false)
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1000px)' })

  const openTarget = useRef(null)
  const isInViewOpen = useInView(openTarget)

  const closeTarget = useRef(null)
  const isInViewClose = useInView(closeTarget)

  useEffect(() => {
    if (isInViewOpen) {
      setIsShow(true)
    }
    if (isInViewClose) {
      setIsShow(false)
    }
  }, [isInViewOpen, isInViewClose])

  useEffect(() => {
    const stopModalDate = localStorage.getItem('stopModalDate')

    const today = moment().format('YYYY-MM-DD')

    //! Modal, BottomSheet 막아놓음
    if (stopModalDate !== today) {
      setIsOpenInstaModal(false)
      setIsBottomSheetOpen(false)
    } else {
      setIsOpenInstaModal(false)
      setIsBottomSheetOpen(false)
    }
  }, [])

  return (
    <>
      <SectionOne setIsOpenModal={setIsOpenModal} />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <SectionNine />
      <SectionTen />
      <SectionEleven />
      <SectionTwelve />
      <SectionBottom />
      <DownloadModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
    </>
    // <>
    //   {isMobileScreen ? (
    //     <MobileContainer>
    //       <OpenBox ref={openTarget} />
    //       <CloseEventBox ref={closeTarget} />
    //       <ScreenOne />
    //       {isBottomSheetOpen ? null : (
    //         <>
    //           <ScreenTwo />
    //           <ScreenThree />
    //           <ScreenFour />
    //           <Box />
    //           <ScreenFive />
    //           <Box />
    //           <ScreenSix />
    //           <ScreenSeven />
    //           <Spacer height={120} />
    //           <ScreenEight />
    //           <Spacer height={120} />
    //           <ScreenNine />
    //           <Spacer height={120} />
    //           <WithHanaMobile />
    //           <ScreenTen />
    //         </>
    //       )}
    //       {isShow ? <MobileFloatBtn /> : null}
    //       <InstaEventBottomSheet
    //         isOpen={isBottomSheetOpen}
    //         setIsOpen={setIsBottomSheetOpen}
    //       />
    //     </MobileContainer>
    //   ) : (
    //     <Container>
    //       <PageOne
    //         isShowFloatBtn={isShowFloatBtn}
    //         setIsShowFloatBtn={setIsShowFloatBtn}
    //       />
    //       <PageTwo />
    //       <PageThree />
    //       <PageFour />
    //       <PageFive />
    //       {/* //! 복잡한 청약정보 */}
    //       <PageSix />
    //       <PageSeven />
    //       <PageEight />
    //       <PageNine />
    //       <WithHana />
    //       <PageTen
    //         isShowDownBtn={isShowDownBtn}
    //         setIsShowDownBtn={setIsShowDownBtn}
    //       />
    //       {isShowFloatBtn ? (
    //         <FloatBtn
    //           isOpen={isOpen}
    //           setIsOpen={setIsOpen}
    //           isShowDownBtn={isShowDownBtn}
    //           setIsShowDownBtn={setIsShowDownBtn}
    //         />
    //       ) : null}
    //   <BasicInstallModal isOpen={isOpen} setIsOpen={setIsOpen} />
    //       <InstaEventModal
    //         isOpen={isOpenInstaModal}
    //         setIsOpen={setIsOpenInstaModal}
    //       />
    //     </Container>
    //   )}
    // </>
  )
}

export default MainTestPage
