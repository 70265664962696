import React from 'react'
import styled from 'styled-components'

const PlayingButton = () => {
  return <Container>재생중</Container>
}

const Container = styled.div`
  padding: 4px 12px;
  box-sizing: border-box;
  border-radius: 40px;
  border: 1px solid #e0f2fe;
  background-color: #f0f9ff;
  color: #0ea5e9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Spoqa Han Sans Neo', sans-serif;
`
export default PlayingButton
