import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Content03 = () => {
  return (
    <Container>
      <Title>월 평균 방문자</Title>
      <Count>389,000</Count>
      <Desc>{`* 제휴 포함`}</Desc>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  padding: 3.5rem 2.5rem;
  border-radius: 2rem;
  box-sizing: border-box;
  width: 24.8438rem;
  height: 16.5rem;
  background-color: #ecff00;
`;

const Title = styled.div`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 120%;
`;

const Count = styled.div`
  font-size: 72px;
  font-style: normal;
  line-height: 120%;
`;

const Desc = styled.div`
  white-space: pre-line;
  color: #8d8d8d;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export default Content03;
