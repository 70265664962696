import { ReactElement, useState } from 'react'
import { Switch } from 'react-router-dom'
import styled from 'styled-components'
import routerItems from './router/paths'
import RouterRenderer from './router/routerRender'
import { Colors } from '@styles/colors'
import ChannelTalkManager from '@components/ChannelTalkManager'
import FloatBtn from '@components/base/FloatBtn'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const Wrapper = styled.div`
  background-color: ${Colors.gf};
  min-height: 100vh;
`

function App(): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const queryClient = new QueryClient()
  // 시뮬게임에서만 사용

  const isEventPage = window.location.href.includes('kb-event') ? true : false
  const applinkPage =
    window.location.href.includes('app-link-bridge') ||
    window.location.href.includes('welcome')
      ? true
      : false

  const inquirePage = window.location.href.includes('inquire') ? true : false
  const newMainPage = window.location.href.includes('new-main')
  const guidePage = window.location.href.includes('guide')
  const samsungEventPage = window.location.href.includes('samsung-event')
  const samsungEvent160Page = window.location.href.includes('samsung-event-160')
  const kbEventPage = window.location.href.includes('kb-event')
  const mobileMainPage = window.location.href.includes('mobile-main')
  const tabletMainPage = window.location.href.includes('tablet-main')
  const simulatedPage = window.location.href.includes('simulated-event')
  const eventPage = window.location.href.includes('event')
  const simulateSignPage = window.location.href.includes('simulated-sign')
  const simulateForgetPage = window.location.href.includes('simulated-forget')
  const simulateChangePwPage =
    window.location.href.includes('simulated-changepw')
  const simulateMarketingPage = window.location.href.includes(
    'simulated-marketing'
  )

  const isSimulatedTradingMainPage = window.location.href.includes(
    'simulated-trading-main'
  )
  const isSimulatedBlurPage = window.location.href.includes('simulated-blur')

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <meta
            property={'title'}
            content={'일육공 160 - 공모주 투자의 확률을 높이다'}
          />
          <meta
            property={'description'}
            content={
              '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.'
            }
          />
          <meta
            property={'og:title'}
            content={'일육공 160 - 공모주 투자의 확률을 높이다'}
          />
          <meta
            property={'og:description'}
            content={
              '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.'
            }
          />
        </Helmet>
        <Wrapper>
          {/* {!isEventPage &&
        !applinkPage &&
        !inquirePage &&
        !newMainPage &&
        !guidePage &&
        !samsungEventPage &&
        !samsungEvent160Page &&
        !mobileMainPage &&
        !tabletMainPage && <Header />} */}
          {!guidePage &&
            !samsungEventPage &&
            !mobileMainPage &&
            !tabletMainPage &&
            !kbEventPage &&
            !samsungEvent160Page &&
            !mobileMainPage &&
            !eventPage &&
            !simulatedPage &&
            !simulateSignPage &&
            !simulateForgetPage &&
            !simulateChangePwPage &&
            !simulateMarketingPage &&
            !isSimulatedBlurPage &&
            !isSimulatedTradingMainPage &&
            !kbEventPage && (
              <FloatBtn
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isShowDownBtn={true}
              />
            )}

          <ChannelTalkManager />

          <Switch children={RouterRenderer(routerItems)} />
          {/* {!isEventPage &&
        !applinkPage &&
        !inquirePage &&
        !guidePage &&
        !newMainPage &&
        !mobileMainPage &&
        !tabletMainPage &&
        !samsungEventPage &&
        !samsungEvent160Page && <Footer />} */}
        </Wrapper>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
