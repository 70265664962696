import { Colors } from '@styles/colors'
import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.section`
  width: 100%;
  height: 1053px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(252, 203, 51, 0.1);
  position: relative;
  z-index: 1;

  @media (max-width: 1430px) {
    height: 1400px;
    padding: 0 20px;
    align-items: flex-start;
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 0 20px;
    align-items: flex-start;
  }
`

export const WhiteBackground = styled.div`
  background-color: ${Colors.gf};
  height: 276px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  @media (max-width: 1430px) {
    left: 0;
    height: 15%;
  }
  @media (max-width: 767px) {
    left: 0;
    height: 15%;
  }
`

export const ImagePhone = styled.img`
  width: 558px;
  height: 927px;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 33%;
  transform: translate(-50%, -50%);
  @media (max-width: 1850px) {
    left: 28%;
  }
  @media (max-width: 1430px) {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }

  @media (max-width: 767px) {
    position: static;
    transform: none;
    width: 100%;
    padding: 0 29px;
    object-fit: contain;
    height: auto;
  }
`

export const TextWrapper = styled.div`
  width: 625px;
  height: 648px;
  position: absolute;
  z-index: 10;
  top: 38%;
  left: 73%;
  transform: translate(-50%, -50%);
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const ArrowImg = styled.img`
  width: 32px;
  height: 32px;
`

//mobile
