export enum IpoStockGubun {
  normal = '일반',
  spec = '스팩',
  reits = '리츠',
}

export enum IpoStockStatus {
  listing = '상장완료',
  offering = '청약중',
  prelisting = '상장전',
  preoffering = '청약전',
  withdrawal = '청약철회',
  entire = '전체',
}
