import styled from 'styled-components'

const Styled = {
  Content: styled.div<{ size: number; src: string | null }>`
    border-radius: 50%;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    background: url(${({ src }) => src}) white no-repeat center center;
    background-size: contain;
  `,
}

const { Content } = Styled

interface ProfileProps {
  size: number
  src: string | null
}
function Profile({ size, src }: ProfileProps) {
  return <Content size={size} src={src} />
}

export default Profile
