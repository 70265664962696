import { DevAxios } from '@apis/axios'

export const getData = async <T>(url: string): Promise<T> => {
  const response = await DevAxios.get<T>(url)
  return response.data
}

export const postData = async <T, U>(url: string, data: U): Promise<T> => {
  const response = await DevAxios.post<T>(url, data);
  return response.data;
}

export const patchData = async <T, U>(url: string, data: U): Promise<T> => {
  const response = await DevAxios.patch<T>(url, data);
  return response.data;
}
