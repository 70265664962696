import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (max-width: 767px) {
    height: 400px;
  }
  @media (max-width: 480px) {
    height: 281px;
  }
`

export const YellowBackground = styled.div`
  width: 100%;
  height: 542px;
  background-color: ${Colors.primary_ios};
  position: absolute;
  bottom: 0;
  @media (max-width: 767px) {
    height: 300px;
  }
  @media (max-width: 480px) {
    height: 181px;
  }
`

export const ImagePhone = styled.img`
  width: 797px;
  height: 675px;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
  @media (max-width: 767px) {
    width: 500px;
    height: 500px;
    top: auto;
    left: auto;
    right: auto;
    bottom: 0;
    top: auto;
    transform: none;
  }
  @media (max-width: 480px) {
    width: 308px;
    height: 303px;
    top: auto;
    left: auto;
    right: auto;
    bottom: 0;
    top: auto;
    transform: none;
  }
`

export const ImageFinger = styled.img`
  width: 487px;
  height: 582px;
  top: 57%;
  left: 73.5%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
  @media (max-width: 1200px) {
    width: 380px;
    height: 500px;
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
    transform: none;
  }
  @media (max-width: 767px) {
    width: 300px;
    height: 400px;
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
    transform: none;
  }
  @media (max-width: 480px) {
    width: 170px;
    height: 244px;
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
    transform: none;
  }
`

export const ImageMore = styled.img`
  width: 48px;
  height: 40px;
  position: absolute;
  z-index: 10;
  top: 95%;
  left: 53%;
  transform: translate(-50%, -50%);
  animation: motion 2s linear infinite;

  @keyframes motion {
    0% {
      margin-top: -5px;
      opacity: 0;
    }
    100% {
      margin-top: 10px;
      opacity: 1;
    }
  }

  -webkit-@keyframes motion {
    0% {
      margin-top: -5px;
      opacity: 0;
    }
    100% {
      margin-top: 10px;
      opacity: 1;
    }
  }
`
