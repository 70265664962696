import React, { useState } from 'react'
import styled from 'styled-components'
import QNA from './qna'
import useRouterHistoryHandler from '../../hooks/useRouterHistoryHandler'
import { useHistory } from 'react-router-dom'

const QnaBox = () => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()

  const handlerOpenNewTab = (url: string) => {
    window.open(url, '_black')
  }

  return (
    <Container>
      <QNA
        title='FAQ'
        content='자주 묻는 질문 확인하기'
        link={() =>
          handlerOpenNewTab('https://metalogos.oopy.io/160/entrustfaq_2')
        }
      />
      <Line />
      <QNA
        title='앱이 열리지 않으시나요?'
        content='웹으로 문의하기'
        // link={() => {
        //   history.push('/', { prev: 'inquire' })
        // }}
        link={() => {
          history.push('/', { prev: 'inquire' })
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
  background-color: #fff;
  overflow: hidden;
`

const Line = styled.div`
  width: 100%;
  border-top: 1px solid #f5f5f9;
`

export default QnaBox
