import styled from 'styled-components'
import Modal from 'react-modal'


interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const passwordModalStyle = {
  content: {
    border: 0,
    // width: '80%',
    height: 'auto',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    bottom: 'auto',
    borderRadius: '4px',
    padding: '0',
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 100,
  },
}

const Styled = {
  ModalContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 56px 100px;
    padding-bottom:32px;
    justify-content: center;
    
    @media (max-width:800px){
      font-size: 18px;
      line-height: 21.6px;
      padding: 40px 58px;
    padding-bottom:16px;

    }
  `,
  Title: styled.div`
    font-family: Pretendard700, sans-serif;
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    margin-bottom:32px;
    @media (max-width:800px){
      font-size: 18px;
      line-height: 21.6px;
    }
  `,
  Description: styled.div`
    font-family: Pretendard400, sans-serif;
    font-size:22px;
    line-height: 26.4px;
    text-align: center;
    color:#555564;
    margin-bottom:32px;
    @media (max-width:800px){
      font-size:14px;
      line-height: 16.8px;
    }
  `,
  BlueButton: styled.span`
  border:none;
  border-radius: 18px;
  color:#0E6DFB;
  font-family: Pretendard700, sans-serif;
  font-size:22px;
  line-height: 26.4px;
  text-align: center;
  width:100%;
  cursor: pointer;
  @media (max-width:800px){
    font-family: Pretendard500, sans-serif;
  font-size:16px;
  line-height: 19.2px;
  }
  `,
    Line: styled.div`
    width: 100%; 
    height: 1px;
    background-color: #EEEEEE; /* 색상 */
    /* margin: 20px 0;  */
    margin-bottom:16px;
  `
}

const {
  ModalContainer,
  Title,
  Description,
  BlueButton,
  Line
} = Styled

const PasswordModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          ...passwordModalStyle,
          content: {
            ...passwordModalStyle.content,
            width: '80%',
            maxWidth: '500px',
          },
        }}
        ariaHideApp={false}
      >
        <ModalContainer>
          <Title>
            비밀번호가 틀렸습니다
          </Title>
          <Description>
            비밀번호를 다시 <br />
            확인해주세요
          </Description>
          <Line />
          <BlueButton onClick={()=>{
            setIsOpen(false);
          }}>
            확인하기
          </BlueButton>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default PasswordModal
