import Spacer from '@components/base/Spacer'
import { SpoqaLight } from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'
import axios from 'axios'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

const RedirectSamsungPage = () => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('redirect')
  //     window.location.href = 'i6o://contract-completion'
  //   })
  // }, [])

  const handleClick = () => {
    window.location.href = 'i6o://contract-completion'
  }

  return (
    <Container>
      <div onClick={handleClick}>redirect</div>
    </Container>
  )
}

export default RedirectSamsungPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  width: 100%;
  height: 100vh;
`
const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  text-align: center;
`
const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 55px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Button = styled.div`
  width: 80%;
  padding: 0 24px;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primary_ios};
`

const Image = styled.img``
