import { useAnimation, useInView } from 'framer-motion'
import Spacer from '../../base/Spacer'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '../../base/TextStyle/Spoqa'
import {
  Container,
  ContentWrapper,
  ImageBorder,
  ImageLeft,
  ImageMobile,
  ImageRight,
  ImageWrapper,
  Row,
  TextWrapper,
} from './styles'
import { Colors } from '@styles/colors'
import { useMediaQuery } from 'react-responsive'
import { BoldText, PaddingWrapper } from '../SectionSeven/styles'

const SectionEleven = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  const isTabletL = useMediaQuery({ query: '(max-width: 1430px)' })
  return (
    <Container>
      {isTabletL ? (
        <ContentWrapper>
          <Spacer height={120} />
          <PaddingWrapper>
            <BoldText
              size={isMobile ? 14 : 24}
              color={Colors.primary_ios}
              weight={700}
            >
              실시간 · 청약 경쟁률
            </BoldText>
            <Spacer height={16} />
            <SpoqaRegular size={isMobile ? 22 : 30} color={Colors.g12}>
              투자결정 내렸다면?
            </SpoqaRegular>
            <Spacer height={10} />
            <SpoqaBold size={isMobile ? 22 : 30} color={Colors.g12}>
              주간사별
            </SpoqaBold>
            <Spacer height={8} />
            <Row>
              <SpoqaBold size={isMobile ? 22 : 30} color={Colors.g12}>
                실시간 경쟁률
              </SpoqaBold>
              <Spacer width={4} />
              <SpoqaRegular size={isMobile ? 22 : 30} color={Colors.g12}>
                확인까지
              </SpoqaRegular>
            </Row>
            <Spacer height={32} />
            <SpoqaRegular
              size={isMobile ? 13 : 19}
              color={Colors.g12}
              lineHeight={isMobile ? 18.2 : 30}
            >
              투자를 하고자 마음을 먹었는데 어느 증권사에
              <br />
              청약을 해야 할지 모르시겠다고요?
              <br />
              실시간 경쟁률을 확인하시고 배정주식수가
              <br />
              유리한 증권사에 청약하세요! <br />
            </SpoqaRegular>
          </PaddingWrapper>
          <ImageWrapper>
            {isTablet ? (
              <ImageMobile
                src={'images/main/section-eleven/mobile.png'}
                alt='mobile'
              />
            ) : (
              <ImageWrapper>
                <ImageLeft
                  src={'/images/main/section-eleven/left.png'}
                  alt='left'
                />
                <ImageBorder
                  src={'/images/main/section-eleven/dotted_border.png'}
                  alt='border'
                />
                <ImageRight
                  src={'/images/main/section-eleven/right.png'}
                  alt='right'
                />
              </ImageWrapper>
            )}
          </ImageWrapper>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <TextWrapper>
            <SpoqaBold size={24} color={Colors.primary_ios}>
              실시간·청약 경쟁률
            </SpoqaBold>
            <Spacer height={16} />
            <SpoqaRegular size={48}>투자결정 내렸다면?</SpoqaRegular>
            <Spacer height={16} />
            <SpoqaBold size={48} color={Colors.g12} lineHeight={62.4}>
              주간사별
            </SpoqaBold>
            <Row>
              <SpoqaBold size={48} color={Colors.g12} lineHeight={62.4}>
                실시간 경쟁률
              </SpoqaBold>
              <Spacer width={12} />
              <SpoqaRegular size={48} color={Colors.g12} lineHeight={62.4}>
                확인까지
              </SpoqaRegular>
            </Row>
            <Spacer height={40} />
            <SpoqaRegular size={22} lineHeight={30.8}>
              투자를 하고자 마음을 먹었는데 어느 증권사에 청약을 <br />
              해야 할지 모르시겠다고요? 실시간 경쟁률을 확인하시고 <br />
              배정주식수가 유리한 증권사에 청약하세요!
            </SpoqaRegular>
          </TextWrapper>
          <ImageWrapper>
            <ImageLeft
              src={'/images/main/section-eleven/left.png'}
              alt='left'
            />
            <ImageBorder
              src={'/images/main/section-eleven/dotted_border.png'}
              alt='border'
            />
            <ImageRight
              src={'/images/main/section-eleven/right.png'}
              alt='right'
            />
          </ImageWrapper>
        </ContentWrapper>
      )}
      {/* <ImageWrapper>
        <Image
          src={'/images/main/section-eleven/right.png'}
          alt='right'
        />
      </ImageWrapper> */}
    </Container>
  )
}

export default SectionEleven
