import { Colors } from '../../../styles/colors'
import { BottomSheet } from 'react-spring-bottom-sheet'
import styled from 'styled-components'
import { SpoqaBold, SpoqaMedium } from '../TextStyle/Spoqa'
import moment from 'moment'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  BannerImg: styled.img``,

  SheetFooter: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  MoreInfoBtn: styled.div`
    position: absolute;
    background-color: ${Colors.gf};
    left: 17.27px;
    bottom: 24px;
    padding: 11px 21px;
    box-shadow: 0px 3.84308px 15.3723px rgba(109, 109, 109, 0.07);
    border-radius: 100px;
  `,
}

const { Container, BannerImg, SheetFooter, MoreInfoBtn } = Styled

const AdsBottomSheet: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <BottomSheet
        open={isOpen}
        // onDismiss={() => {
        //   setIsOpen(false)
        // }}
        color={Colors.d_day}
        footer={
          <SheetFooter>
            <SpoqaMedium
              size={14}
              color={Colors.g9}
              onClick={() => {
                const today = moment().format('YYYY-MM-DD')
                setIsOpen(false)
                localStorage.setItem('stopAds', today)
              }}
            >
              오늘은 그만보기
            </SpoqaMedium>
            <SpoqaMedium
              size={14}
              color={Colors.g9}
              onClick={() => {
                setIsOpen(false)
              }}
            >
              닫기
            </SpoqaMedium>
          </SheetFooter>
        }
      >
        <Container>
          <BannerImg src='images/ads-img.png' />
          <MoreInfoBtn
            onClick={() => {
              window.location.href = '/'
            }}
          >
            <SpoqaBold size={14} color={Colors.g0}>
              일육공 더 알아보기
            </SpoqaBold>
          </MoreInfoBtn>
        </Container>
      </BottomSheet>
    </>
  )
}

export default AdsBottomSheet
