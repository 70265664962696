import React from "react";
import styled from "styled-components";
import Content01 from "./content01";
import Content02 from "./content02";
import Content03 from "./content03";
import Content04 from "./content04";
import Content05 from "./content05";
import Content06 from "./content06";
import Content07 from "./content07";

const Index = () => {
  return (
    <Container>
      <Content01 />
      <Content02 />
      <Content03 />
      <Content04 />
      <Content05 />
      <Content06 />
      <Content07 />
      <SourceContainer>
          <Source>출처: 메타로고스(주) <Line /> 기준일: 2024년 11월 1일</Source>
        </SourceContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 360px;
  justify-content: center;
`;


const Source = styled.div`
  color: #414351;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: end;
  white-space: pre-line;
  text-align: end;
  gap: 4px;
`

const SourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 312px;

`
const Line = styled.div`
  width: 1.012px;
  height: 12.688px;
  background-color: #414351;
`



export default Index;
