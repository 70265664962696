import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { Desktop } from '@constants/mediaQuery'
import { Link } from 'react-router-dom'
import { useScrollBlock } from '@hooks/useScrollBlock'

const Background = styled.div`
  position: fixed;
  z-index: 2000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`
const ModalStyle = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: transparent;
  width: 500px;
  height: 486px;

  @media screen and (max-width: ${Desktop}px) {
    width: 326px;
    height: 324px;
  }
`
const PopupImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`
const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 52px;
  padding: 0 24px;
  bottom: 24px;
  @media screen and (max-width: ${Desktop}px) {
    height: 40px;
    padding: 0 16px;
    bottom: 16px;
  }
`
const NavigateButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d1686;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  font-family: Pretendard400, sans-serif;
  color: ${Colors.gf};
  @media screen and (max-width: ${Desktop}px) {
    height: 40px;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
  }
`
const CloseIcon = styled.button`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
  @media screen and (max-width: ${Desktop}px) {
    top: 16px;
    right: 16px;
  }
  > img {
    width: 44px;
    height: 44px;
    @media screen and (max-width: ${Desktop}px) {
      width: 24px;
      height: 24px;
    }
  }
`
const EVENT_PAGE_PATH = '/simulated-event'
const LandingPopup = () => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (isOpen) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => allowScroll()
  }, [isOpen])

  return (
    <>
      {isOpen && (
        <>
          <Background onClick={() => setIsOpen(false)} />
          <ModalStyle>
            <CloseIcon onClick={() => setIsOpen(false)}>
              <img src={'/icons/close_white.png'} alt='close' />
            </CloseIcon>
            <PopupImage
              src={'/images/event/simulate_event_popup_low.png'}
              srcSet={'/images/event/simulate_event_popup_high.png'}
              loading={'eager'}
            />
            <ButtonWrapper>
              <NavigateButton to={EVENT_PAGE_PATH}>
                이벤트 자세히 보기
              </NavigateButton>
            </ButtonWrapper>
          </ModalStyle>
        </>
      )}
      {}
    </>
  )
}

export default LandingPopup
