import { Colors } from "../../styles/colors";
import styled, { css } from "styled-components";

const Styled = {
  Wrapper: styled.div<{
    width?: number | string;
    height?: number | string;
    background?: Colors;
  }>`
    width: auto;
    height: auto;

    ${({ width }) =>
      typeof width === "number"
        ? width &&
          css`
            width: ${width}px;
          `
        : css`
            width: ${width};
          `};
    ${({ height }) =>
      typeof height === "number"
        ? height &&
          css`
            height: ${height}px;
          `
        : css`
            height: ${height};
          `};
    ${({ background }) =>
      background &&
      css`
        background-color: ${background};
      `};
  `,
};

const { Wrapper } = Styled;

interface SpacerProps {
  width?: number | string;
  height?: number | string;
  background?: Colors;
}
function Sepertator({ width, height, background }: SpacerProps) {
  return <Wrapper width={width} height={height} background={background} />;
}

export default Sepertator;
