import { ReactElement, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import PageOne from '../components/calendarpage/PageOne'
import ScreenOne from '../components/mobile-calendarpage/ScreenOne'

export const Styled = {
  Container: styled.div``,
}

const { Container } = Styled

const CalendarPage = (): ReactElement => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1000px)' })

  return (
    <Container>
      {isMobileScreen ? (
        <ScreenOne />
      ) : (
        <>
          <PageOne />
        </>
      )}
    </Container>
  )
}

export default CalendarPage
