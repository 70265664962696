import { useMutation, useQuery } from '@tanstack/react-query'
import { patchData } from '../utils/api'
import { AxiosError } from 'axios'

type ReturnType<T> = {
  data: T
}
type CurrentDataType = {
  statusCode: number
  message: string
}

type RequestDataType = {
  name: string | null;
  mobile: string | null;
  newPassword: string | null;
};

const usePatchSimulatedPassword = () => {
  const patchPasswordUrl = '/mock-investment/user/password'

  const simulatedPasswordData = useMutation<
    ReturnType<CurrentDataType>,
    AxiosError,
    RequestDataType
  >({
    mutationFn: (data) => patchData<ReturnType<CurrentDataType>,RequestDataType>(patchPasswordUrl, data),
    retry: false
  })



  return {
    simulatedPasswordData,
  }
}

export default usePatchSimulatedPassword
