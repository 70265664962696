import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { SpoqaBold, SpoqaLight } from '@components/base/TextStyle/Spoqa'
import { Desktop } from '@constants/mediaQuery'
import { getNow } from '../../utils/dateHelper'
import AnimatedCounter from '@components/simulated-trading/AnimatedCounter'

const Main = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 70px 56px 40px;
  background: ${Colors.gf};
  border-radius: 12px;

  @media screen and (max-width: ${Desktop}px) {
    padding: 40px 16px 24px;
  }
`

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  > img {
    height: 90px;
    @media screen and (max-width: ${Desktop}px) {
      height: 48px;
    }
  }
`
const AggregationDate = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 56px;
  top: 40px;
  height: 30px;
  padding: 4px 12px;
  border-radius: 10px;
  border: 1px solid #dcdeeb;
  @media screen and (max-width: ${Desktop}px) {
    padding: 4px 6px;
    height: 22px;
    right: 16px;
    top: 24px;
    border-width: 1px;
  }
  > span {
    font-family: Pretendard500, sans-serif;
    letter-spacing: -0.33px;
    font-size: 18px;
    color: #555564;

    @media screen and (max-width: ${Desktop}px) {
      font-size: 11px;
    }
  }
`

const ProgressBarSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 88px;
`
const Flag = styled.div`
  position: absolute;
  display: flex;
  right: -3px;
  top: 6px;
  @media screen and (max-width: ${Desktop}px) {
    top: 14px;
  }
  > img {
    width: 48px;
    height: 64px;
    @media screen and (max-width: ${Desktop}px) {
      width: 40px;
      height: 50px;
    }
  }
`

const Caption = styled.div`
  display: flex;
  justify-content: flex-end;
  > span {
    font-family: Pretendard400, sans-serif;
    line-height: 19px;
    font-size: 16px;
    color: #999ba9;
    margin-top: 20px;

    @media screen and (max-width: ${Desktop}px) {
      font-size: 12px;
      line-height: 14px;
      margin-top: 16px;
    }
  }
`
const ProgressBar = styled.div`
  display: flex;
  height: 24px;
  width: 100%;
  border-radius: 8px;
  margin-top: 48px;
  z-index: 1;
  background: #eaeef3;
  @media screen and (max-width: ${Desktop}px) {
    height: 16px;
  }
`
const ProgressActive = styled.div<{ percent: number }>`
  position: relative;
  transition: width ease-in-out 0.7s;
  width: ${(props) => props.percent}%;
  height: 24px;
  border-radius: 8px;
  padding-left: 34px;
  background: linear-gradient(90deg, #ffedb4 0%, #fccb33 100%);
  @media screen and (max-width: ${Desktop}px) {
    height: 16px;
  }
`
const WonIcon = styled.div`
  position: absolute;
  overflow: hidden;
  right: -4px;
  bottom: -18px;
  @media screen and (max-width: ${Desktop}px) {
    bottom: -12px;
  }
  > img {
    width: 56px;
    height: 56px;
    @media screen and (max-width: ${Desktop}px) {
      width: 40px;
      height: 40px;
    }
  }
`

const Statistics = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 52px 0 12px 0;
  @media screen and (max-width: ${Desktop}px) {
    gap: 10px;
    margin: 30px 0 16px 0;
  }
`
const SpaceBetween = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Label = styled.span<{ color?: string }>`
  color: ${({ color }) => color || '#19191B'};
  font-size: 18px;
  line-height: 22px;
  font-family: Pretendard500, sans-serif;
  @media screen and (max-width: ${Desktop}px) {
    font-family: Pretendard400, sans-serif;
    font-size: 14px;
    line-height: 17px;
  }
`
const Value = styled.span`
  color: #f2a900;
  transition: all 0.3s ease-in-out;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.84px;
  font-family: Pretendard700, sans-serif;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 22px;
    line-height: 26px;
  }
`
const AmountText = styled.span<{ color?: string }>`
  font-size: 22px;
  font-family: Pretendard500, sans-serif;
  line-height: 26px;
  color: ${({ color }) => color || '#19191B'};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 19px;
  }
`
const BonusAmount = styled(AmountText)`
  font-family: Pretendard700, sans-serif;
  @media screen and (max-width: ${Desktop}px) {
    font-family: Pretendard500, sans-serif;
  }
`
const BonusLabel = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #999ba9;
  font-family: Pretendard400, sans-serif;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 12px;
    line-height: 14px;
  }
`
const BonusCol = styled(SpoqaBold)<{ color?: string }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Sum = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  gap: 16px;
  background-color: ${Colors.background_sub};
  @media screen and (max-width: ${Desktop}px) {
    gap: 8px;
    padding: 16px;
    border-radius: 16px;
  }
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CaptionInfoButton = styled.button`
  padding: 0;
  cursor: pointer;
  position: relative;

  > img {
    width: 24px;
    height: 24px;
    opacity: 0.6;
    @media screen and (max-width: ${Desktop}px) {
      width: 16px;
      height: 16px;
    }
  }
`
const CaptionDecorationImage = styled.div`
  position: absolute;
  top: -8px;
  left: 200px;
  @media screen and (max-width: ${Desktop}px) {
    left: 140px;
  }
`
const CaptionInfoBox = styled.div`
  position: absolute;
  width: 385px;
  right: -168px;
  top: 32px;
  padding: 8px;
  font-size: 14px;
  line-height: 17px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  font-family: Pretendard400, sans-serif;
  color: ${Colors.gf};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 12px;
    line-height: 14px;
    word-break: auto-phrase;
    text-align: left;
    top: 24px;
    right: -21px;
    width: 174px;
    height: max-content;
  }
`
type Props = {
  eventProfitPercentage: number
  eventProfit: number
  totalAmountReceived: number
  startDate: string
  endDate: string
}

const ProgressMain = ({
  eventProfitPercentage,
  eventProfit,
  totalAmountReceived,
  startDate,
  endDate,
}: Props) => {
  const formattedEventPercentage =
    eventProfitPercentage > 100 ? 100 : eventProfitPercentage
  const [isOpenToggle, setIsOpenToggle] = useState(false)
  return (
    <Main>
      <AggregationDate>
        <SpoqaLight>
          <span>{getNow().format('M월 D일')} 기준</span>
        </SpoqaLight>
      </AggregationDate>
      <TitleBox>
        <img
          src={'/images/event/simulate-logo-web.png'}
          alt={'simulate-game-event'}
        />
      </TitleBox>
      <ProgressBarSection>
        <Flag>
          <img src='/images/event/simulate-flag.png' alt={'goal'} />
        </Flag>
        <ProgressBar>
          <ProgressActive percent={formattedEventPercentage}>
            <WonIcon>
              <img src={'/images/event/simulate-goal.png'} alt={'goal'} />
            </WonIcon>
          </ProgressActive>
        </ProgressBar>
        <Caption>
          <span>최대 3만원</span>
        </Caption>
      </ProgressBarSection>
      <Statistics>
        <SpaceBetween>
          <Label>수익금 + 참여지원금</Label>
          <Value>
            <AnimatedCounter
              targetAmount={totalAmountReceived + eventProfit}
              duration={1500}
            />
          </Value>
        </SpaceBetween>
        <SpaceBetween>
          <Flex>
            <Label color={'rgba(85,85,100,0.6)'}>
              실제 일육공 간편투자 수익
            </Label>
            <CaptionInfoButton onClick={() => setIsOpenToggle((prev) => !prev)}>
              <img
                src={'/images/event/simulated_info.svg'}
                alt={'lottery prize'}
              />
              {isOpenToggle ? (
                <CaptionInfoBox>
                  <CaptionDecorationImage>
                    <img
                      src={'/icons/simulate-caption-triangle.png'}
                      alt={'caption'}
                      width={10}
                      height={8}
                    />
                  </CaptionDecorationImage>
                  {startDate} ~ {endDate}까지의 실제 일육공 간편투자 서비스 실현
                  수익입니다.
                </CaptionInfoBox>
              ) : null}
            </CaptionInfoButton>
          </Flex>

          <AmountText color={'rgba(25, 25, 27, 0.6)'}>
            {totalAmountReceived}원
          </AmountText>
        </SpaceBetween>
      </Statistics>
      <Sum>
        <SpaceBetween>
          <BonusCol>
            <Label>추가 보너스</Label>
            <BonusLabel>이벤트 종료 후 추첨 지급</BonusLabel>
          </BonusCol>

          <BonusAmount>500,000원</BonusAmount>
        </SpaceBetween>
      </Sum>
    </Main>
  )
}

export default ProgressMain
