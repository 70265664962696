import React from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { NavigateShare } from '../../utils'
import { isAndroid } from 'react-device-detect'

const MobileHeaderStyle = styled.header`
  display: flex;
  width: 100%;
  position: fixed;
  padding: 0 24px;
  height: 48px;
  top: 0;
  justify-content: space-between;
  border-bottom: 1px solid #c9d3de;
  background-color: ${Colors.gf};
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 300;
`
const RNHeader = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > button {
    padding: 0;
    cursor: pointer;
  }
`
const ShareButton = styled.button`
  font-family: SpoqaHanSansNeoRegular, sans-serif;
  font-size: 16px;
  letter-spacing: -0.33px;
  line-height: 24px;
  color: #0e6dfb;
`

type Props = {
  isFirst?: boolean
  hasBack?: boolean
  hasClose?: boolean
  hasShareInHeader?: boolean
  handleWebviewClose: () => void
  handleBackButton: () => void
}

const AppHeader = ({
  isFirst,
  hasBack,
  hasClose,
  handleBackButton,
  handleWebviewClose,
  hasShareInHeader,
}: Props) => {
  return (
    <MobileHeaderStyle>
      <RNHeader>
        {hasBack ? (
          <button onClick={isFirst ? handleWebviewClose : handleBackButton}>
            <img src={'/icons/back.svg'} alt={'back'} width={24} height={24} />
          </button>
        ) : (
          // space-between 속성을 위한 빈 div
          <div />
        )}
        {hasClose && !isFirst ? (
          <button onClick={handleWebviewClose}>
            <img
              src={'/icons/close.svg'}
              alt={'close'}
              width={20}
              height={20}
            />
          </button>
        ) : hasShareInHeader && !isAndroid ? (
          <ShareButton onClick={NavigateShare}>공유하기</ShareButton>
        ) : null}
      </RNHeader>
    </MobileHeaderStyle>
  )
}

export default AppHeader
