import styled from "styled-components";
import { Colors } from "../../styles/colors";
import Spacer from "../base/Spacer";
import { SpoqaBold, SpoqaLight, SpoqaMedium } from "../base/TextStyle/Spoqa";

export const Style = {
  Container: styled.div`
    padding-top: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `,

  PrimaryText: styled.span``,

  Title: styled.h1``,

  Content: styled.span``,

  ContentBold: styled.span``,
};

const { Container, Title, Content, ContentBold } = Style;

const ScreenOne = () => {
  const linespace = 4;
  return (
    <Container>
      <SpoqaBold size={40} color={Colors.primary_ios}>
        <SpoqaBold size={14} color={Colors.primary_ios}>
          공모주 투자
        </SpoqaBold>
      </SpoqaBold>
      <Spacer height={8} />
      <Title>
        <SpoqaBold size={24}>일육공과 함께, 따상하자!</SpoqaBold>
      </Title>
      <Spacer height={32} />
      <Content>
        <SpoqaLight size={14}>
          일육공은 모바일 공모주 투자 길잡이 서비스로,
        </SpoqaLight>
      </Content>
      <Spacer height={linespace} />
      <Content>
        <SpoqaLight size={14}>
          자체 개발한 퀀트 분석 프로그램을 활용하여
        </SpoqaLight>
        <Spacer height={linespace} />
      </Content>
      <Content>
        <SpoqaLight size={14}>
          보다 쉽고 편리한 공모주 투자 경험을 제공합니다.
        </SpoqaLight>
        <Spacer height={linespace} />
      </Content>
      <Spacer height={16} />
      <ContentBold>
        <SpoqaMedium size={14}>일육공(160)과 함께 공모주 투자</SpoqaMedium>
      </ContentBold>
      <Spacer height={linespace} />
      <ContentBold>
        <SpoqaMedium size={14}>160% 상승, 따상해봐요!</SpoqaMedium>
      </ContentBold>
      <Spacer height={40} />
    </Container>
  );
};

export default ScreenOne;
