import { RouteProps, generatePath } from "react-router";
import { LocationDescriptorObject } from "history";

import { stringify } from "qs";

export enum RouterItemType {
  DEFAULT = "default",
  REDIRECT = "redirect",
  PRIVATE = "private",
}

export interface RouterItemParam extends RouteProps {
  name: string;
  path: string;
  type: RouterItemType;
  to?: string;
}

export interface RouterItem extends RouterItemParam {
  toObject: (payload?: CustomLocation) => LocationDescriptorObject<any>;
}

export interface RedirectRouterItem extends RouterItem {
  to: string;
}

export interface CustomLocation {
  params?: {};
  queries?: {};
  states?: {};
  search?: string;
  [key: string]: any;
}

export function createRouterItem<T = RouterItem>({
  name,
  path,
  component,
  type,
  to,
}: RouterItemParam): T {
  return {
    name,
    path,
    component,
    type,
    to,
    toObject: (payload: any) => {
      return {
        pathname: generatePath(path, payload?.params),
        search: stringify(payload?.queries),
        state: payload?.states,
      };
    },
  } as unknown as T;
}
