import React from 'react'
import styled from 'styled-components'
import Section01 from './section01/index'
import Section02 from './section02/index'
import Section03 from './section03/index'
import Section05 from './section05/index'
import Section06 from './section06/index'
import Section07 from './section07/index'
import MobileHeader from '@components/layout/MobileHeader'

const Index = () => {
  return (
    <Container>
      <MobileHeader />
      <Section01 />
      <Section02 />
      <Section03 />
      <Section05 />
      <Section06 />
      <Section07 />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 360px;
`

export default Index
