import { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../styles/colors'
import Sepertator from '../base/Sepertator'
import Spacer from '../base/Spacer'
import { SpoqaBold, SpoqaRegular } from '../base/TextStyle/Spoqa'

interface ContainerProps {
  isQuestionOpen?: boolean
}

interface QuestionItemProps {
  isClick?: boolean
}

export const Styled = {
  Container: styled('div')<ContainerProps>`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  `,
  FAQlogo: styled.img`
    width: 96.23px;
    height: 66px;
  `,

  QuestionItem: styled('div')<QuestionItemProps>`
    width: 320px;
    height: ${(props) => (props.isClick ? 'auto' : '57px')};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: ${(props) =>
      props.isClick ? `${Colors.maingrey_bg}` : ''};
    padding-bottom: ${(props) => (props.isClick ? '24px' : '0px')};
    cursor: pointer;
  `,

  QuestionLeft: styled.div`
    display: flex;
    width: 84vw;
  `,

  QuestionRight: styled.div`
    display: flex;
  `,

  NumberBox: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 57px;
  `,

  QuestionBox: styled.div`
    display: flex;
    flex-direction: column;

    width: 77%;
  `,

  QuestionTitle: styled.div`
    display: flex;
    align-items: center;
    height: 57px;
  `,

  IconBox: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 57px;
    align-items: center;
  `,

  ArrowDown: styled.img`
    width: 17px;
    height: 10px;
  `,

  AnswerBox: styled.div`
    width: 100%;
  `,
}

const {
  Container,
  FAQlogo,
  QuestionItem,
  QuestionLeft,
  QuestionRight,
  NumberBox,
  IconBox,
  ArrowDown,
  QuestionBox,
  QuestionTitle,
  AnswerBox,
} = Styled

const ScreenOne = () => {
  const [question1, setQuestion1] = useState(false)
  const [question2, setQuestion2] = useState(false)
  const [question3, setQuestion3] = useState(false)
  const [question4, setQuestion4] = useState(false)
  const [question5, setQuestion5] = useState(false)
  const [question6, setQuestion6] = useState(false)
  const [question7, setQuestion7] = useState(false)
  const [question8, setQuestion8] = useState(false)

  const isQuestionOpen =
    question1 ||
    question2 ||
    question3 ||
    question4 ||
    question5 ||
    question6 ||
    question7 ||
    question8

  const TitleSize = 15
  const AnswerSize = 13

  const TitleLineHeight = 20
  const AnswerLineHeight = 16
  return (
    <Container isQuestionOpen={isQuestionOpen}>
      <Spacer height={88} />
      <FAQlogo src='images/faq-logo.png' />
      <Spacer height={40} />
      <Sepertator width={320} height={2} background={Colors.ge} />
      {/* //! 1 */}
      <QuestionItem
        onClick={() => {
          setQuestion1(!question1)
        }}
        isClick={question1}
      >
        <QuestionLeft>
          <NumberBox>
            <SpoqaBold color={Colors.g3} size={TitleSize}>
              01
            </SpoqaBold>
          </NumberBox>
          <QuestionBox>
            <QuestionTitle>
              <SpoqaRegular
                size={TitleSize}
                color={Colors.g3}
                lineHeight={TitleLineHeight}
              >
                일육공은 어떤 서비스를 제공하나요?
              </SpoqaRegular>
            </QuestionTitle>
            {question1 ? (
              <AnswerBox>
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  회원님들께는 공모주 일정을 확인하기 편하시도록 보여드리고,
                  실시간 경쟁률과 수요예측 정보 등 공모주에 대한 종합적인 정보를
                  제공해드리고 있어요. 또한 실제 투자 결정에 도움이 될 수 있도록
                  퀀트알고리즘에 따른 해당 종목의 매력도를 알려드리고, 전문가의
                  상세한 의견도 받아보실 수 있습니다!
                </SpoqaRegular>
              </AnswerBox>
            ) : null}
          </QuestionBox>
        </QuestionLeft>
        <QuestionRight>
          <IconBox>
            {question1 ? (
              <ArrowDown src='images/arrow-up.png' />
            ) : (
              <ArrowDown src='images/arrow-down.png' />
            )}
          </IconBox>
        </QuestionRight>
      </QuestionItem>
      <Sepertator width={320} height={1} background={Colors.background_sub} />
      {/* //! 2 */}
      <QuestionItem
        onClick={() => {
          setQuestion2(!question2)
        }}
        isClick={question2}
      >
        <QuestionLeft>
          <NumberBox>
            <SpoqaBold color={Colors.g3} size={TitleSize}>
              02
            </SpoqaBold>
          </NumberBox>
          <QuestionBox>
            <QuestionTitle>
              <SpoqaRegular
                size={TitleSize}
                color={Colors.g3}
                lineHeight={TitleLineHeight}
              >
                일육공은 무슨 뜻인가요?
              </SpoqaRegular>
            </QuestionTitle>
            {question2 ? (
              <AnswerBox>
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  신규 상장 첫 거래일에 공모가의 2배까지 시초가를 형성하고,
                  2배의 시초가 기준으로 상한가인 30%까지 올라 마감 하는 경우,
                  "따상"이라 해요. 이 경우 종가가 공모가 기준 160% 상승하는
                  효과가 있는데, 고객님들의 공모주 투자 길잡이로서 이름에
                  일육공(160%)의 마음을 담았습니다.
                </SpoqaRegular>
              </AnswerBox>
            ) : null}
          </QuestionBox>
        </QuestionLeft>
        <QuestionRight>
          <IconBox>
            {question2 ? (
              <ArrowDown src='images/arrow-up.png' />
            ) : (
              <ArrowDown src='images/arrow-down.png' />
            )}
          </IconBox>
        </QuestionRight>
      </QuestionItem>
      <Sepertator width={320} height={1} background={Colors.background_sub} />
      {/* //! 3 */}
      <QuestionItem
        onClick={() => {
          setQuestion3(!question3)
        }}
        isClick={question3}
      >
        <QuestionLeft>
          <NumberBox>
            <SpoqaBold color={Colors.g3} size={TitleSize}>
              03
            </SpoqaBold>
          </NumberBox>
          <QuestionBox>
            <QuestionTitle>
              <SpoqaRegular
                size={TitleSize}
                color={Colors.g3}
                lineHeight={TitleLineHeight}
              >
                일육공의 매력지수는 어떤 의미인가요?
              </SpoqaRegular>
            </QuestionTitle>
            {question3 ? (
              <AnswerBox>
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  공모주에 청약하실 때, 손실이 날까 망설이는 마음이 드실 수
                  있어요, 일육공은 수요 예측 정보와 재무정보 등을 퀀트
                  알고리즘에 담아 투자자분들이 효율적으로 투자하실 수 있도록
                  매력지수를 제공해드리고 있습니다. 매력지수가 높을 수록
                  효과적인 투자를 기대하실 수 있습니다.
                </SpoqaRegular>
                <br />
                <br />
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  ※ 투자에 대한 최종 결정은 투자자 본인이 하셔야 하며,
                  매력지수가 투자수익을 보장드리는 건 아니고 손실이 발생할 수도
                  있어요.
                </SpoqaRegular>
              </AnswerBox>
            ) : null}
          </QuestionBox>
        </QuestionLeft>
        <QuestionRight>
          <IconBox>
            {question3 ? (
              <ArrowDown src='images/arrow-up.png' />
            ) : (
              <ArrowDown src='images/arrow-down.png' />
            )}
          </IconBox>
        </QuestionRight>
      </QuestionItem>
      <Sepertator width={320} height={1} background={Colors.background_sub} />
      {/* //! 4 */}
      <QuestionItem
        onClick={() => {
          setQuestion4(!question4)
        }}
        isClick={question4}
      >
        <QuestionLeft>
          <NumberBox>
            <SpoqaBold color={Colors.g3} size={TitleSize}>
              04
            </SpoqaBold>
          </NumberBox>
          <QuestionBox>
            <QuestionTitle>
              <SpoqaRegular
                size={TitleSize}
                color={Colors.g3}
                lineHeight={TitleLineHeight}
              >
                일육공은 어떤 좋은 점이 있나요?
              </SpoqaRegular>
            </QuestionTitle>
            {question4 ? (
              <AnswerBox>
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  일육공에서는 상장일정을 비롯하여 실시간경쟁률, 청약증거금 등
                  공모주에 대한 종합적인 정보를 한 번에 확인하실 수 있기 때문에,
                  투자하실 때에 여기저기 정보를 찾으러 다닐 필요가 없어요.
                  그리고 일육공만의 노하우를 담아 개발한 평가지표인 종목별
                  매력지수를 보실 수 있고, 전문가의 상세한 의견도 받아보실 수
                  있습니다!
                </SpoqaRegular>
              </AnswerBox>
            ) : null}
          </QuestionBox>
        </QuestionLeft>
        <QuestionRight>
          <IconBox>
            {question4 ? (
              <ArrowDown src='images/arrow-up.png' />
            ) : (
              <ArrowDown src='images/arrow-down.png' />
            )}
          </IconBox>
        </QuestionRight>
      </QuestionItem>
      <Sepertator width={320} height={1} background={Colors.background_sub} />
      {/* //! 5 */}
      <QuestionItem
        onClick={() => {
          setQuestion5(!question5)
        }}
        isClick={question5}
      >
        <QuestionLeft>
          <NumberBox>
            <SpoqaBold color={Colors.g3} size={TitleSize}>
              05
            </SpoqaBold>
          </NumberBox>
          <QuestionBox>
            <QuestionTitle>
              <SpoqaRegular
                size={TitleSize}
                color={Colors.g3}
                lineHeight={TitleLineHeight}
              >
                공모주 투자는 뭔가요?{' '}
              </SpoqaRegular>
            </QuestionTitle>
            {question5 ? (
              <AnswerBox>
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  회사가 상장을 할 때 보통 공모라는 과정을 진행하는데요, 공모는
                  상장 직전에 금융기관, 일반인, 회사 직원들에게 해당 회사의
                  주식을 공평한 가격에 살 수 있도록 하는 것을 말해요. 기관들이
                  먼저 참여하는 절차는 수요예측이라고 하고, 이 때 공모가격이
                  결정된답니다. 이후 일반인들은 청약이라는 방식을 통해서
                  공모가에 주식을 살 수 있고 이 때, 청약하여 주식을 사는 것을
                  공모주 투자라고 합니다.
                </SpoqaRegular>
              </AnswerBox>
            ) : null}
          </QuestionBox>
        </QuestionLeft>
        <QuestionRight>
          <IconBox>
            {question5 ? (
              <ArrowDown src='images/arrow-up.png' />
            ) : (
              <ArrowDown src='images/arrow-down.png' />
            )}
          </IconBox>
        </QuestionRight>
      </QuestionItem>
      <Sepertator width={320} height={1} background={Colors.background_sub} />
      {/* //! 6 */}
      <QuestionItem
        onClick={() => {
          setQuestion6(!question6)
        }}
        isClick={question6}
      >
        <QuestionLeft>
          <NumberBox>
            <SpoqaBold color={Colors.g3} size={TitleSize}>
              06
            </SpoqaBold>
          </NumberBox>
          <QuestionBox>
            <QuestionTitle>
              <SpoqaRegular
                size={TitleSize}
                color={Colors.g3}
                lineHeight={TitleLineHeight}
              >
                공모주 투자는 왜 매력적인가요?
              </SpoqaRegular>
            </QuestionTitle>
            {question6 ? (
              <AnswerBox>
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  공모주의 공모가격은 보통 비교대상 업체보다 20~30% 할인된
                  가격으로 결정되고 그래서 상장하면 수익이 날 확률이 높답니다.
                  실제로 2021년에 상장한 종목의 83%가 공모가보다 상장일의 종가가
                  높았고, 게다가 일육공의 매력지수가 50점 이상인 종목들은 무려
                  95%가 공모가보다 상장일 종가가 높았어요! 매력지수를 활용하시면
                  더욱 효과적인 투자를 하실 수 있습니다.
                </SpoqaRegular>
                <br />
                <br />
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  ※ 과거의 성과가 미래의 수익을 보장하지는 않아요.
                </SpoqaRegular>
                <br />
                <br />
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  ※ 공모주식은 상장초기 가격변동성이 크고, 상장 후 주가가
                  공모가격보다 낮아져 투자손실이 발생할 수 있습니다.
                </SpoqaRegular>
              </AnswerBox>
            ) : null}
          </QuestionBox>
        </QuestionLeft>
        <QuestionRight>
          <IconBox>
            {question6 ? (
              <ArrowDown src='images/arrow-up.png' />
            ) : (
              <ArrowDown src='images/arrow-down.png' />
            )}
          </IconBox>
        </QuestionRight>
      </QuestionItem>
      <Sepertator width={320} height={1} background={Colors.background_sub} />
      {/* //! 7 */}
      <QuestionItem
        onClick={() => {
          setQuestion7(!question7)
        }}
        isClick={question7}
      >
        <QuestionLeft>
          <NumberBox>
            <SpoqaBold color={Colors.g3} size={TitleSize}>
              07
            </SpoqaBold>
          </NumberBox>
          <QuestionBox>
            <QuestionTitle>
              <SpoqaRegular
                size={TitleSize}
                color={Colors.g3}
                lineHeight={TitleLineHeight}
              >
                청약은 어떻게 하나요?
              </SpoqaRegular>
            </QuestionTitle>
            {question7 ? (
              <AnswerBox>
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  각각의 공모주마다 상장을 주관하는 증권사가 있어요. 해당
                  증권사의 홈페이지나 HTS, MTS에서 청약을 하실 수 있는데, 보통은
                  증권사 계좌개설을 하신 후 계좌에 청약대금을 납입하고
                  청약신청을 하시면 며칠 후에 공모주를 배정받게 돼요. 만약
                  배정을 받지 못하시거나 일부만 배정받으신 경우에는 계좌로
                  청약대금을 환불 받게 된답니다. 일육공에서는 실시간 경쟁률을
                  제공해드려서 투자자분들이 더 많은 공모주를 배정받으실 수
                  있도록 도움을 드리고 있으니 참고해주세요!
                </SpoqaRegular>
              </AnswerBox>
            ) : null}
          </QuestionBox>
        </QuestionLeft>
        <QuestionRight>
          <IconBox>
            {question7 ? (
              <ArrowDown src='images/arrow-up.png' />
            ) : (
              <ArrowDown src='images/arrow-down.png' />
            )}
          </IconBox>
        </QuestionRight>
      </QuestionItem>
      <Sepertator width={320} height={1} background={Colors.background_sub} />
      {/* //! 8 */}
      <QuestionItem
        onClick={() => {
          setQuestion8(!question8)
        }}
        isClick={question8}
      >
        <QuestionLeft>
          <NumberBox>
            <SpoqaBold color={Colors.g3} size={TitleSize}>
              08
            </SpoqaBold>
          </NumberBox>
          <QuestionBox>
            <QuestionTitle>
              <SpoqaRegular
                size={TitleSize}
                color={Colors.g3}
                lineHeight={TitleLineHeight}
              >
                공모주 청약의 균등배정과 비례배정은 무엇인가요?
              </SpoqaRegular>
            </QuestionTitle>
            {question8 ? (
              <AnswerBox>
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  과거 일반 투자자들에게 적용되는 공모주 배정방식은,
                  청약증거금과 배정수량이 비례하는 비례배정방식 뿐이었어요. 즉,
                  청약증거금이 적으면 한 주도 배정 받지 못할 확률이 높았답니다.
                  그런데 2021년부터 일반 투자자들에게 배정되는 공모주식수의 50%
                  이상을 청약자들에게 동일 수량 배정하도록 하는 균등배정방식이
                  도입되었어요. 균등배정방식은 적은 청약증거금으로 최소 수량만
                  청약해도 거액 투자자와 동등한 배정기회가 부여되는 방식으로
                  청약을 신청하면 균등하게 배정되며, 다만 배정물량보다 청약자가
                  많을 경우 추첨을 통해 랜덤 배정됩니다.
                </SpoqaRegular>
                <br />
                <br />
                <SpoqaRegular
                  size={AnswerSize}
                  lineHeight={AnswerLineHeight}
                  color={Colors.g7}
                >
                  ※ [공모증권] 일반청약자에게 복수 배정방식(균등방식+비례방식
                  등)이 적용되어 각 방식에 따른 배정결과가 다를 수 있습니다.
                </SpoqaRegular>
              </AnswerBox>
            ) : null}
          </QuestionBox>
        </QuestionLeft>
        <QuestionRight>
          <IconBox>
            {question8 ? (
              <ArrowDown src='images/arrow-up.png' />
            ) : (
              <ArrowDown src='images/arrow-down.png' />
            )}
          </IconBox>
        </QuestionRight>
      </QuestionItem>
      <Sepertator width={320} height={2} background={Colors.ge} />
    </Container>
  )
}

export default ScreenOne
