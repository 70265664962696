import React, {useEffect} from 'react'
import styled from 'styled-components';
import AOS from "aos";
import "aos/dist/aos.css";


const Mobile = () => {
    useEffect(() => {
        AOS.init(
            {
                duration: 800,
                easing: 'ease-out'
            }
        );
      }, []);
  return (
    <div>
      <img src={'/img/main/mobile_samsung.svg'} alt="" data-aos="slide-up"/>
    </div>
  )
};

export default Mobile;
