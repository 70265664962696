import { useQuery } from '@tanstack/react-query'
import { getData } from '../utils/api'
import { AxiosError } from 'axios'

type ReturnType<T> = {
  data: T
}
type CurrentDataType = {
  statusCode: number,
  message: string
}
const useGetSimulatedUserData = (name: string, mobile: string, isFetch:boolean) => {

  const getUserUrl = '/mock-investment/user'
  
  const simulatedCurrentData = useQuery<
    ReturnType<CurrentDataType>,
    AxiosError,
    CurrentDataType
  >({
    queryKey: ['participationInquiryGetUser', name, mobile],
    queryFn: () => getData(`${getUserUrl}?name=${name}&mobile=${mobile}`),
    select: ({ data }) => data,
    retry: false,
    enabled: isFetch, 

  })
  return {
    simulatedCurrentData,
  }
}

export default useGetSimulatedUserData
