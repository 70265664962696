import { isAndroid } from 'react-device-detect'
import {
  ANDROID_STORE_ADDRESS,
  APPLE_STORE_ADDRESS,
} from '@constants/storeAddress'

const NavigateStore = () => {
  window.location.href = isAndroid ? ANDROID_STORE_ADDRESS : APPLE_STORE_ADDRESS
}
export default NavigateStore
