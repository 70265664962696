import { css } from "styled-components";

export const BUTTON_STYLE_RESET = css`
  background: none;
  border: none;
  outline: 0;
  color: inherit;
`;

export const MIN_WIDTH = css`
  min-width: 320px;
`;

export const SAFE_AREA_BOTTOM = css`
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;
