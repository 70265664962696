/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import 'react-spring-bottom-sheet/dist/style.css'
import styled, { css } from 'styled-components'
import {
  ScheduleType,
  filterScheduleInitialState,
  hideCompletedFilterInitialState,
  setFilter,
  setSelectedDate,
  setSelectedMonth,
  setSelectedYear,
  typeFilterInitialState,
} from '../store/modules/base'
import { baseSelector } from '../store/selectors/base'
import { Colors } from '@styles/colors'
import { getNow } from '../utils/dateHelper'
import Icon, { IconType } from './base/Icon'
import Spacer from './base/Spacer'
import { IpoStockGubun } from '@enums/StockEnum'

const SHOW_MONTH_CHUNKED_COUNT = 4

const dateModalStyle = {
  content: {
    top: '210px',
    left: 'calc(50% - 565px)',
    border: 0,
    width: '360px',
    height: '350px',
    borderRadius: '20px',
    backgroundColor: Colors.gf,
    //boxshadow
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
    padding: '34px',
    zIndex: 100,
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
}

const filterModalStyle = {
  content: {
    top: '186px',
    left: 'calc(50% + 150px)',
    border: 0,
    width: '360px',
    height: '384px',
    borderRadius: '20px',
    backgroundColor: Colors.gf,
    //boxshadow
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
    padding: '0px',
    zIndex: 100,
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
}

const filterListModalStyle = {
  content: {
    top: '150px',
    left: 'calc(50% + 150px)',
    border: 0,
    width: '360px',
    height: '240px',
    borderRadius: '20px',
    backgroundColor: Colors.gf,
    //boxshadow
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
    padding: '0px',
    zIndex: 100,
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
}

const Styled = {
  Wrapper: styled.div`
    padding: 0 45px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  `,
  Left: styled.div`
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  `,
  Date: styled.div`
    display: inline-flex;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  `,
  Right: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  YearLabelWrapper: styled.div`
    display: flex;
  `,
  Label: styled.div<{ active?: boolean; fontSize?: number }>`
    cursor: pointer;
    display: inline-flex;

    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: 'SpoqaHanSansNeoRegular', sans-serif;
    color: ${Colors.g9};
    padding: 7px 13px;

    background-color: ${Colors.background_sub};
    border-radius: 50px;
    margin-right: 8px;
    ${({ fontSize }) =>
      fontSize &&
      css`
        font-size: ${fontSize}px;
      `}
    ${({ active }) =>
      active &&
      css`
        color: white;
        background-color: ${Colors.secondary};
      `};
  `,
  MonthWrapper: styled.div`
    margin-top: 32px;
    margin-bottom: 36px;
  `,
  MonthRow: styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  `,
  Month: styled.div`
    font-family: 'SpoqaHanSansNeoRegular', sans-serif;
    display: inline-flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    flex-basis: calc(100% / ${SHOW_MONTH_CHUNKED_COUNT});
    text-align: center;
    margin: 8px;
    cursor: pointer;
  `,
  Item: styled.div<{
    active?: boolean
    disabled?: boolean
  }>`
    color: ${Colors.g12};
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    ${({ active }) =>
      active &&
      css`
        color: white;
        background-color: ${Colors.secondary};
      `}
  `,
  Filter: styled.div``,
  SmallTitle: styled.div`
    font-size: 11.5px;
    color: ${Colors.ga};
    margin-bottom: 8px;
    font-family: 'SpoqaHanSansNeoRegular', sans-serif;
  `,
  BottomArea: styled.div`
    display: flex;

    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%;
    justify-content: start;
    align-items: center;
  `,
  Button: styled.div`
    background-color: ${Colors.primary_ios};
    width: 173px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: ${Colors.g12};
    border-radius: 12px;
    font-family: 'SpoqaHanSansNeoBold', sans-serif;
    cursor: pointer;
  `,
  ResetButton: styled.div`
    margin-top: 12px;
    color: ${Colors.secondary};
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SpoqaHanSansNeoBold', sans-serif;
    width: 140px;
    height: 48px;
    cursor: pointer;
  `,
  FilterWrapper: styled.div`
    padding: 34px;
  `,
  Clickable: styled.div`
    cursor: pointer;
  `,

  DateModalContainer: styled.div``,
}

const {
  Wrapper,
  Left,
  Right,
  Date,
  YearLabelWrapper,
  Label,
  MonthWrapper,
  MonthRow,
  Month,
  Item,
  Filter,
  SmallTitle,
  BottomArea,
  Button,
  ResetButton,
  FilterWrapper,
  Clickable,
} = Styled

const SHOW_PREVIOUS_YEAR_COUNT = 3
const SHOW_MONTH_COUNT = 12

interface CalendarHeaderProps {
  setDate: (date: string) => void
  setIsShowCalendarView: (isShowCalendarView: boolean) => void
  isShowCalendarView: boolean
}
function CalendarHeader({
  setDate,
  setIsShowCalendarView,
  isShowCalendarView,
}: CalendarHeaderProps) {
  const dispatch = useDispatch()
  const [openDateModal, setOpenDateModal] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const { selectedYear, selectedMonth, selectedFilter } =
    useSelector(baseSelector)

  const chunkedYears = useMemo(
    () =>
      _.chunk(
        Array.from({ length: SHOW_MONTH_COUNT }),
        SHOW_MONTH_CHUNKED_COUNT
      ),
    []
  )

  const [scheduleFilter, setScheduleFilter] = useState(selectedFilter.schedule)
  const [typeFilter, setTypeFilter] = useState(selectedFilter.type)
  const [hideCompletedFilter, setHideCompletedFilter] = useState(
    selectedFilter.hideCompleted
  )

  const getYearByIndex = useCallback((index: number) => {
    if (getNow().month() > 10) {
      return getNow().add(1, 'year').subtract(index, 'year').year()
    } else {
      return getNow().subtract(index, 'year').year()
    }
  }, [])

  const [tempYear, setTempYear] = useState(selectedYear ?? getNow().year())

  const handleClickMonth = useCallback(
    (yearsIndex: number, yearIndex: number) => {
      if (!selectedYear) {
        return
      }
      const month = SHOW_MONTH_CHUNKED_COUNT * yearsIndex + yearIndex
      dispatch(setSelectedMonth(month))
      const date = dayjs()
        .set('year', tempYear)
        .set('month', month)
        .set('date', 1)
        .format('YYYY-MM-DD')
      setDate(date)
      setOpenDateModal(false)
      dispatch(setSelectedYear(tempYear))
      dispatch(setSelectedDate(date))
    },
    [setDate, tempYear]
  )
  const handleYear = useCallback(
    (index: any) => {
      if (selectedMonth === null) {
        return
      }
      const year = getYearByIndex(index)
      setTempYear(year)
    },
    [setDate, selectedMonth]
  )
  const handleScheduleFilter = useCallback(
    (type: ScheduleType) => {
      if (scheduleFilter.includes(type)) {
        setScheduleFilter(scheduleFilter.filter((item) => item !== type))
      } else {
        setScheduleFilter([...scheduleFilter, type])
      }
    },
    [scheduleFilter]
  )
  const handleTypeFilter = useCallback(
    (type: keyof typeof IpoStockGubun) => {
      if (typeFilter.includes(type)) {
        setTypeFilter(typeFilter.filter((item) => item !== type))
      } else {
        setTypeFilter([...typeFilter, type])
      }
    },
    [typeFilter]
  )
  const handleApplyFilter = useCallback(() => {
    if (selectedYear === null || selectedMonth === null) {
      return
    }
    dispatch(
      setFilter({
        schedule: scheduleFilter,
        type: typeFilter,
        hideCompleted: hideCompletedFilter,
      })
    )
    const date = dayjs()
      .set('year', selectedYear)
      .set('month', selectedMonth)
      .set('date', 1)
      .format('YYYY-MM-DD')
    setDate(date)
    setOpenFilterModal(false)
  }, [
    selectedYear,
    selectedMonth,
    scheduleFilter,
    typeFilter,
    hideCompletedFilter,
    setDate,
  ])
  const handleClearFilter = useCallback(() => {
    setScheduleFilter(filterScheduleInitialState)
    setTypeFilter(typeFilterInitialState)
    setHideCompletedFilter(hideCompletedFilterInitialState)
  }, [])

  useEffect(() => {
    if (selectedYear) {
      setTempYear(selectedYear)
    }
  }, [selectedYear])
  return (
    <Wrapper>
      <Left>
        <Date onClick={() => setOpenDateModal(true)}>
          {dayjs()
            .set('year', selectedYear ?? getNow().year())
            .set('month', selectedMonth ?? getNow().month())
            .format('YYYY년 MM월')}
          <Spacer width={10} />
          <Icon
            type={IconType.ARROW_BOTTOM}
            size={30}
            rotate={openDateModal ? 180 : 0}
          />
        </Date>
      </Left>
      <Right>
        <Clickable>
          <Icon
            type={IconType.FILTER}
            size={38}
            onClick={() => setOpenFilterModal(true)}
          />
        </Clickable>

        <Spacer width={50} />
        {isShowCalendarView ? (
          <Clickable>
            <Icon
              type={IconType.SHOW_LIST_VIEW}
              size={38}
              onClick={() => setIsShowCalendarView(false)}
            />
          </Clickable>
        ) : (
          <Clickable>
            <Icon
              type={IconType.SHOW_CALENDAR_VIEW}
              size={38}
              onClick={() => setIsShowCalendarView(true)}
            />
          </Clickable>
        )}
      </Right>

      <Modal
        isOpen={openDateModal}
        onRequestClose={() => setOpenDateModal(false)}
        style={dateModalStyle}
        ariaHideApp={false}
      >
        <YearLabelWrapper>
          {Array.from({ length: SHOW_PREVIOUS_YEAR_COUNT }).map(
            (item, itemIndex) => (
              <Label
                key={itemIndex}
                active={tempYear === getYearByIndex(itemIndex)}
                onClick={() => handleYear(itemIndex)}
              >
                {getYearByIndex(itemIndex)}년
              </Label>
            )
          )}
        </YearLabelWrapper>

        <MonthWrapper>
          {chunkedYears.map((years, yearsIndex) => (
            <MonthRow key={`chunked-years-${yearsIndex}`}>
              {years.map((year, yearIndex) => (
                <Month
                  key={yearIndex}
                  onClick={() => handleClickMonth(yearsIndex, yearIndex)}
                >
                  <Item
                    active={
                      selectedMonth !== null
                        ? SHOW_MONTH_CHUNKED_COUNT * yearsIndex +
                            (yearIndex + 1) ===
                          selectedMonth + 1
                        : false
                    }
                  >
                    {SHOW_MONTH_CHUNKED_COUNT * yearsIndex + (yearIndex + 1)}월
                  </Item>
                </Month>
              ))}
            </MonthRow>
          ))}
        </MonthWrapper>
      </Modal>

      <Modal
        isOpen={openFilterModal}
        onRequestClose={() => setOpenFilterModal(false)}
        style={isShowCalendarView ? filterModalStyle : filterListModalStyle}
        ariaHideApp={false}
      >
        <FilterWrapper>
          {isShowCalendarView ? (
            <Filter>
              <SmallTitle>공모주 일정</SmallTitle>
              <Label
                active={scheduleFilter.includes(ScheduleType.EX)}
                onClick={() => handleScheduleFilter(ScheduleType.EX)}
                fontSize={14}
              >
                수요예측일
              </Label>

              <Label
                active={scheduleFilter.includes(ScheduleType.OFFERING)}
                onClick={() => handleScheduleFilter(ScheduleType.OFFERING)}
                fontSize={14}
              >
                청약일
              </Label>
              <Label
                active={scheduleFilter.includes(ScheduleType.LISTING)}
                onClick={() => handleScheduleFilter(ScheduleType.LISTING)}
                fontSize={14}
              >
                상장일
              </Label>
            </Filter>
          ) : null}
          <Spacer height={22} />
          <Filter>
            <SmallTitle>공모주 종류</SmallTitle>
            {Object.keys(IpoStockGubun).map((value, index) => {
              return (
                <Label
                  key={index}
                  active={typeFilter.includes(value)}
                  onClick={() =>
                    handleTypeFilter(value as keyof typeof IpoStockGubun)
                  }
                  fontSize={14}
                >
                  {IpoStockGubun[value as keyof typeof IpoStockGubun]}
                </Label>
              )
            })}
          </Filter>
          <Spacer height={22} />
          {isShowCalendarView ? (
            <Filter>
              <SmallTitle>상장완료 종목 숨기기</SmallTitle>
              <Label
                onClick={() => setHideCompletedFilter(true)}
                fontSize={14}
                active={hideCompletedFilter}
              >
                Yes
              </Label>
              <Label
                onClick={() => setHideCompletedFilter(false)}
                fontSize={14}
                active={!hideCompletedFilter}
              >
                No
              </Label>
            </Filter>
          ) : null}
          <BottomArea>
            <ResetButton onClick={handleClearFilter}>초기화</ResetButton>
            <Spacer width={12} />
            <Button onClick={handleApplyFilter}>적용하기</Button>
          </BottomArea>
        </FilterWrapper>
      </Modal>
    </Wrapper>
  )
}

export default CalendarHeader
