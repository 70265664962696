import { useAnimation, useInView } from 'framer-motion'
import Spacer from '../../base/Spacer'
import { SpoqaLight, SpoqaMedium } from '../../base/TextStyle/Spoqa'
import { Container, ImageBubble } from './styles'
import { useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

const SectionFive = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  return (
    <Container>
      <ImageBubble
        src={
          isTablet
            ? 'images/main/section-five/text_bubble_mobile.png'
            : 'images/main/section-five/text_bubble_web.png'
        }
        alt='text_bubble_web'
      />
    </Container>
  )
}

export default SectionFive
