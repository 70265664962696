/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from "react";
import { Route as Router, Redirect } from "react-router-dom";
import { RouterItemType, RouterItemParam } from "./routerItem";

function RouterInterceptor(routeProps: RouterItemParam): ReactElement | null {
  const { component: Component, type, path, to, exact, name } = routeProps;

  if (type === RouterItemType.REDIRECT) {
    return <Redirect from={path} to={to ?? "*"} />;
  }

  return (
    <Router
      path={path}
      exact={exact}
      render={(props) => {
        if (!Component) return null;
        return <Component {...props} {...routeProps} />;
      }}
    />
  );
}

export default RouterInterceptor;
