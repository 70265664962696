import { ReactElement, useEffect } from 'react'
import NavigateStore from '../../utils/navigateStore';
import { Helmet } from 'react-helmet-async'

const Index = (): ReactElement => {
    const handleDeepLink = () => {
        // const url = 'i6o://benefits'; 
        const url = "i6o://benefits-event?url=https://160ipo.com/event-connect?fromApp=true"; 
        const timeout = setTimeout(() => {
          NavigateStore();
        }, 2000); 
    
        window.location.href = url;
    
        window.addEventListener('pagehide', () => {
          clearTimeout(timeout);
        });
      };
    
      useEffect(() => {
        handleDeepLink();
      }, []);
    return (
        <>
        <Helmet>
        <meta
          property={'title'}
          content={'일육공 160 - 공모주 투자의 확률을 높이다'}
        />
        <meta
          property={'description'}
          content={
            '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.'
          }
        />
        <meta
          property={'og:title'}
          content={'일육공 160 - 공모주 투자의 확률을 높이다'}
        />
        <meta
          property={'og:description'}
          content={
            '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.'
          }
        />
      </Helmet>
      <></>
      </>
    )
}

export default Index;