import styled, { css } from 'styled-components'
import { Colors } from '@styles/colors' // 평일 갯수

const DAY_OF_WEEKDAY_COUNT = 5

const WRAPPER_MARGIN = 12

export const CalendarStyled = {
  Wrap: styled.div`
    margin: 0 ${WRAPPER_MARGIN}px;
  `,
  DateWrap: styled.div`
    margin-top: 36px;

    &:first-child {
      margin-top: 0;
    }
  `,
  ScheduleWrapper: styled.div`
    position: relative;
  `,
  EmptySchedule: styled.div``,
  Schedule: styled.div<{ color: string; margin?: boolean }>`
    display: flex;
    height: 27px;
    font-size: 16px;
    margin-top: 2px;
    padding: 3px 2px;
    position: relative;
    justify-content: center;
    align-items: center;
    font-family: 'SpoqaHanSansNeoRegular', sans-serif;

    ${({ margin }) =>
      margin &&
      css`
        margin-left: 2px;
      `};
    ${({ color }) => {
      if (color === 'transparent') {
        return css`
          color: transparent;
          background-color: transparent;
        `
      } else {
        return css`
          background-color: ${color};
        `
      }
    }}
  `,
  Title: styled.div<{ width: number; block?: number }>`
    ${(props) => {
      return (
        props.block &&
        css`
          position: absolute;
          left: 0;
          width: calc((${props.width}px * ${props.block + 1}));
          text-align: center;
          z-index: 1;
        `
      )
    }}
  `,
  YearAndMonth: styled.div`
    display: flex;
    background-color: ${Colors.ge};
    margin-top: 24px;
  `,
  DayHeader: styled.div<{ hide?: boolean }>`
    font-size: 24px;
    flex-basis: calc(100% / ${DAY_OF_WEEKDAY_COUNT});
    text-align: center;
    padding: 20px 0;
    color: ${Colors.g5};
    font-weight: 700;

    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
  `,
  DaysRow: styled.div`
    display: flex;
    flex-grow: 1;
  `,
  EmptyDay: styled.div<{ hide?: boolean }>`
    display: flex;
    flex-basis: calc(100% / ${DAY_OF_WEEKDAY_COUNT});
    padding: 20px 0;
    justify-content: center;
    align-items: center;

    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
  `,
  Day: styled.div<{ hide?: boolean }>`
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-basis: calc(100% / ${DAY_OF_WEEKDAY_COUNT});
    font-size: 20px;
    color: ${Colors.g12};
    position: relative;

    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
  `,
  Label: styled.div`
    position: absolute;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    bottom: -16px;
    text-align: center;
  `,
  DayItem: styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 15px 0 10px 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  `,
  Item: styled.div<{ isSelected?: boolean; today?: boolean }>`
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    ${({ today }) =>
      today &&
      css`
        background-color: ${Colors.gE2};
      `};
    ${({ isSelected }) =>
      isSelected &&
      css`
        color: white;
        font-weight: 700;
        background-color: ${Colors.secondary};
      `}
  `,
}
