import { Colors } from '@styles/colors'
import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 102px 0 102px;
  background: linear-gradient(
    180deg,
    rgba(250, 250, 250, 0) 0%,
    rgba(252, 203, 51, 0.1) 100%
  );

  @media (max-width: 767px) {
    padding: 50px 0 50px;
  }
`

export const ImageBubble = styled.img`
  width: 792px;
  height: 468px;
  object-fit: contain;
  @media (max-width: 767px) {
    width: 80%;
  }
`
