import { Colors } from '@styles/colors'
import Spacer from '../../base/Spacer'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '../../base/TextStyle/Spoqa'
import {
  Container,
  ContentWrapper,
  ImageBox,
  ImageWrapper,
  PaddingWrapper,
  RightContentWrapper,
  TextWrapper,
  ImageMobile,
  BoldText,
} from './styles'
import { useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

const SectionEight = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  const isTabletL = useMediaQuery({ query: '(max-width: 1430px)' })
  return (
    <Container>
      {isTabletL ? (
        <ContentWrapper>
          <PaddingWrapper>
            <BoldText
              size={isMobile ? 14 : 24}
              color={Colors.primary_ios}
              weight={700}
            >
              일정 • 종목 캘린더
            </BoldText>
            <Spacer height={16} />
            <SpoqaBold
              size={isMobile ? 22 : 48}
              color={Colors.g12}
              lineHeight={isMobile ? 28 : 60}
            >
              공모주 일정관리,
              <br />
              일육공 캘린더로 한눈에
            </SpoqaBold>
            <Spacer height={32} />
            <SpoqaRegular
              size={isMobile ? 13 : 19}
              color={Colors.g12}
              lineHeight={isMobile ? 18.2 : 30}
            >
              종목별 수요예측일, 청약일, 상장일을 캘린더로 <br />
              표시하여 한 눈에 확인 가능하도록 구성했어요. <br />
              성공적인 공모주 투자의 시작은 일정 체크이니까요! <br />
            </SpoqaRegular>
          </PaddingWrapper>
          <ImageWrapper>
            <ImageMobile
              src={'images/main/section-eight/mobile.png'}
              alt='mobile'
            />
          </ImageWrapper>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <RightContentWrapper>
            <TextWrapper>
              <SpoqaBold size={24} color={Colors.primary_ios}>
                일정·종목 캘린더
              </SpoqaBold>
              <Spacer height={16} />
              <SpoqaBold size={48} color={Colors.g12} lineHeight={62.4}>
                공모주 일정관리,
                <br /> 일육공 캘린더로 한눈에
              </SpoqaBold>
              <Spacer height={40} />
              <SpoqaRegular size={22} lineHeight={30.8}>
                종목별 수요예측일, 청약일, 상장일을 캘린더로
                <br />
                표시하여 한 눈에 확인 가능하도록 구성했어요.
                <br />
                성공적인 공모주 투자의 시작은 일정 체크이니까요!
              </SpoqaRegular>
            </TextWrapper>
            <ImageWrapper>
              <ImageBox
                src={'/images/main/section-eight/phone_1.png'}
                alt='phone1'
              />
              <ImageBox
                src={'/images/main/section-eight/phone_2.png'}
                alt='phone2'
              />
              <ImageBox
                src={'/images/main/section-eight/phone_3.png'}
                alt='phone3'
              />
            </ImageWrapper>
          </RightContentWrapper>
        </ContentWrapper>
      )}
    </Container>
  )
}

export default SectionEight
