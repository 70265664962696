import React from 'react'
import { SpoqaLight, SpoqaRegular } from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'

const Section = styled.section`
  padding: 0;

  @media screen and (max-width: ${Desktop}px) {
    padding: 0 24px;
  }
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  align-items: center;
  text-align: center;
  padding: 32px 0;
  height: 228px;
  border: 1px solid ${Colors.ge};

  @media screen and (max-width: ${Desktop}px) {
    padding: 24px 0;
    height: 162px;
  }
`
const MainContents = styled(SpoqaRegular)`
  word-break: auto-phrase;
  color: ${Colors.g7};
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 22px;
  letter-spacing: -0.33px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 12px;
    margin-bottom: 4px;
    line-height: 16px;
  }
`
const List = styled.ul`
  display: flex;
  position: relative;
  margin-top: 24px;
  gap: 8px;
  width: 216px;
  @media screen and (max-width: ${Desktop}px) {
    margin-top: 16px;
    width: 156px;
  }
`
const Item = styled.li<{ index: number }>`
  background: lightgray;
  position: absolute;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  left: ${(props) => props.index * 48}px;
  z-index: ${(props) => 4 - props.index};
  @media screen and (max-width: ${Desktop}px) {
    width: 48px;
    height: 48px;
    left: ${(props) => props.index * 36}px;
  }
  .logo {
    width: 66px;
    height: 66px;
    @media screen and (max-width: ${Desktop}px) {
      width: 48px;
      height: 48px;
    }
  }
`
const PlusItem = styled(Item)`
  background: ${Colors.gf};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border: 1px solid ${Colors.ge};
  width: 66px;
  height: 66px;
  @media screen and (max-width: ${Desktop}px) {
    width: 48px;
    height: 48px;
  }
  > img {
    width: 33px;
    height: 33px;
    @media screen and (max-width: ${Desktop}px) {
      width: 24px;
      height: 24px;
    }
  }
`

const Caption = styled(SpoqaLight)`
  font-size: 18px;
  color: ${Colors.ga};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 12px;
  }
`
const Securities = [
  {
    imgUrl: '/images/event/simulated_samsung.png',
    name: '삼성증권',
  },
  {
    imgUrl: '/images/event/simulated_hanto.png',
    name: '한국투자증권',
  },
  {
    imgUrl: '/images/event/simulated_kb.png',
    name: 'KB증권',
  },
]
const CompanyListSection = () => {
  return (
    <Section>
      <Box>
        <MainContents>
          이 시뮬레이션은 일육공 간편투자가 가능한
          <br /> 아래 3개 증권사의 투자 결과입니다
        </MainContents>
        <Caption>증권사는 변동될 수 있습니다</Caption>
        <List>
          {Securities.map(({ imgUrl, name }, index) => (
            <Item key={name} index={index}>
              <img className={'logo'} src={imgUrl} alt={name} />
            </Item>
          ))}
          <PlusItem index={Securities.length}>
            <img
              src={'/icons/gray_plus.png'}
              width={24}
              height={24}
              alt={'coming_soon'}
            />
          </PlusItem>
        </List>
      </Box>
    </Section>
  )
}

export default CompanyListSection
