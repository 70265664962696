import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import { useAppSelector } from '../store/selectors/utils'
import { Helmet } from 'react-helmet-async'
import Footer from '@components/layout/Footer'
import MobileHeader from '@components/layout/MobileHeader'
import WebHeader from '@components/layout/WebHeader'
import AppHeader from '@components/layout/AppHeader'
import usePostMessage from '@hooks/usePostMessage'
import { useHistory } from 'react-router-dom'

interface Props {
  children: React.ReactNode
  isFirst?: boolean
  hasFooter?: boolean
  hasBack?: boolean
  hasClose?: boolean
  paddingBottom?: number
  hasHeaderUnderLine?: boolean
  hasShareInHeader?: boolean
}

const Wrapper = styled.main<{ headerHeight: number; bottomHeight: number }>`
  padding-top: ${(props) => props.headerHeight}px;
  padding-bottom: ${(props) => props.bottomHeight}px;
`

const SimulatedLayout = ({
  children,
  isFirst,
  hasBack = true,
  hasClose = true,
  hasFooter = true,
  paddingBottom = 98,
  hasHeaderUnderLine = true,
  hasShareInHeader = false,
}: Props) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const { postMessageHandler } = usePostMessage()
  const history = useHistory()

  const handleWebviewClose = () => {
    postMessageHandler({
      action: 'close',
    })
  }
  const handleBackButton = () => {
    history.goBack()
  }

  return (
    <Wrapper headerHeight={isFromApp ? 48 : 78} bottomHeight={paddingBottom}>
      <Helmet>
        <meta
          property={'title'}
          content={'일육공 160 - 공모주 투자의 확률을 높이다'}
        />
        <meta
          property={'description'}
          content={
            '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.'
          }
        />
        <meta
          property={'og:title'}
          content={'일육공 160 - 공모주 투자의 확률을 높이다'}
        />
        <meta
          property={'og:description'}
          content={
            '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.'
          }
        />
        <meta property={'og:image'} content={'/simulate-og.png'} />
        <meta
          property={'og:url'}
          content={'https://160ipo.com/simulated-event'}
        />
      </Helmet>

      {isMobile ? (
        <>
          {isFromApp ? (
            <AppHeader
              hasShareInHeader={hasShareInHeader}
              handleWebviewClose={handleWebviewClose}
              handleBackButton={handleBackButton}
              isFirst={isFirst}
              hasBack={hasBack}
              hasClose={hasClose}
            />
          ) : (
            <MobileHeader hasHeaderUnderLine={hasHeaderUnderLine} />
          )}
        </>
      ) : (
        <WebHeader hasHeaderUnderLine={hasHeaderUnderLine} />
      )}
      {children}
      {hasFooter && !isFromApp ? <Footer isFirst={isFirst} /> : null}
    </Wrapper>
  )
}

export default SimulatedLayout
