import { useAnimation, useInView } from 'framer-motion'
import Spacer from '../../base/Spacer'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '../../base/TextStyle/Spoqa'
import {
  CardImage,
  CardItem,
  CardText,
  CardWrapper,
  Container,
  ContentWrapper,
  TextWrapper,
} from './styles'
import { Colors } from '@styles/colors'
import { useMediaQuery } from 'react-responsive'
import { BoldText, PaddingWrapper } from '../SectionSeven/styles'

const SectionTwelve = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  const isTabletL = useMediaQuery({ query: '(max-width: 1430px)' })
  return (
    <Container>
      <ContentWrapper>
        {isTabletL ? (
          <>
            <Spacer height={120} />
            <PaddingWrapper>
              <BoldText
                size={isMobile ? 14 : 24}
                color={Colors.primary_ios}
                weight={700}
              >
                종목알림·개인화 기능
              </BoldText>
              <Spacer height={16} />
              <SpoqaBold
                size={isMobile ? 22 : 40}
                color={Colors.g12}
                lineHeight={isMobile ? 28 : 60}
              >
                나에게 꼭 맞는 알림 받고 <br /> 차곡차곡 투자기록 쌓기
              </SpoqaBold>
            </PaddingWrapper>
            <Spacer height={52} />
          </>
        ) : (
          <>
            <TextWrapper>
              <SpoqaBold size={24} color={Colors.primary_ios}>
                종목알림·개인화 기능
              </SpoqaBold>
              <Spacer height={16} />
              <SpoqaBold size={48} color={Colors.g12} lineHeight={62.4}>
                나에게 꼭 맞는 알림받고
                <br />
                차곡차곡 투자기록 쌓기
              </SpoqaBold>
            </TextWrapper>
            <Spacer height={114} />
          </>
        )}

        <CardWrapper>
          <CardItem>
            <CardText>
              <SpoqaBold
                size={isMobile ? 18 : isTablet ? 24 : 40}
                color={Colors.g12}
              >
                맞춤알림설정
              </SpoqaBold>
              <Spacer height={40} />
              <SpoqaRegular
                size={isMobile ? 13 : isTablet ? 18 : 22}
                color={Colors.g12}
                lineHeight={isMobile ? 18.2 : isTablet ? 24 : 30.8}
              >
                좋은 종목의 청약을 못하거나 상장일을 놓쳐
                <br />
                매매하지 못하셨던 아까운 경험이 있으신가요?
                <br />
                종목별 알림 설정으로 청약 일정, 놓치지 말아요!
              </SpoqaRegular>
            </CardText>
            <CardImage
              src={'/images/main/section-twelve/alarm.png'}
              alt='alarm'
            />
          </CardItem>
          <Spacer height={60} />
          <CardItem>
            {!isTablet && (
              <CardImage
                src={'/images/main/section-twelve/calculator.png'}
                alt='calculator'
              />
            )}
            <CardText>
              <SpoqaBold
                size={isMobile ? 18 : isTablet ? 24 : 40}
                color={Colors.g12}
              >
                공모주 배정주식 계산
              </SpoqaBold>
              <Spacer height={40} />
              {!isTablet ? (
                <SpoqaRegular
                  size={isMobile ? 13 : isTablet ? 18 : 22}
                  color={Colors.g12}
                  lineHeight={isMobile ? 18.2 : isTablet ? 24 : 30.8}
                >
                  내가 가진 투자금으로는 몇 주를 배정받을 수 있을지
                  <br />
                  궁금하시죠? 배정주식계산기로 균등배정 및<br />
                  비례배정까지 모두! 간편하게 계산해보세요.
                </SpoqaRegular>
              ) : (
                <SpoqaRegular
                  size={isMobile ? 13 : isTablet ? 18 : 22}
                  color={Colors.g12}
                  lineHeight={isMobile ? 18.2 : isTablet ? 24 : 30.8}
                >
                  내가 가진 투자금으로는 몇 주를 배정받을 수 <br />
                  있을지 궁금하시죠? 배정주식계산기로 균등배정 <br /> 및
                  비례배정까지 모두! 간편하게 계산해보세요.
                </SpoqaRegular>
              )}
            </CardText>
            {isTablet && (
              <CardImage
                src={'/images/main/section-twelve/calculator.png'}
                alt='calculator'
              />
            )}
          </CardItem>
          <Spacer height={60} />
          <CardItem>
            <CardText>
              <SpoqaBold
                size={isMobile ? 18 : isTablet ? 24 : 40}
                color={Colors.g12}
              >
                나만의 투자노트 기록
              </SpoqaBold>
              <Spacer height={40} />
              {!isTablet ? (
                <SpoqaRegular
                  size={isMobile ? 13 : isTablet ? 18 : 22}
                  color={Colors.g12}
                  lineHeight={isMobile ? 18.2 : isTablet ? 24 : 30.8}
                >
                  투자의 마무리는 기록! 앞으로의 꾸준한 성공투자를 위해
                  <br />
                  어떤 때 수익이 나고 어떤 때 손실이 났는지 기록해보세요.
                  <br />
                  월별로 나의 투자 기록을 한눈에 트랙킹 할 수 있어요!
                </SpoqaRegular>
              ) : (
                <SpoqaRegular
                  size={isMobile ? 13 : isTablet ? 18 : 22}
                  color={Colors.g12}
                  lineHeight={isMobile ? 18.2 : isTablet ? 24 : 30.8}
                >
                  투자의 마무리는 기록! <br /> 앞으로의 꾸준한 성공투자를 위해
                  어떤 때 수익이 <br />
                  나고 어떤 때 손실이 났는지 기록해보세요. 월별로 <br /> 나의
                  투자 기록을 한눈에 트랙킹 할 수 있어요!
                </SpoqaRegular>
              )}
            </CardText>
            <CardImage
              src={'/images/main/section-twelve/note.png'}
              alt='note'
            />
          </CardItem>
        </CardWrapper>
      </ContentWrapper>
    </Container>
  )
}

export default SectionTwelve
