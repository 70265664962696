import { useState, useEffect } from 'react';

const useGetBottomInset = () => {
  const [bottomInset, setBottomInset] = useState(0);

  useEffect(() => {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    const updateBottomInset = () => {
      const inset = window.innerHeight - document.documentElement.clientHeight;
      setBottomInset(inset);
    };

    if (isChrome) {
      window.addEventListener('resize', updateBottomInset);
      updateBottomInset();

      return () => {
        window.removeEventListener('resize', updateBottomInset);
      };
    }
  }, []);

  return bottomInset;
};

export default useGetBottomInset;