import React from 'react'
import Header from './header'
import AccordionMenu from './accordionMenu'

const GuidePage = () => {
  return (
    <>
      {/* <Header /> */}
      <AccordionMenu />
    </>
  )
}

export default GuidePage
