import React from 'react'
import styled from 'styled-components'
import MobileDownload from '@components/NewMain/Mobile/MobileDownload'

const Index = () => {
  // const [deviceType, setDeviceType] = useState('Unknown Device')
  // const detectDevice = () => {
  //   const userAgent =
  //     navigator.userAgent || navigator.vendor || (window as any).opera
  //   const platform = navigator.platform
  //
  //   if (/android/i.test(userAgent)) {
  //     return 'Android'
  //   } else if (
  //     /iPad|iPhone|iPod/.test(userAgent) &&
  //     !(window as any).MSStream
  //   ) {
  //     return 'iOS'
  //   } else if (platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
  //     return 'iOS'
  //   } else {
  //     return 'Other'
  //   }
  // }

  // const redirectToPlatformLink = () => {
  //   const device = detectDevice()
  //   setDeviceType(device)
  //
  //   if (device === 'Android') {
  //     try {
  //       window.open(
  //         'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
  //       )
  //     } catch (error) {
  //       window.location.href =
  //         'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
  //     }
  //   } else if (device === 'iOS') {
  //     // try {
  //     //   window.webkit.messageHandlers.openDeepLink.postMessage('https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484');
  //     // } catch (error) {
  //     //   window.location.href = 'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484';
  //     // }
  //     window.location.href =
  //       'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
  //   } else {
  //     try {
  //       window.open('https://qr-efbc6.web.app/')
  //     } catch (error) {
  //       window.location.href = 'https://qr-efbc6.web.app/'
  //     }
  //   }
  // }

  return (
    <Container>
      <FooterInfo>
        <LeftContainer>
          <BusinessInfo>
            <Top>
              <Name>
                엠엘투자자문(주)대표
                <img src={'/img/main/footer_line.svg'} alt='' />
                윤도선
              </Name>
              <Number>사업자등록번호 : 341-88-02703 </Number>
            </Top>
            <Address>서울특별시 강남구 역삼로17길 10 부강빌딩 3층</Address>
            <CopyRight>
              ⓒ ML Investment Advisory Co.,Ltd. All Rights Reserved.
            </CopyRight>
          </BusinessInfo>
          <Bottom>
            <Navigate
              onClick={() =>
                window.open('https://metalogos.oopy.io/mli/160/privacy_policy')
              }
            >
              개인정보처리방침
            </Navigate>
            <Navigate
              onClick={() =>
                window.open('https://metalogos.oopy.io/mli/160/serviceinfo')
              }
            >
              서비스이용약관
            </Navigate>
          </Bottom>
        </LeftContainer>
      </FooterInfo>
      <MobileDownload />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  min-width: 360px;
  width: 100%;
  margin-bottom: 123px;
`

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: center;
  width: 360px;
`

const Name = styled.div`
  display: flex;
  gap: 8px;
  font-family: Pretendard400, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Number = styled.div``

const BusinessInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 12px;
`

const CopyRight = styled.div`
  color: #808491;
  font-family: Pretendard, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Address = styled.div``
const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Bottom = styled.div`
  display: flex;
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: 24px;
  margin-top: 24px;
`

const Navigate = styled.div`
  color: #000;
  font-family: Pretendard600, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`
export default Index
