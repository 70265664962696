import { useMutation, useQuery } from '@tanstack/react-query'
import { postData } from '../utils/api'
import { AxiosError } from 'axios'

type ReturnType<T> = {
  data: T
}
type CurrentDataType = {
  statusCode: number
  message: string
}

type RequestDataType = {
  name: string | null
  mobile: string | null
  password: string | null
}

const usePostSimulatedData = () => {
  const postGameStartUrl = '/mock-investment/user'
  const postIsExistUrl = '/mock-investment/user/verify'

  const simulatedGameStartData = useMutation<
    ReturnType<CurrentDataType>,
    AxiosError,
    RequestDataType
  >({
    mutationFn: (data) =>
      postData<ReturnType<CurrentDataType>, RequestDataType>(
        postGameStartUrl,
        data
      ),
  })

  const simulatedIsExistData = useMutation<
    ReturnType<CurrentDataType>,
    AxiosError,
    RequestDataType
  >({
    mutationFn: (data) =>
      postData<ReturnType<CurrentDataType>, RequestDataType>(
        postIsExistUrl,
        data
      ),
  })

  return {
    simulatedGameStartData,
    simulatedIsExistData,
  }
}

export default usePostSimulatedData
