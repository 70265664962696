import React from "react";
import styled from "styled-components";
import IconBox from "./IconBox";

const Content01 = () => {
  return (
    <Container>
      <IconBox />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border-radius: 32px;
  width: 437px;
  height: 423px;
  background-color: #f0f4f9;
  overflow: hidden;
`;

export default Content01;
