import React from 'react'
import styled from 'styled-components'
import Rolling from './rolling'

interface ContainerProps {
  newMobileMain: boolean
}

const Index = () => {
  const newMobileMain = window.location.href.includes('mobile-main')

  return (
    <Container newMobileMain={newMobileMain}>
      <Title>{`공모주, \n 쉽고 편리하게`}</Title>
      <ImgContainer>
        <CenterImg src='/img/Center-main.svg' />
        <Rolling />
      </ImgContainer>
    </Container>
  )
}

const Container = styled.div<ContainerProps>`
  height: 485px;
  padding: 96px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 88px;
  position: relative;
  width: 100%;
  margin-top: ${({ newMobileMain }) => (newMobileMain ? '0' : '89px')};
`

const Title = styled.div`
  white-space: pre-line;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  line-height: 100%; /* 40px */
  font-family: Pretendard900, sans-serif;
  font-weight: 900;
`

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const CenterImg = styled.img`
  width: 144px;
  position: absolute;
  z-index: 150;
  bottom: 50px;
`
export default Index
