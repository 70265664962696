import styled from 'styled-components'

interface SpoqaPrpos {
  size?: number
  color?: string
  lineHeight?: number
}

export const SpoqaBold = styled('span')<SpoqaPrpos>`
  font-family: 'SpoqaHanSansNeoBold', sans-serif;
  font-size: ${(props) => props.size + 'px'};
  color: ${(props) => (props.color === null ? '#121212' : props.color)};
  line-height: ${(props) => props.lineHeight + 'px'};
`

export const SpoqaMedium = styled('span')<SpoqaPrpos>`
  font-family: 'SpoqaHanSansNeoMedium', sans-serif;
  font-size: ${(props) => props.size + 'px'};
  color: ${(props) => (props.color === null ? '#121212' : props.color)};
  line-height: ${(props) => props.lineHeight + 'px'};
`

export const SpoqaRegular = styled('span')<SpoqaPrpos>`
  font-family: 'SpoqaHanSansNeoRegular', sans-serif;
  font-size: ${(props) => props.size + 'px'};
  color: ${(props) => (props.color === null ? '#121212' : props.color)};
  line-height: ${(props) => props.lineHeight + 'px'};
  z-index: 1;
`

export const SpoqaLight = styled('span')<SpoqaPrpos>`
  font-family: 'SpoqaHanSansNeoLight', sans-serif;
  font-size: ${(props) => props.size + 'px'};
  color: ${(props) => (props.color === null ? '#121212' : props.color)};
  line-height: ${(props) => props.lineHeight + 'px'};
`
