import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// app에서 webview로 접근 한 경우 isFromApp = true
const initialState: { isFromApp: boolean } = {
  isFromApp: false,
}
const entryPointSlice = createSlice({
  name: 'entryPoint',
  initialState,
  reducers: {
    setEntryPoint(state, action: PayloadAction<boolean>) {
      state.isFromApp = action.payload
    },
  },
})
export const { setEntryPoint } = entryPointSlice.actions
export default entryPointSlice.reducer
