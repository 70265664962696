import React from 'react'
import styled from 'styled-components'

const CountUp = ({ count }: { count: number }) => {
  return (
    <Container>
      <Number>{count}</Number>
    </Container>
  )
}

const Container = styled.div`
  border-radius: 72px;
  border: 2px rgba(201, 0, 0, 0.9) solid;
  background: rgba(255, 0, 0, 0.9);
  position: absolute;
  top: -35px;
  right: 2px;
  width: 80px;
  height: 64px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Number = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
`

export default CountUp
