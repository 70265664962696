import { ReactElement } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/colors";
import Spacer from "../base/Spacer";
import { SpoqaMedium } from "../base/TextStyle/Spoqa";

export const Style = {
  Container: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  `,

  Block: styled.div`
    background-color: ${Colors.yellow_sub};
    width: 100%;
    height: 281px;
    position: absolute;
    top: 0px;
    z-index: 0;
  `,

  BGitem: styled.img`
    position: absolute;
    top: 0;
    right: -40px;
    /* width: 255px; */
    height: 281px;
  `,

  HalfMoon: styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    width: 320px;
  `,

  ContentContainer: styled.div`
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    width: 100%;
  `,

  ImgText: styled.img`
    width: 120px;
    height: 25px;
  `,

  ContentItem: styled.img``,
};

const {
  Container,
  Block,
  BGitem,
  HalfMoon,
  ContentContainer,
  ImgText,
  ContentItem,
} = Style;

const ScreenTwo = (): ReactElement => {
  return (
    <Container>
      <Block>
        <HalfMoon src="/images/infopage/item2.png" />
        <BGitem src="/images/infopage/item1.png" />
      </Block>
      <ContentContainer>
        <SpoqaMedium size={13}>단 하나의 공모주 평가지표,</SpoqaMedium>
        <Spacer height={8} />
        <ImgText src="/images/infopage/item3.png" />
        <ContentItem
          style={{ width: "360px" }}
          src="/images/mobile/score-card.png"
        />
        <ContentItem
          style={{ width: "50.58px" }}
          src="/images/infopage/item5.png"
        />
        <ContentItem
          style={{ width: "360px" }}
          src="/images/mobile/chart.png"
        />
      </ContentContainer>
    </Container>
  );
};

export default ScreenTwo;
