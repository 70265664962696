import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import AOS from 'aos'
import 'aos/dist/aos.css'
import CountUp from './countUp'
import Mobile_Samsung from './mobile_samsung'
import useIntersect from '@hooks/useIntersect'

const Index = () => {
  const timerRef = useRef<NodeJS.Timer>()

  const [count, setCount] = useState<number>(0)

  const countUpHandler = () => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        setCount((prev) => prev + 1)
      }, 90)
    }
  }

  const intersectRef = useIntersect(() => countUpHandler())
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    })
  }, [])
  useEffect(() => {
    if (count >= 12) {
      clearInterval(timerRef.current)
    }
  }, [count])

  return (
    <Container>
      <Title data-aos='fade-down'>
        <CountUp count={count} />
        <div>{`청약부터 매도까지 \n 알아서 다 해드리는`}</div>
        <div ref={intersectRef}>{`일육공 간편투자서비스!`}</div>
        <div>{`잊고 살아요 \n 우리가 챙길게요`}</div>
      </Title>
      <CircleContainer>
        <Mobile_Samsung />
      </CircleContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  min-width: 1280px;
  height: 1745px;
`

const CircleContainer = styled.div`
  display: flex;
  gap: 4.9375rem;
  height: 944px;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  margin-top: 150px;
  border-bottom: 1px solid #dcdeeb;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 72px;
  font-style: normal;
  white-space: pre-line;
  gap: 72px;
  position: relative;
  line-height: normal;
  font-family: Pretendard900, sans-serif;
  font-weight: 900;
  margin-top: 263px;
`

export default Index
