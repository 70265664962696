export * as price from './price'

export function shorteningTitle(title: string, count: number): string {
  if (count >= 1) {
    return title.length >= 12 ? `${title.slice(0, 10)}...` : title
  }
  return title.length >= 7 ? `${title.slice(0, 5)}...` : title
}
export const CopyURL = () => {
  const url = window.location.href
  navigator.clipboard
    .writeText(url)
    .then(() => {
      alert('URL을 복사하였습니다.')
    })
    .catch((err) => {
      console.error('URL 복사 실패:', err)
    })
}
const SHARE_DATA = {
  title: '일육공 160 - 공모주 투자의 확률을 높이다',
  text: '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.',
  url: 'https://160ipo.com/simulated-event',
}
export const NavigateShare = async () => {
  try {
    if (navigator.canShare(SHARE_DATA)) {
      await navigator.share(SHARE_DATA)
    } else {
      // 공유하기 web API error
      CopyURL()
    }
  } catch (error) {
    console.log(error)
  }
}
