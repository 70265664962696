import styled from 'styled-components'
import { Colors } from '../../../styles/colors'

import Modal from 'react-modal'
import Spacer from '../Spacer'
import { SpoqaMedium } from '../TextStyle/Spoqa'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const basicInstallModalStyle = {
  content: {
    border: 0,
    width: '527px',
    height: '697px',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    bottom: 'auto',
    borderRadius: '20px',
    backgroundColor: Colors.gf,
    padding: '0',
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 100,
  },
}

const Styled = {
  ModalContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 56px;
  `,
  ModalImg: styled.img`
    width: 278px;
    height: 314px;
  `,
  DownloadTitleContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 75px;
    padding-right: 87px;
  `,
  DonwloadTitle: styled.div`
    font-size: 18px;
    color: ${Colors.g9};
  `,

  ContentContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 56px;
    padding-right: 87px;
    align-items: center;
  `,

  StoreBtnContainer: styled.div`
    display: flex;
    flex-direction: column;
    height: 126px;
    justify-content: space-between;
  `,
  StoreBtn: styled.div`
    width: 158px;
    height: 48px;
    background-color: #fff7de;
    border-radius: 6.3px;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
  `,

  ContentLogo: styled.img`
    width: 33.47px;
    height: 35.86px;
  `,

  QRcontent: styled.img`
    width: 126px;
    height: 126px;
  `,

  CloseContainer: styled.div`
    border-top: 1.5px solid ${Colors.ge};
    width: 100%;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
}

const {
  ModalContainer,
  ModalImg,
  DownloadTitleContainer,
  ContentContainer,
  StoreBtnContainer,
  StoreBtn,
  ContentLogo,
  QRcontent,
  CloseContainer,
} = Styled

const BasicInstallModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={basicInstallModalStyle}
      >
        <ModalContainer>
          <ModalImg
            src='images/modal/basic_install_modal_img.png'
            alt='basic_install_modal'
          />
          <Spacer height={26} />
          <DownloadTitleContainer>
            <SpoqaMedium size={18} color={Colors.g9}>
              스토어 다운로드
            </SpoqaMedium>
            <SpoqaMedium size={18} color={Colors.g9}>
              QR코드 다운로드
            </SpoqaMedium>
          </DownloadTitleContainer>
          <Spacer height={23} />
          <ContentContainer>
            <StoreBtnContainer>
              <StoreBtn
                onClick={() => {
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
                  )
                }}
              >
                <Spacer width={13.76} />
                <ContentLogo src='images/google-logo-medium.png' />
                <Spacer width={6.38} />
                <SpoqaMedium size={16}>Google Play</SpoqaMedium>
              </StoreBtn>

              <StoreBtn
                onClick={() => {
                  window.open(
                    'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
                  )
                }}
              >
                <Spacer width={13.76} />
                <ContentLogo src='images/apple-logo-medium.png' />
                <Spacer width={6.38} />
                <SpoqaMedium size={16}>Apple Store</SpoqaMedium>
              </StoreBtn>
            </StoreBtnContainer>

            <Spacer width={1} height={23} background={Colors.ga} />
            <QRcontent src='images/modal/QRimg.png' />
          </ContentContainer>
          <Spacer height={56} />
          <CloseContainer
            onClick={() => {
              setIsOpen(false)
            }}
          >
            <SpoqaMedium size={22} color={Colors.g9}>
              닫기
            </SpoqaMedium>
          </CloseContainer>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default BasicInstallModal
