import { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../styles/colors'
import Spacer from '../base/Spacer'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '../base/TextStyle/Spoqa'

export const Styled = {
  Container: styled.div`
    width: 100%;
    height: 1328px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
  `,

  ContentContainer: styled.div`
    display: flex;
  `,

  ContentItem: styled('img')``,

  BgBox: styled.div`
    width: 100%;
    height: 724px;
    background-color: ${Colors.maingrey_bg};
    position: absolute;
    top: 282px;
    z-index: -1;
  `,
  TopContentContainer: styled.div`
    width: 1200px;
    height: 563px;
    position: relative;
    display: flex;
    align-items: center;
  `,
  BottomContentContainer: styled.div`
    width: 1200px;
    height: 563px;
    position: relative;
    display: flex;
    align-items: center;
  `,

  TopMainBox: styled.div`
    width: 600px;
    height: 563px;
    background-color: ${Colors.gf};
    box-shadow: -28px 28px 70px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    position: absolute;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  TopSubBox: styled.div`
    width: 690px;
    height: 450px;
    border-radius: 30px;
    background-color: ${Colors.ge};
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 56px;
  `,
  BottomMainBox: styled.div`
    width: 600px;
    height: 645px;
    background-color: ${Colors.gf};
    box-shadow: -28px 28px 70px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    position: absolute;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  BottomSubBox: styled.div`
    width: 690px;
    height: 450px;
    border-radius: 30px;
    background-color: ${Colors.ge};
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 53px;
  `,
  TapBox: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
  `,

  TapCenterLine: styled.div`
    width: 2px;
    height: 20px;
    background-color: ${Colors.gE2};
  `,
  TapTextBox: styled.div`
    width: 299px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,

  SubTextContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
}

const {
  Container,

  TopContentContainer,
  BottomContentContainer,
  BgBox,

  TopMainBox,
  TopSubBox,
  BottomMainBox,
  BottomSubBox,
  TapBox,
  TapCenterLine,
  TapTextBox,
  ContentItem,
  SubTextContainer,
} = Styled

const PageTree = () => {
  const [isTopTab, setIsTopTab] = useState(true)
  const [isBottomTab, setIsBottomTab] = useState(true)

  return (
    <Container>
      <BgBox />
      <TopContentContainer>
        <TopMainBox>
          <Spacer height={40} />
          {isTopTab ? (
            <TapBox>
              <TapTextBox
                onClick={() => {
                  setIsTopTab(false)
                }}
              >
                <SpoqaRegular color={Colors.ga}>2021년</SpoqaRegular>
              </TapTextBox>
              <TapCenterLine />
              <TapTextBox>
                <SpoqaBold>2022년</SpoqaBold>
              </TapTextBox>
            </TapBox>
          ) : (
            <TapBox>
              <TapTextBox>
                <SpoqaBold>2021년</SpoqaBold>
              </TapTextBox>
              <TapCenterLine />
              <TapTextBox
                onClick={() => {
                  setIsTopTab(true)
                }}
              >
                <SpoqaRegular color={Colors.ga}>2022년 1~3분기</SpoqaRegular>
              </TapTextBox>
            </TapBox>
          )}
          <Spacer height={40} />
          {isTopTab ? (
            <ContentItem
              src='/images/infopage/item8-1.png'
              style={{ width: '520px', height: '418.37px' }}
            />
          ) : (
            <ContentItem
              src='/images/infopage/item9-1.png'
              style={{ width: '520px', height: '418.37px' }}
            />
          )}
        </TopMainBox>
        <TopSubBox>
          <SubTextContainer>
            <SpoqaLight size={24}>
              상장하는 종목의 다양한 정보와 일육공만의 노하우
            </SpoqaLight>
            <Spacer height={10} />
            <div>
              <SpoqaLight size={24}>를 담아 단 하나의 평가지표인 </SpoqaLight>
              <SpoqaMedium size={24}>일육공 매력지수</SpoqaMedium>
              <SpoqaLight size={24}>를</SpoqaLight>
            </div>
            <Spacer height={10} />
            <SpoqaLight size={24}>개발하였어요.</SpoqaLight>
            <Spacer height={46} />
            <SpoqaMedium size={24}>
              과거 상장된 모든 종목과 일육공 매력지수 50점
            </SpoqaMedium>
            <Spacer height={10} />
            <div>
              <SpoqaMedium size={24}>이상의 종목을 비교 </SpoqaMedium>
              <SpoqaLight size={24}>해보면 보다 적은 청약 종목 및</SpoqaLight>
            </div>
            <Spacer height={10} />
            <div>
              <SpoqaLight size={24}>투자금액에도 </SpoqaLight>
              <SpoqaMedium size={24}>
                확연히 높은 투자손익 및 투자수익률,
              </SpoqaMedium>
            </div>
            <Spacer height={10} />
            <div>
              <SpoqaMedium size={24}>수익확률을 확인</SpoqaMedium>
              <SpoqaLight size={24}>해볼 수 있어요.</SpoqaLight>
            </div>
          </SubTextContainer>
        </TopSubBox>
      </TopContentContainer>
      <Spacer height={120} />

      <BottomContentContainer>
        <BottomMainBox>
          <Spacer height={30} />
          {isBottomTab ? (
            <TapBox>
              <TapTextBox
                onClick={() => {
                  setIsBottomTab(false)
                }}
              >
                <SpoqaRegular color={Colors.ga}>2021년</SpoqaRegular>
              </TapTextBox>
              <TapCenterLine />
              <TapTextBox>
                <SpoqaBold>2022년 1~3분기</SpoqaBold>
              </TapTextBox>
            </TapBox>
          ) : (
            <TapBox>
              <TapTextBox>
                <SpoqaBold>2021년</SpoqaBold>
              </TapTextBox>
              <TapCenterLine />
              <TapTextBox
                onClick={() => {
                  setIsBottomTab(true)
                }}
              >
                <SpoqaRegular color={Colors.ga}>2022년 1~3분기</SpoqaRegular>
              </TapTextBox>
            </TapBox>
          )}
          <Spacer height={50} />

          {isBottomTab ? (
            <ContentItem
              src='/images/infopage/item8-2.png'
              style={{ width: '520px', height: '499.71px' }}
            />
          ) : (
            <ContentItem
              src='/images/infopage/item9-2.png'
              style={{ width: '520px', height: '499.71px' }}
            />
          )}
        </BottomMainBox>
        <BottomSubBox>
          <SubTextContainer>
            <SpoqaLight size={24}>
              공모주 안에서도 수익이 발생할 확률이 높은
            </SpoqaLight>
            <Spacer height={10} />
            <SpoqaLight size={24}>
              공모주가 있어요. 일육공의 매력지수로 구간을
            </SpoqaLight>

            <Spacer height={10} />
            <div>
              <SpoqaLight size={24}>나누어 살펴보면, </SpoqaLight>
              <SpoqaMedium size={24}>
                50점 이상만을 투자할 경우 전체
              </SpoqaMedium>
            </div>
            <Spacer height={10} />
            <div>
              <SpoqaMedium size={24}>수익률이 확연히 증가</SpoqaMedium>
              <SpoqaLight size={24}>하는 것을 확인할 수 있어요.</SpoqaLight>
            </div>
            <Spacer height={46} />

            <SpoqaBold size={24}>
              일육공이 자체 개발한 알고리즘과 전문가의 의견을
            </SpoqaBold>
            <Spacer height={10} />
            <SpoqaBold size={24}>
              통해 좋은 공모주를 선별할 수 있도록 도와드릴게요!
            </SpoqaBold>
          </SubTextContainer>
        </BottomSubBox>
      </BottomContentContainer>
    </Container>
  )
}

export default PageTree
