import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import CompanyListSection from '@components/simulated-trading/CompanyListSection'
import ScheduleListSection from '@components/simulated-trading/ScheduleListSection'
import CurrentInvestment from '@components/simulated-trading/CurrentInvestment'
import InvestmentCompleted from '@components/simulated-trading/InvestmentCompleted'
import { useAppSelector } from '../../store/selectors/utils'
import NavigateStore from '../../utils/navigateStore'
import usePostMessage from '@hooks/usePostMessage'
import useSearchParams from '@hooks/useSearchParams'

const Wrapper = styled.div`
  padding-top: 24px;
  background: ${Colors.gFa};
`
const Border = styled.div`
  background-color: ${Colors.background_sub};
  height: 12px;
  width: 100%;
`
const EachSection = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
`

const SimulatedSections = () => {
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const { postMessageHandler } = usePostMessage()
  const { getParam } = useSearchParams()
  const fromApp = getParam('fromApp') === 'true'
  /**
   * 각 공모주 카드 클릭 시의 action
   * @param code 앱에서 온 경우 RN으로 전달하는 code 값
   */

  const onClickCard = useCallback(
    (code: string) => {
      const formatted = {
        action: 'toStock',
        stockId: code,
      }
      // 앱에서 온 경우
      if (isFromApp || fromApp) {
        postMessageHandler(formatted)
      } else {
        NavigateStore()
      }
    },
    [fromApp, isFromApp]
  )

  return (
    <Wrapper>
      <EachSection>
        <CompanyListSection />
      </EachSection>
      <EachSection>
        <ScheduleListSection onClickCard={onClickCard} />
      </EachSection>
      <Border />
      <EachSection>
        <CurrentInvestment onClickCard={onClickCard} />
      </EachSection>
      <Border />
      <EachSection>
        <InvestmentCompleted onClickCard={onClickCard} />
      </EachSection>
    </Wrapper>
  )
}

export default SimulatedSections
