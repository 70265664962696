import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Content06 = () => {
  return (
    <Container>
      <Title>투자 고객</Title>
      <Count>1,800+</Count>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  border-radius: 32px;
  box-sizing: border-box;
  width: 397.5px;
  height: 264px;
  color: #fff;
  background-image: linear-gradient(128deg, #ff00a1 46.01%, #cb258e 103.39%);
`;

const Title = styled.div`
  font-size: 33px;
  font-style: normal;
  line-height: 120%;
`;

const Count = styled.div`
font-size: 80px;
font-style: normal;
line-height: 100%;
`;

export default Content06;
