import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import { MIN_WIDTH, BUTTON_STYLE_RESET } from './presets'
import { Colors } from './colors'

const GlobalStyle = createGlobalStyle`
  ${reset};

  
  * {
    box-sizing: border-box;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    /* //! 드래그 방지 */
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    @media (max-width: 1000px) {
      ::-webkit-scrollbar {
        display: none;
      }      
    }

    &[hidden] {
      display: none !important;
    }
  }
  
  img,
  a {
    -webkit-user-drag: none;
  }


  input, textarea {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  html, body {
    min-height: 100%;
    min-height: 100vh;
    @media (max-width: 1000px) {
      overflow-x: hidden;
    }
  }



  @font-face {
    font-family: 'Pretendard';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}


  body {
    ${MIN_WIDTH};
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    background: #fff; 
    font-family: "Pretendard Variable", Pretendard, 'GmarketSans', 'Pretendard', 'GmarketSansBold',  'SpoqaHanSansNeoBold', -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', sans-serif;
    /* font-family: HelveticaNeue-light, AppleSDGothicNeo-light, sans-serif; */
    /* -webkit-font-smoothing: subpixel-antialiased; */
    color: ${Colors.PRIMARY};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    vertical-align: top;
  }

  input {
    margin: 0;
    padding: 0;
    background: transparent;
    outline: none;
    color: ${Colors.PRIMARY};
    caret-color: ${Colors.ga};

    &::-webkit-input-placeholder {
      line-height: normal;
      color: ${Colors.ga};
    }
  }

  input[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }

  // 숫자
  // @see https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }

  button {
    ${BUTTON_STYLE_RESET};
  }
  
  #root {
    width:100%;
    min-height: 100vh;

    --main-samsung: #0E6DFB;



  }

  .ReactModal__Body--open {
   overflow-y: hidden; 
  }

  .ReactModal__Overlay {
    opacity: 0 !important;
    transition: opacity 200ms ease-in-out !important;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1 !important;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0 !important;
  }
`

export default GlobalStyle
