import React from 'react'
import styled from 'styled-components'

type NameProps = {
  name: string
  url?: string
}

const Menu = ({ name, url }: NameProps) => {
  return (
    <Container onClick={() => window.open(url)} id={'menu_ml_btn_click'}>
      {name}
    </Container>
  )
}

const Container = styled.div`
  width: auto;
  height: auto;
  font-size: 22px;
  font-family: Pretendard600, sans-serif;
  line-height: 26px;
  font-style: normal;
  cursor: pointer;
`

export default Menu
