import { useQuery } from '@tanstack/react-query'
import { getData } from '../utils/api'
import { AxiosError } from 'axios'
import { IpoStockGubun } from '@enums/StockEnum'

type ReturnType<T> = {
  data: T
}
type CurrentDataType = {
  stockCode: string
  stockTicker: string
  status: string
  amountProfit: number
  amountReceived: number
  name: string
  imgUrl: string
}
type CompletedDataType = {
  stockCode: string
  offeringPrice: number
  score: number
  sort: IpoStockGubun
} & CurrentDataType

type SummaryDataType = {
  totalAmountReceived: number
  eventProfit: number
  eventProfitPercentage: number
  eventSchedule: {
    startDate: Date
    endDate: Date
  }
}

type ScheduledOfferDataType = {
  code: string
  name: string
  imgUrl: string
  offeringStartDate: string
  offeringEndDate: string
  securitiesCode: string
}

const useGetSimulatedData = () => {
  // 추후 API URL은 따로 상수로 관리
  // 각 페이지별 API는 한 hooks에 담는게 좋아보임
  const completedUrl = '/mock-investment/done'
  const currentUrl = '/mock-investment'
  const summaryUrl = '/mock-investment/summary'
  const scheduledUrl = '/stock/scheduled-offer-contractable'

  //청약 예정 공모주
  const scheduledOfferData = useQuery<
    ReturnType<ScheduledOfferDataType[]>,
    AxiosError,
    ScheduledOfferDataType[]
  >({
    queryKey: ['scheduledOffer'],
    queryFn: () => getData(scheduledUrl),
    select: ({ data }) => data,
    retry: false,
  })

  //모의 투자 요약내역 조회,
  const simulatedSummaryData = useQuery<
    ReturnType<SummaryDataType>,
    AxiosError,
    SummaryDataType
  >({
    queryKey: ['summaryInvestment'],
    queryFn: () => getData(summaryUrl),
    select: ({ data }) => data,
    retry: false,
  })

  //투자완료내역 조회
  const simulatedCompletedData = useQuery<
    ReturnType<CompletedDataType[]>,
    AxiosError,
    CompletedDataType[]
  >({
    queryKey: ['completedInvestment'],
    queryFn: () => getData(completedUrl),
    select: ({ data }) => data,
    retry: false,
  })

  //현재내역 조회
  const simulatedCurrentData = useQuery<
    ReturnType<CurrentDataType[]>,
    AxiosError,
    CurrentDataType[]
  >({
    queryKey: ['currentInvestment'],
    queryFn: () => getData(currentUrl),
    select: ({ data }) => data,
    retry: false,
  })
  return {
    scheduledOfferData,
    simulatedSummaryData,
    simulatedCompletedData,
    simulatedCurrentData,
  }
}

export default useGetSimulatedData
