import { useAnimation, useInView } from 'framer-motion'
import Spacer from '../../base/Spacer'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '../../base/TextStyle/Spoqa'
import {
  Container,
  Image,
  ImageWrapper,
  TextWrapper,
  ContentWrapper,
  ImageMobile,
} from './styles'
import { useEffect, useRef } from 'react'
import { Colors } from '@styles/colors'
import { useMediaQuery } from 'react-responsive'
import { BoldText, PaddingWrapper } from '../SectionSeven/styles'

const SectionNine = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  const isTabletL = useMediaQuery({ query: '(max-width: 1430px)' })
  return (
    <Container>
      {isTabletL ? (
        <ContentWrapper>
          <PaddingWrapper>
            <BoldText
              size={isMobile ? 14 : 24}
              color={Colors.primary_ios}
              weight={700}
            >
              종목 상세정보
            </BoldText>
            <Spacer height={16} />
            <SpoqaBold
              size={isMobile ? 22 : 48}
              color={Colors.g12}
              lineHeight={isMobile ? 28 : 60}
            >
              복잡한 청약정보,
              <br />
              일육공으로 간편하게
            </SpoqaBold>
            <Spacer height={32} />
            <SpoqaRegular
              size={isMobile ? 13 : 19}
              color={Colors.g12}
              lineHeight={isMobile ? 18.2 : 30}
            >
              상세정보에서는 해당 공모주의 자세한 정보를
              <br />
              확인하실 수 있도록 체계적으로 정리했어요.
              <br />
              복잡한 청약정보를 간편하게 확인하세요! <br />
            </SpoqaRegular>
          </PaddingWrapper>
          <ImageWrapper>
            <ImageMobile
              src={
                isTablet
                  ? 'images/main/section-nine/mobile.png'
                  : '/images/main/section-nine/phone.png'
              }
              alt='mobile'
            />
          </ImageWrapper>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <ImageWrapper>
            <Image
              src={'/images/main/section-nine/phone.png'}
              alt='nine-phone'
            />
          </ImageWrapper>
          <TextWrapper>
            <SpoqaBold size={24} color={Colors.primary_ios}>
              종목 상세정보
            </SpoqaBold>
            <Spacer height={16} />
            <SpoqaBold size={48} color={Colors.g12} lineHeight={62.4}>
              복잡한 청약정보,
              <br /> 일육공으로 간편하게
            </SpoqaBold>
            <Spacer height={40} />
            <SpoqaRegular size={22} lineHeight={30.8}>
              상세정보에서는 해당 공모주의 자세한 정보를
              <br />
              확인하실 수 있도록 체계적으로 정리했어요.
              <br />
              복잡한 청약정보를 간편하게 확인하세요!
            </SpoqaRegular>
          </TextWrapper>
        </ContentWrapper>
      )}
    </Container>
  )
}

export default SectionNine
