import { Colors } from '@styles/colors'
import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.section`
  width: 100%;
  height: 722px;
  background-color: ${Colors.gf};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 178px;
`

export const ContentWrapper = styled(motion.div)`
  padding: 0 10%;
  height: 367px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.gf};
`

export const ContentItem = styled(motion.img)`
  width: 25%;
  @media (max-width: 767px) {
    width: 60%;
  }
`

//mobile carousel
export const ItemWrapper = styled(motion.div)`
  width: 100%;
  min-height: 355px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SelecteIconContainer = styled.div`
  height: 100%;
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 360px) {
    width: 53px;
  }
`
export const SelecteIcon = styled.img`
  width: 30px;
  height: 40px;
`

export const CarouselContainer = styled.div`
  width: 100%;
  min-height: 355px;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 120px;
  @media (max-width: 767px) {
    padding-bottom: 60px;
  }
`
