import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import Menu from './menu'

const AccordionMenu = () => {
  const playerRef = useRef<ReactPlayer | null>(null)
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null)

  const seekToTime = (seconds: number) => {
    if (playerRef.current) {
      playerRef.current.seekTo(seconds, 'seconds')
    }
  }

  const toggleMenuVisibility = (index: number, time: number) => {
    setVisibleIndex(visibleIndex === index ? null : index)
    seekToTime(time)
  }

  const list = [
    {
      title: '1. 한눈에 보기',
      base: '공모주 투자는 청약, 배정, 매도 3단계를 통해 이루어져요. 일육공 AI가 우량 공모주의 청약과 매도 과정을 대신해드리며, 투자자님께서는 계약한 증권 계좌로 청약을 위해 최소청약증거금을 입금만 하시면 돼요. 일육공에서는 최소청약증거금으로 균등 배정을 위한 투자를 대신해드려요.',
      detail: `* 최소청약증거금은 공모주 청약을 위해 필요한 투자금이며 공모주의 가격과 청약 주 수에 따라 금액이 달라져요. \n * 균등 배정이란, 청약에 참여한 모든 투자자가 동등한 기회로 공모주를 배정받을 수 있는 제도를 의미해요. 청약 경쟁률에 따라 균등 배정으로 받을 수 있는 공모주의 수량이 달라져요.`,
      time: 0,
    },
    {
      title: '2. 준비하기',
      base: '공모주 간편투자를 위해서는 계약한 증권사의 계좌에 투자를 위한 최소청약증거금 이상의 잔액이 필요해요. 일육공에 등록한 입출금 계좌나 주거래 계좌를 증권 계좌와 오픈뱅킹으로 연결하면 수수료 없이 이체가 가능해요.',
      detail:
        '* 오픈뱅킹은 하나의 금융 앱으로 여러 은행의 계좌 조회, 송금 등을 할 수 있는 금융 서비스를 말해요.',
      time: 19,
    },
    {
      title: '3. 입금하기 : 청약',
      base: `공모주 청약을 위해 최소 청약 증거금을 입금해 주세요. 청약일에는 일육공 AI가 계약한 증권사의 우량 공모주만 선별 후 청약해 드려요. 증권 계좌의 잔액이 충분하면 자동으로 청약이 되며, 충분하지 않으면 일육공에서 입금 안내를 드려요. 안내에 따라 부족한 금액을 입금하면 일육공이 청약을 대신해 드려요. \n 매번 이체가 번거로우면, 계약한 증권 계좌마다 여유 있게 50만 원씩 넣어주시면 돼요. 50만 원을 미리 넣어두면 대부분의 우량 공모주를 이체 없이 자동으로 투자할 수 있어요..`,
      detail:
        '* 여러 증권 계좌에 금액을 넣어두는 경우, 계약서에 명시된 계약금인 100만 원을 넘어갈 수도 있어요.',
      time: 38,
    },
    {
      title: '4. 결과보기 : 배정 및 매도',
      base: `공모주 배정 결과는 일육공에서 확인할 수 있어요. 청약 경쟁률에 따라 무작위로 배정 여부가 결정되며 경쟁률이 높은 경우, 배정을 받지 못할 수도 있어요. \n 마지막으로 공모주가 상장되면 배정받은 공모주는 일육공이 알아서 매도해 드리며, 앱에서 매도 결과를 확인할 수 있어요. 매도 금액은 매도일 기준 2영업일 뒤 증권 계좌에 입금되며, 매도 금액은 오픈뱅킹을 통해 출금할 수 있어요. \n 이와 같은 방법으로 일육공 AI가 선별한 다른 우량 공모주에 계속해서 투자할 수 있어요.`,
      time: 79,
    },
  ]
  return (
    <Container>
      <PlayerContainer>
        <ReactPlayer
          ref={playerRef}
          url='/video/240920_guide.mp4'
          loop
          playing
          muted
          width='22.5rem'
          height='26.25rem'
          controls
          autoPlay={true}
          playsinline={true}
        />
      </PlayerContainer>
      {list.map((item, idx) => (
        <Menu
          key={idx}
          title={item.title}
          base={item.base}
          detail={item.detail}
          isVisible={visibleIndex === idx}
          toggleVisibility={() => toggleMenuVisibility(idx, item.time)} // 클릭 시 시간 이동
          isLast={idx === list.length - 1}
          time={item.time}
        />
      ))}
    </Container>
  )
}

const PlayerContainer = styled.div`
  height: 26.25rem;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
  all: unset;
`

export default AccordionMenu
