import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Card from "./card";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "./countUp";

const Index = () => {
  const thirdCardRef = useRef(null);
  const countUpRef = useRef(null);
  const [countUpStarted, setCountUpStarted] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200
    });
  }, []);

  return (
    <Container>
      {/* <Card
        fadeRight
        title="청약이 내일 시작됩니다"
        content="공모가: **,000원"
        time="2시간 전"
        width={284}
        height={88}
        top={50}
        right={141}
      />
      <Card
        fadeLeft
        title="청약을 위한 투자금이 부족"
        content="OO증권계좌에 **,000원"
        time="2시간 전"
        width={284}
        height={88}
        left={156}
        top={110}
      />

        <Card
          fadeRight
          ref={thirdCardRef}
          title="공모주 매도 완료"
          content={`오늘 공모주 매도했습니다. \n 매도가 ***,000원`}
          time="방금"
          width={284}
          height={88}
          right={109}
          bottom={91}
      /> */}

      <SamsungTitle>{`청약부터 매도까지 \n 알아서 다 해드리는 \n\n\n 일육공 간편투자서비스! \n\n 잊고 살아요 \n 우리가 챙길게요`}</SamsungTitle>
      {/* <Title>{`잊고 살아요 \n 우리가 챙길게요`}</Title> */}
      <Phone src={'/img/main/mobile-mobile-img.svg'} data-aos="slide-up" />
    </Container>
  );
};




const Phone = styled.img`
  width: 320px;
  bottom: 0;
  position: absolute;
`;


const Container = styled.div`
  width: 100%;
  height: 1002px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #DCDEEB;
  padding: 72px 19px 0px 19px;
  /* gap: 48px; */
`;

const Title = styled.div`
  white-space: pre-line;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  text-align: center;
  font-family: Pretendard900, sans-serif;
  font-weight: 900;

`;

const SamsungTitle = styled(Title)``

export default Index;
