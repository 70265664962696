import { Colors } from '@styles/colors'
import Modal from 'react-modal'
import {
  CloseContainer,
  ContentContainer,
  ContentLogo,
  DownloadTitleContainer,
  ModalContainer,
  ModalImg,
  QRcontent,
  StoreBtn,
  StoreBtnContainer,
} from './styles'
import Spacer from '@components/base/Spacer'
import { SpoqaMedium } from '@components/base/TextStyle/Spoqa'
import { useMediaQuery } from 'react-responsive'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DownloadModal = ({ isOpen, setIsOpen }: Props) => {
  const isTabletL = useMediaQuery({ query: '(max-width: 1430px)' })

  const ModalStyle = {
    content: {
      border: 0,
      width: isTabletL ? '527px' : '30%',
      height: '697px',
      left: '50%',
      right: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      bottom: 'auto',
      borderRadius: '20px',
      backgroundColor: Colors.gf,
      padding: '0',
      boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 100,
    },
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={ModalStyle}
      >
        <ModalContainer>
          <ModalImg
            src='images/modal/basic_install_modal_img.png'
            alt='basic_install_modal'
          />
          <Spacer height={26} />
          <DownloadTitleContainer>
            <SpoqaMedium size={18} color={Colors.g9}>
              스토어 다운로드
            </SpoqaMedium>
            <SpoqaMedium size={18} color={Colors.g9}>
              QR코드 다운로드
            </SpoqaMedium>
          </DownloadTitleContainer>
          <Spacer height={23} />
          <ContentContainer>
            <StoreBtnContainer>
              <StoreBtn
                onClick={() => {
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
                  )
                }}
              >
                <Spacer width={13.76} />
                <ContentLogo src='images/google-logo-medium.png' />
                <Spacer width={6.38} />
                <SpoqaMedium size={16}>Google Play</SpoqaMedium>
              </StoreBtn>

              <StoreBtn
                onClick={() => {
                  window.open(
                    'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
                  )
                }}
              >
                <Spacer width={13.76} />
                <ContentLogo src='images/apple-logo-medium.png' />
                <Spacer width={6.38} />
                <SpoqaMedium size={16}>Apple Store</SpoqaMedium>
              </StoreBtn>
            </StoreBtnContainer>

            <Spacer width={1} height={23} background={Colors.ga} />
            <QRcontent src='images/modal/QRimg.png' />
          </ContentContainer>
          <Spacer height={56} />
          <CloseContainer
            onClick={() => {
              setIsOpen(false)
            }}
          >
            <SpoqaMedium size={22} color={Colors.g9}>
              닫기
            </SpoqaMedium>
          </CloseContainer>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default DownloadModal
