import { createSelector } from "@reduxjs/toolkit";
import { BASE_STATE } from "./utils";

export const baseSelector = createSelector(
  [BASE_STATE],
  ({ showLoader, showSplash, date, filter, selectedDateSchedule, ci }) => {
    return {
      showLoader,
      showSplash,
      selectedDateSchedule,
      ci,
      selectedYear: date.selectedYear,
      selectedMonth: date.selectedMonth,
      selectedDate: date.selectedDate,
      selectedFilter: filter,
    };
  }
);
