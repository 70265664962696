import React from "react";
import styled from "styled-components";

const content05 = () => {
  return (
    <Container>
      <Title>월 평균 방문자</Title>
      <Count>389,000</Count>
      <Desc>* 제휴 포함</Desc>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 148px;
  height: 122px;
  box-sizing: border-box;
  background-color: #ecff00;
  color: #000;
`;

const Title = styled.div`
  color: #000;
  font-family: Pretendard, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const Count = styled.div`
  color: #000;

  font-family: Pretendard, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 


`;

const Desc = styled.div`
  color: #000;
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export default content05;
