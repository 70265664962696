import { useEffect } from 'react'

const usePostMessage = () => {
  const postMessageHandler = (message: unknown) => {
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  }
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: 'rerender',
        })
      )
    }
  }, [])
  return { postMessageHandler }
}

export default usePostMessage
