import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface UserState {
  name: string;
  phone: string;
  password: string;
  infoAgree: boolean;
}

const initialState: UserState = {
  name: '',
  phone: '',
  password: '',
  infoAgree: false,
};

const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<{ name: string; phone: string; password: string; infoAgree:boolean }>) {
      const { name, phone, password,infoAgree } = action.payload;
      state.name = name;
      state.phone = phone;
      state.password = password;
      state.infoAgree = infoAgree;
    },
    clearUser(state) {
      state.name = '';
      state.phone = '';
      state.password = '';
      state.infoAgree = false;
    },
  },
})



export const { setUser, clearUser } = userSlice.actions
export default userSlice.reducer
