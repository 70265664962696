import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${Colors.gf};
  z-index: 1;
  padding: 80px 0 400px 0;

  @media (max-width: 1430px) {
    height: auto;
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 0 0 120px 0;
  }
`

export const ContentWrapper = styled.div`
  width: 70%;
  position: relative;
  @media (max-width: 1430px) {
    position: relative;
    height: auto;
    width: 100%;
    flex-direction: column;
  }
`

export const CardWrapper = styled.div`
  @media (max-width: 1430px) {
    padding: 0 4%;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardItem = styled.div`
  width: 100%;
  height: 540px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  box-shadow: -28px 28px 70px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 78px 60px;
  @media (max-width: 767px) {
    height: auto;
    background: #fff;
    box-shadow: -11.256px 11.256px 28.141px 0px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    padding: 34px;
  }
`

export const CardImage = styled.img`
  width: 40%;
  @media (max-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 80%;
    margin-top: 32px;
    object-fit: contain;
  }
`
export const CardText = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
`
