import { CustomLocation, RouterItem } from '../router/routerItem'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import {
  MainRouterItem,
  InfoRouterItem,
  FaqRouterItem,
  CalendarRouterItem,
  EventKbRouteItem,
  WelcomeBridgeRouteItem,
  WelcomeCallSchemeRouteItem,
  SimulatedEventSignItem,
  SimulatedEventForgetItem,
  SimulatedEventChangedPwItem,
  SimulatedMarketingItem,
  SimulatedTradingPageItem
} from '../router/paths/base'

interface HandleDefaultRoutePropsType {
  payload?: CustomLocation
  replace?: boolean
}

interface UseRouterHistoryHandler {
  goBack: () => void
  handleGoMainPage: GoPageHandlerType
  handleGoInfoPage: GoPageHandlerType
  handleGoFaqPage: GoPageHandlerType
  handleGoCalendarPage: GoPageHandlerType
  handleGoEventKbPage: GoPageHandlerType
  handleGoWelcomeBridgePage: GoPageHandlerType
  handleGoWelcomeCallSchemePage: GoPageHandlerType
  handleGoSimulatedSignPage: GoPageHandlerType
  handleGoSimulatedForgePage: GoPageHandlerType
  handleGoSimulatedChangePwPage: GoPageHandlerType
  handleGoSimulatedMarketingPage: GoPageHandlerType
  handleGoSimulatedTradingPage: GoPageHandlerType
}

type GoPageHandlerType = (props?: HandleDefaultRoutePropsType) => void

interface GoActionPropsType extends HandleDefaultRoutePropsType {
  pageComponent: RouterItem
}

function useRouterHistoryHandler(): UseRouterHistoryHandler {
  const history = useHistory()
  const location = useLocation()

  function goAction({
    payload,
    replace,
    pageComponent,
  }: GoActionPropsType): void {
    if (
      matchPath(`${location.pathname}${location.search}`, pageComponent)
        ?.isExact
    ) {
      return undefined
    }
    const action = replace ? history.replace : history.push
    let queryString = '';
    if (payload) {
      const params: Record<string, string> = {};
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params[key] = String(payload[key]); 
        }
      }
      queryString = new URLSearchParams(params).toString();
    }
  
    const pathWithQuery = pageComponent.toObject(payload);
  
    if (queryString) {
      action({
        pathname: pathWithQuery.pathname,
        search: `?${queryString}`,
      });
    } else {
      action(pathWithQuery);
    }

    // action(pageComponent.toObject(payload))
  }

  function goBack(): void {
    history.goBack()
  }

  const handleGoMainPage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: MainRouterItem })
  }

  const handleGoInfoPage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: InfoRouterItem })
  }

  const handleGoFaqPage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: FaqRouterItem })
  }

  const handleGoCalendarPage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: CalendarRouterItem })
  }

  const handleGoEventKbPage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: EventKbRouteItem })
  }

  const handleGoWelcomeBridgePage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: WelcomeBridgeRouteItem })
  }
  const handleGoWelcomeCallSchemePage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: WelcomeCallSchemeRouteItem })
  }
  const handleGoSimulatedSignPage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: SimulatedEventSignItem })
  }
  const handleGoSimulatedForgePage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: SimulatedEventForgetItem })
  }
  const handleGoSimulatedChangePwPage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: SimulatedEventChangedPwItem })
  }
  const handleGoSimulatedMarketingPage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: SimulatedMarketingItem })
  }
  const handleGoSimulatedTradingPage: GoPageHandlerType = (props) => {
    goAction({ ...props, pageComponent: SimulatedTradingPageItem })
  }

  return {
    goBack,
    handleGoMainPage,
    handleGoInfoPage,
    handleGoFaqPage,
    handleGoCalendarPage,
    handleGoEventKbPage,
    handleGoWelcomeBridgePage,
    handleGoWelcomeCallSchemePage,
    handleGoSimulatedSignPage,
    handleGoSimulatedForgePage,
    handleGoSimulatedChangePwPage,
    handleGoSimulatedMarketingPage,
    handleGoSimulatedTradingPage
  }
}

export default useRouterHistoryHandler
