import { Colors } from "../../styles/colors";
import styled, { css } from "styled-components";

const Styled = {
  Wrapper: styled.div<{
    width?: number | string;
    height?: number | string;
    background?: Colors;
  }>`
    width: auto;
    height: auto;

    ${({ width }) =>
      width &&
      css`
        width: ${width}px;
      `};
    ${({ height }) =>
      height &&
      css`
        height: ${height}px;
      `};
    ${({ background }) =>
      background &&
      css`
        background-color: ${background};
      `};
  `,
};

const { Wrapper } = Styled;

interface SpacerProps {
  width?: number | string;
  height?: number | string;
  background?: Colors;
}
function Spacer({ width, height, background }: SpacerProps) {
  return <Wrapper width={width} height={height} background={background} />;
}

export default Spacer;
