import React from 'react'
import SimulatedLayout from '@layouts/SimulatedLayout'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'
import { Colors } from '@styles/colors'
import { Link, useHistory } from 'react-router-dom'
import useSearchParams from '@hooks/useSearchParams'
import { useMediaQuery } from 'react-responsive'

const EventDesktopSize = 1280
const MAX_ITEM_WIDTH = 640
const TabContainer = styled.div`
  border-bottom: 1px solid #dcdeeb;
  display: flex;
  justify-content: center;
  height: 100%;
  flex: 1;
`
const Tab = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: ${MAX_ITEM_WIDTH * 2}px;
  height: 80px;
  @media screen and (max-width: 1280px) {
    max-width: 100%;
    height: 48px;
  }
`
const TabItem = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  > span {
    font-size: 26px;
    font-family: ${(props) =>
      props.isSelected
        ? 'Pretendard700, sans-serif'
        : 'Pretendard400, sans-serif'};
    color: ${(props) => (props.isSelected ? '#19191B' : '#999ba9')};
    @media screen and (max-width: ${EventDesktopSize}px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`
const CurrentUnderLine = styled.div<{ isEnd: boolean }>`
  position: absolute;
  bottom: 0;
  left: ${(props) => (props.isEnd ? '50%' : 0)};
  transition: ease-in-out 0.3s;
  width: 100%;
  max-width: ${MAX_ITEM_WIDTH}px;
  height: 2px;
  background-color: ${Colors.g7};
  @media screen and (max-width: ${EventDesktopSize}px) {
    width: 50%;
  }
`
const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const EventList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 36px;
  padding-top: 56px;
  margin-bottom: 140px;
  @media screen and (max-width: ${Desktop}px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    margin-bottom: 56px;
  }
  @media screen and (max-width: ${EventDesktopSize}px) {
    gap: 24px;
    padding: 24px;
    margin-bottom: 56px;
  }
`
const EventItem = styled.li`
  width: 620px;
  @media screen and (max-width: ${EventDesktopSize}px) {
    width: 312px;
  }
`
const EventImage = styled.img`
  height: 360px;
  background-color: lightgray;
  width: 620px;
  border-radius: 12px;
  @media screen and (max-width: ${EventDesktopSize}px) {
    width: 312px;
    height: 180px;
  }
`
const EventTitle = styled.h2`
  font-family: Pretendard700, sans-serif;
  text-align: start;
  margin: 24px 0 8px 0;
  word-break: auto-phrase;
  @media screen and (max-width: ${EventDesktopSize}px) {
    margin: 16px 0 8px;
    font-size: 18px;
    line-height: 22px;
  }
`
const EventDesc = styled.span`
  font-family: Pretendard400, sans-serif;
  text-align: start;
  color: #999ba9;
  @media screen and (max-width: ${EventDesktopSize}px) {
    font-size: 14px;
    line-height: 17px;
  }
`

const ONGOING = '?tab=ongoing'
const END = '?tab=end'

const OnGoingEventList = [
  {
    id: 3,
    title: '일육공 공모주 게임으로 최대 53만원의 기회 받기',
    startDate: '2025.01.06',
    endDate: '2025.02.05',
    description: '(청약 발생 시, 해당 종목 매도일까지)',
    thumbnailUrl: '/images/event/simulate-event-card.png',
    link: '/simulated-event',
  },
]

const EndEventList = [
  {
    id: 1,
    title: '공모주 간편투자하고 최대 4만원 받자!',
    startDate: '2024.09.27',
    endDate: '2024.12.31',
    description: '(미션 완료 시•선착순 5,000명)',
    thumbnailUrl: '/images/event/samsung-event-card.png',
    link: '/samsung-event-160',
  },
  {
    id: 2,
    title: '일육공에서 공모주 청약하고 아이패드 받자!',
    startDate: '',
    endDate: '2025.01.31',
    description: '',
    thumbnailUrl: '/images/event/kb-event-card.png',
    link: '/kb-event',
  },
]

const Event = () => {
  const history = useHistory()
  const { getParam } = useSearchParams()
  const isMobile = useMediaQuery({
    query: `(max-width: ${EventDesktopSize}px)`,
  })

  const events = [] //임시
  const currentTab = getParam('tab') || 'ongoing'
  const isEndTab = currentTab === 'end'
  /**
   *
   * @param isEnd 종료된 이벤트 인지 여부
   */
  const handleTabChange = (isEnd: boolean = false) => {
    if (isEnd) {
      history.replace(END)
    } else {
      history.replace(ONGOING)
    }
  }

  return (
    <SimulatedLayout paddingBottom={0} hasHeaderUnderLine={false}>
      <TabContainer>
        <Tab>
          <CurrentUnderLine isEnd={isEndTab} />
          <TabItem onClick={() => handleTabChange()} isSelected={!isEndTab}>
            <span>진행중인 이벤트</span>
          </TabItem>
          <TabItem onClick={() => handleTabChange(true)} isSelected={isEndTab}>
            <span>종료된 이벤트</span>
          </TabItem>
        </Tab>
      </TabContainer>
      <ListWrapper>
        {!isEndTab ? (
          <EventList>
            {OnGoingEventList.map(
              ({
                thumbnailUrl,
                id,
                title,
                startDate,
                endDate,
                description,
                link,
              }) => (
                <EventItem key={id}>
                  <Link to={link}>
                    <EventImage src={thumbnailUrl} alt={title} />
                    <EventTitle>{title}</EventTitle>
                    <EventDesc>
                      {startDate} ~ {endDate}
                      {isMobile && <br />}
                      {description}
                    </EventDesc>
                  </Link>
                </EventItem>
              )
            )}
          </EventList>
        ) : (
          <EventList>
            {EndEventList.map(
              ({
                thumbnailUrl,
                id,
                title,
                startDate,
                endDate,
                description,
                link,
              }) => (
                <EventItem key={id}>
                  <Link to={link}>
                    <EventImage src={thumbnailUrl} alt={title} />
                    <EventTitle>{title}</EventTitle>
                    <EventDesc>
                      {startDate} ~ {endDate}
                      {isMobile && <br />}
                      {description}
                    </EventDesc>
                  </Link>
                </EventItem>
              )
            )}
          </EventList>
        )}
      </ListWrapper>
    </SimulatedLayout>
  )
}

export default Event
