import { useEffect, useRef } from 'react';

const useDebounce = (callback: Function) =>{
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const delay = 500;
  const debouncedFunction = (...args: any[]) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return debouncedFunction;
}

export default useDebounce;
