import React from 'react'
import styled from 'styled-components'
const IconBox = () => {
  const IconImgs = [
    '/img/main/icon/icon1.svg',
    '/img/main/icon/icon2.svg',
    '/img/main/icon/icon3.svg',
    '/img/main/icon/icon4.svg',
    '/img/main/icon/icon5.svg',
    '/img/main/icon/icon6.svg',
  ]

  return (
    <Container>
      {IconImgs.map((itm, idx) => (
        <IconContainer key={idx}>
          <img src={itm} alt='' style={{ width: '95px', height: '96px' }} />
        </IconContainer>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  gap: 2.5px;
`

const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 58px 24.7px 57.5px 24px;
  box-sizing: border-box;
  background-color: #f0f4f9;
`

export default IconBox
