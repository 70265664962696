import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import { Colors } from '@styles/colors'
import NavigateStore from '../../utils/navigateStore'
import { useAppSelector } from '../../store/selectors/utils'
import usePostMessage from '@hooks/usePostMessage'
import InstallBottomSheet from '@components/simulated-trading/InstallBottomSheet'
import styled from 'styled-components'
import { CopyURL, NavigateShare } from '../../utils'

const ButtonFixer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.gf};
  border-top: 1px solid ${Colors.ge};
  height: 106px;
  @media screen and (max-width: ${Desktop}px) {
    height: 98px;
  }
`
const MobileButtonWrapper = styled.div<{ paddingBottom?: number }>`
  max-width: 800px;
  width: 100%;
  display: flex;
  gap: 8px;
  padding: 20px 24px
    ${({ paddingBottom }) => (paddingBottom ? paddingBottom : 24)}px;
`
const StartButton = styled.button<{ isFromApp?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #0e6dfb;
  border-radius: 16px;
  height: 58px;
  cursor: pointer;
  @media screen and (max-width: ${Desktop}px) {
    border-radius: ${(props) => (props.isFromApp ? '4px' : '18px')};
  }
  > span {
    font-family: Pretendard500, sans-serif;
    color: ${Colors.gf};
    font-size: 20px;
    line-height: 24px;
    @media screen and (max-width: ${Desktop}px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`
const WebButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: 800px;
  display: flex;
  gap: 8px;
`
const ShareButton = styled.button`
  min-width: 84px;
  cursor: pointer;
  color: #0e6dfb;
  border-radius: 18px;
  border: 1px solid #0e6dfb;
  font-family: Pretendard700, sans-serif;
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 18px;
    line-height: 27px;
  }
`

const TradingNowButton = (props: {
  paddingBottom?: number
  hasShare?: boolean
}) => {
  const { paddingBottom, hasShare = false } = props
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const { postMessageHandler } = usePostMessage()
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)

  // 지금 실제 투자 시작하기 mobile button action 160앱에서 오지 않은 경우 ( safari,chrome 등에서 접근 )
  const onClickMobileStart = () => {
    const formatted = {
      action: 'toInvestmentTab',
    }
    if (isFromApp) {
      postMessageHandler(formatted)
    } else {
      NavigateStore()
    }
  }
  const onClickInvestmentStart = () => setIsBottomSheetOpen(!isBottomSheetOpen)

  return (
    <>
      {isBottomSheetOpen ? (
        <InstallBottomSheet onClose={onClickInvestmentStart} />
      ) : null}
      {isMobile ? (
        <ButtonFixer>
          <MobileButtonWrapper paddingBottom={paddingBottom}>
            {!isFromApp && hasShare ? (
              <ShareButton
                onClick={NavigateShare}
                id={'event_160_01_main_share_btn_click'}
              >
                <span>공유</span>
              </ShareButton>
            ) : null}
            <StartButton
              onClick={onClickMobileStart}
              isFromApp={isFromApp}
              id={'event_160_01_main_startinvest_btn_click'}
            >
              <span>지금 실제 투자 시작하기</span>
            </StartButton>
          </MobileButtonWrapper>
        </ButtonFixer>
      ) : (
        <ButtonFixer>
          <WebButtonWrapper>
            {hasShare ? (
              <ShareButton
                onClick={CopyURL}
                id={'event_160_01_main_share_btn_click'}
              >
                <span>공유</span>
              </ShareButton>
            ) : null}
            <StartButton
              onClick={onClickInvestmentStart}
              id={'event_160_01_main_startinvest_btn_click'}
            >
              <span>지금 실제 투자 시작하기</span>
            </StartButton>
          </WebButtonWrapper>
        </ButtonFixer>
      )}
    </>
  )
}

export default TradingNowButton
