import { useMediaQuery } from 'react-responsive'
import Spacer from '../../base/Spacer'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '../../base/TextStyle/Spoqa'
import { Container, Row } from './styles'

const SectionThree = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  return (
    <Container>
      <Spacer height={isMobile ? 60 : isTablet ? 70 : 81} />
      {isMobile ? (
        <>
          <SpoqaMedium size={18}>일육공만 있으면</SpoqaMedium>
          <Spacer height={4} />
          <SpoqaMedium size={18}>공모주 투자 어렵지 않아요</SpoqaMedium>
        </>
      ) : isTablet ? (
        <>
          <SpoqaMedium size={32}>일육공만 있으면</SpoqaMedium>

          <SpoqaMedium size={32}>공모주 투자 어렵지 않아요</SpoqaMedium>
        </>
      ) : (
        <SpoqaMedium size={32}>
          일육공만 있으면 공모주 투자 어렵지 않아요
        </SpoqaMedium>
      )}
      <Spacer height={isMobile ? 16 : isTablet ? 30 : 40} />
      {isMobile ? (
        <>
          <SpoqaLight size={14}>
            일육공은 상장하는 종목의 다양한 정보와
          </SpoqaLight>
          <Spacer height={8} />
          <Row>
            <SpoqaLight size={14}>특별한 노하우를 담은</SpoqaLight>
            <SpoqaBold size={14}>단 하나의 평가 지표,</SpoqaBold>
          </Row>
          <Spacer height={8} />
          <Row>
            <SpoqaBold size={14}>일육공 매력지수</SpoqaBold>
            <SpoqaLight size={14}>와 공모주 선별부터 청약</SpoqaLight>
          </Row>
          <Spacer height={8} />
          <Row>
            <SpoqaLight size={14}>그리고 매도까지 알아서 해드리는 </SpoqaLight>
            <SpoqaBold size={14}>공모주</SpoqaBold>
          </Row>
          <Spacer height={8} />
          <Row>
            <SpoqaBold size={14}>올인원 투자일임서비스</SpoqaBold>
            <SpoqaLight size={14}>를 제공하고 있어요.</SpoqaLight>
          </Row>
          <Spacer height={32} />
          <Row>
            <SpoqaLight size={14}>일육공과 함께</SpoqaLight>
            <SpoqaBold size={14}>"쉽고 간편하게 공모주 청약"</SpoqaBold>
            <SpoqaLight size={14}>하세요.</SpoqaLight>
          </Row>
        </>
      ) : (
        <>
          <SpoqaLight size={isTablet ? 14 : 24}>
            일육공은 상장하는 종목의 다양한 정보와 특별한 노하우를 담은
          </SpoqaLight>
          <Spacer height={12} />
          <Row>
            <SpoqaBold size={isTablet ? 14 : 24}>
              단 하나의 평가 지표, 일육공 매력지수
            </SpoqaBold>
            <SpoqaLight size={isTablet ? 14 : 24}>
              와 공모주 선별부터 청약 그리고
            </SpoqaLight>
          </Row>
          <Spacer height={12} />
          <Row>
            <SpoqaLight size={isTablet ? 14 : 24}>
              매도까지 알아서 해드리는
            </SpoqaLight>
            <Spacer width={4} />
            <SpoqaBold size={isTablet ? 14 : 24}>
              공모주 올인원 투자일임서비스
            </SpoqaBold>
            <SpoqaLight size={isTablet ? 14 : 24}>
              를 제공하고 있어요.
            </SpoqaLight>
          </Row>
          <Spacer height={40} />
          <Row>
            <SpoqaLight size={isTablet ? 18 : 24}>일육공과 함께</SpoqaLight>
            <Spacer width={4} />
            <SpoqaBold size={isTablet ? 18 : 24}>
              "쉽고 간편하게 공모주 청약"
            </SpoqaBold>
            <Spacer width={2} />
            <SpoqaLight size={isTablet ? 18 : 24}>하세요.</SpoqaLight>
          </Row>
        </>
      )}
    </Container>
  )
}

export default SectionThree
