import { useEffect, useRef } from 'react';

interface KeyboardToolbarProps {
  setBottomPosition: (height: number) => void;
  setIsUp?: (isUp: boolean) => void;
}

const useKeyboardVisibility = (setBottomPosition: (height: number) => void, setIsUp?: (isUp: boolean) => void) => {
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleVisibility = () => {
      if (!window.visualViewport) return;

      const isKeyboardVisible = window.visualViewport.height < window.innerHeight;

      if (isKeyboardVisible) {
        const keyboardHeight = window.innerHeight - window.visualViewport.height;
        setBottomPosition(keyboardHeight);
      } else {
        setBottomPosition(0);
      }
    };

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrollingDown = currentScrollY > lastScrollY.current;

      if(setIsUp){
        if (isScrollingDown) {
            setIsUp(false);
          } else {
            console.log('Scrolling up');
            setIsUp(true);
          }
      }
   

      lastScrollY.current = currentScrollY;
      handleVisibility();
    };

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleVisibility);
      window.visualViewport.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleVisibility);
        window.visualViewport.removeEventListener('scroll', handleScroll);
      }
    };
  }, [setBottomPosition, setIsUp]);
};

const KeyboardToolbar: React.FC<KeyboardToolbarProps> = ({ setBottomPosition, setIsUp }) => {
  useKeyboardVisibility(setBottomPosition, setIsUp);
  return null;
};

export default KeyboardToolbar;
