import React from 'react'
import styled from 'styled-components'

const Index = () => {
  return (
    <Container>
      <FooterDiv>
        <CorporateInfoLeft>
          <Info>
            <BusinessInfo>
              <Name>
                엠엘투자자문(주)
                <img src={'/img/main/footer_line.svg'} alt='' />
                대표 윤도선
              </Name>
              <Address>{`사업자등록번호 : 341-88-02703`}</Address>
            </BusinessInfo>
            <Address>{`서울특별시 강남구 역삼로17길 10 부강빌딩 3층`}</Address>
          </Info>
        </CorporateInfoLeft>
        <RightContainer>
          <Navigate
            onClick={() =>
              window.open('https://metalogos.oopy.io/mli/160/privacy_policy')
            }
          >
            개인정보처리방침
          </Navigate>
          <Navigate
            onClick={() =>
              window.open('https://metalogos.oopy.io/mli/160/serviceinfo')
            }
          >
            서비스이용약관
          </Navigate>
        </RightContainer>
      </FooterDiv>
      <CopyRightContainer>
        <CopyRight>
          <LeftText>
            ⓒ ML Investment Advisory Co.,Ltd. All Rights Reserved.
          </LeftText>
        </CopyRight>
      </CopyRightContainer>
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
  height: 164px;
  background-color: #fff;
  border-top: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px;
  min-width: 1280px;
`

const CorporateInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  gap: 8px;
`

const CorporateInfoRight = styled(CorporateInfoLeft)`
  text-align: right;
`

const Logo = styled.img`
  margin-bottom: 8px;
`

const Address = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  line-height: 120%;
`

const Name = styled.div`
  color: #000;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 8px;
`

const Email = styled.div`
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

const FooterDiv = styled.div`
  width: 1280px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`

const CopyRightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CopyRight = styled.div`
  width: 1280px;
`

const CallNumber = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`
const OperatingHour = styled.div`
  color: #81828a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`
const LeftText = styled.div`
  color: #808491;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  margin-top: 24px;
`
const RightContainer = styled.div`
  display: flex;
  gap: 16px;
`
const Navigate = styled.div`
  cursor: pointer;
`

const BusinessInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export default Index
