import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 416px;
  background-color: ${Colors.maingrey_bg};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 480px) {
    height: 344px;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`
