import styled from 'styled-components'
import PageOne from '../components/infopage/PageOne'
import PageTwo from '../components/infopage/PageTwo'
import PageThree from '../components/infopage/PageThree'
import PageFour from '../components/infopage/PageFour'
import Spacer from '../components/base/Spacer'
import { Colors } from '../styles/colors'
import { useMediaQuery } from 'react-responsive'
import ScreenOne from '../components/mobile-infopage/ScreenOne'
import ScreenTwo from '../components/mobile-infopage/ScreenTwo'
import ScreenThree from '../components/mobile-infopage/ScreenThree'
import ScreenFour from '../components/mobile-infopage/ScreenFour'
import FloatBtn from '../components/base/FloatBtn'
import { useState } from 'react'
import BasicInstallModal from '../components/base/modal/basic_install_modal'
import MobileFloatBtn from '../components/base/float_btn/mobile_float_btn'

const Styled = {
  Container: styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `,
  MobileContainer: styled.div`
    @media (min-width: 999px) {
      display: none;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Colors.gf};
  `,
}

const { Container, MobileContainer } = Styled

const InfoPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  return (
    <>
      {isMobile ? (
        <MobileContainer>
          <ScreenOne />
          <ScreenTwo />
          <ScreenThree />
          <ScreenFour />
          <MobileFloatBtn />
        </MobileContainer>
      ) : (
        <Container>
          <PageOne />
          <PageTwo />
          <PageThree />
          <Spacer height={120} />
          <PageFour />
          <FloatBtn
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowDownBtn={true}
          />
          <BasicInstallModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </Container>
      )}
    </>
  )
}

export default InfoPage
