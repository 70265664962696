import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

type Props = {
  targetAmount: number
  duration?: number
}
const FadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`
const Counter = styled.div`
  animation: ${FadeIn} 0.2s ease-in-out;
`

const AnimatedCounter = ({ targetAmount, duration = 2000 }: Props) => {
  const [currentAmount, setCurrentAmount] = React.useState(0)

  useEffect(() => {
    let startTimestamp: DOMHighResTimeStamp | null = null

    const step = (timestamp: DOMHighResTimeStamp): void => {
      if (!startTimestamp) startTimestamp = timestamp

      const elapsed = timestamp - startTimestamp
      const progress = Math.min(elapsed / duration, 1) // 0 ~ 1 사이 값
      const updatedAmount = Math.floor(progress * targetAmount)

      setCurrentAmount(updatedAmount)

      if (progress < 1) {
        requestAnimationFrame(step as FrameRequestCallback)
      }
    }
    requestAnimationFrame(step as FrameRequestCallback)
    return () => cancelAnimationFrame(step as unknown as number)
  }, [targetAmount, duration])
  return <Counter>{currentAmount.toLocaleString()}원</Counter>
}
export default AnimatedCounter
