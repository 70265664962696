import axios from 'axios'

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URI,
})
export const DevAxios = axios.create({
  baseURL: process.env.REACT_APP_DEV_REST_API_URI,
})
export const API_URL = {
  SCHEDULE: '/schedule/external',
}
