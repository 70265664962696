import { Colors } from '@styles/colors'
import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.section`
  background-color: ${Colors.yellow_sub};
  width: 100%;
  height: 626px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 214px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    margin-top: 5px;
    flex-direction: row;
  }
`

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 542px;
  height: 74px;
  z-index: 1;
  @media (max-width: 767px) {
    width: 80%;
    height: 74px;
    margin-top: 16px;
    justify-content: center;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 28px;
    margin-top: 16px;
    padding: 0 74px;
  }
`
export const Btn = styled.div`
  width: 256px;
  height: 100%;
  background-color: ${Colors.gf};
  border-radius: 9.792px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 9.792px;
  background: var(--grey-gf, #fff);

  @media (max-width: 767px) {
    width: 180px;
    height: 50px;
  }
  @media (max-width: 480px) {
    width: 112px;
    height: 38.04px;
    flex-shrink: 0;
    border-radius: 19px;
    background: var(--grey-gf, #fff);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
`
export const BtnImage = styled.img`
  width: 42px;
  height: 47px;
  @media (max-width: 767px) {
    width: 22px;
    height: 22px;
  }
  @media (max-width: 480px) {
    width: 35px;
    height: 35px;
  }
`

export const FlagImage = styled.img`
  height: 90%;
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 767px) {
    right: -15%;
    z-index: 0;
  }
`
