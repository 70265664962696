import { useEffect, useCallback, useRef } from 'react'

const baseOption = {
  root: null,
  threshold: 0.5,
  rootMargin: '0px',
}
const useIntersect = (
  onIntersect: (
    entry: IntersectionObserverEntry,
    observer: IntersectionObserver
  ) => void,
  option?: IntersectionObserverInit
) => {
  const ref = useRef(null)
  const checkIntersect = useCallback(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      const entry = entries[0]
      if (entry.isIntersecting) onIntersect(entry, observer)
    },
    [onIntersect]
  )

  useEffect(() => {
    let observer: IntersectionObserver
    if (ref.current) {
      observer = new IntersectionObserver(checkIntersect, {
        ...baseOption,
        ...(option && { option }),
      })
      observer.observe(ref.current)
    }
    return () => observer && observer.disconnect()
  }, [checkIntersect, option])

  return ref
}

export default useIntersect
