import { SpoqaBold, SpoqaMedium } from '@components/base/TextStyle/Spoqa'
import React from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { Desktop } from '@constants/mediaQuery'
import useGetSimulatedData from '@hooks/useGetSimulatedData'
import dayjs from 'dayjs'
import { getNow } from '../../utils/dateHelper'

const Section = styled.section`
  margin-top: 64px;
  max-width: 800px;
`
const ScheduleList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  padding: 24px 0 56px;
  @media screen and (max-width: ${Desktop}px) {
    display: flex;
    padding: 12px 24px 40px 24px;
    overflow: scroll;
    gap: 16px;
  }
`
const Item = styled.li`
  width: 250px;
  height: 250px;
  @media screen and (max-width: ${Desktop}px) {
    min-width: 180px;
    height: 173px;
  }
`
const ItemCard = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  padding: 24px;
  position: relative;
  background-color: ${Colors.gf};
  cursor: pointer;
  box-shadow: 0 4px 16px 0 rgba(109, 109, 109, 0.07);
  @media screen and (max-width: ${Desktop}px) {
    padding: 16px;
  }
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  > img {
    width: 111px;
    height: 111px;
    @media screen and (max-width: ${Desktop}px) {
      width: 80px;
      height: 80px;
    }
  }
`
const Subtitle = styled(SpoqaBold)`
  font-size: 26px;
  letter-spacing: -0.33px;
  color: ${Colors.g12};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 18px;
    padding-left: 24px;
  }
`
const DDay = styled(SpoqaBold)`
  position: absolute;
  top: 24px;
  left: 24px;
  color: #0e6dfb;
  letter-spacing: -0.33px;
  font-size: 22px;
  line-height: 26px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 14px;
    line-height: 17px;
    top: 16px;
    left: 16px;
  }
`
const CompanyName = styled(SpoqaMedium)`
  font-size: 22px;
  line-height: 26px;
  color: ${Colors.g5};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 16px;
  }
`
type Props = {
  onClickCard: (code: string) => void
}
const ScheduleListSection = ({ onClickCard }: Props) => {
  const {
    scheduledOfferData: { data },
  } = useGetSimulatedData()
  const diffDate = (target: string) => {
    const now = dayjs(getNow()).startOf('day')
    const targetDate = dayjs(target).endOf('day')
    return Math.abs(now.diff(targetDate, 'day'))
  }
  // webview to app

  return (
    <Section>
      <Subtitle>곧 다가올 투자 일정</Subtitle>
      <ScheduleList>
        {data?.map(({ code, name, imgUrl, offeringStartDate }, index) => (
          <Item key={name + index}>
            <ItemCard
              onClick={() => onClickCard(code)}
              id={`event_160_01_main_stock_btn_click_${code}`}
            >
              <Flex>
                <DDay>D-{diffDate(offeringStartDate)}</DDay>
                <img src={imgUrl} alt={name} />
              </Flex>

              <CompanyName>{name}</CompanyName>
            </ItemCard>
          </Item>
        ))}
      </ScheduleList>
    </Section>
  )
}

export default ScheduleListSection
