import React from 'react'
import EventDetail from '@components/simulated-trading/EventDetail'
import SimulatedLayout from '@layouts/SimulatedLayout'
import useSetEntryPoint from '@hooks/useSetEntryPoint'

const SimulatedEventDetailPage = () => {
  useSetEntryPoint()
  return (
    <SimulatedLayout
      hasFooter={false}
      hasBack={true}
      paddingBottom={0}
      hasClose={false}
    >
      <EventDetail />
    </SimulatedLayout>
  )
}

export default SimulatedEventDetailPage
