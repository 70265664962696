import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Content05 = () => {
  return (
    <Container>
      <Title>다운로드</Title>
      <Count>42,000+</Count>
    </Container>
  );
};

const Container = styled.div`
  grid-area: box4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  border-radius: 32px;
  box-sizing: border-box;
  height: 512px;
  background-image: url("/img/main/contentBg02.svg");
  background-size: cover;
`;

const Title = styled.div`
  color: #000;
  font-size: 33px;
  font-style: normal;
  line-height: 120%;
`;

const Count = styled.div`
  font-size: 72px;
  font-style: normal;
  line-height: 120%;
`;

const Desc = styled.div`
  white-space: pre-line;
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  line-height: 120%;
`;
export default Content05;
